import { Card, Col, Flex, Row, Segmented, Spin, Statistic, Typography } from "antd"
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { dateTimeFormat, dateTimeFormatWithoutSeconds } from "../../common/constants/constants"
import { useMemo, useState } from "react"

import { MeterValueGraphQlDto } from "../../features/transactions/graphql/types"
import { MeterValueMeasurandEnum } from "../../common/enums/meterValueMeasurandEnum"
import { MeterValuePhaseEnum } from "../../common/enums/meterValuePhaseEnum"
import { MeterValueUnitEnum } from "../../common/enums/meterValueUnitEnum"
import { QUERY_TRANSACTION_METER_VALUES } from "../../features/transactions/graphql/quries"
import React from "react"
import dayjs from "dayjs"
import styles from "./Chart.module.scss"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

interface TransactionLineChartProps {
	id: number
	type: number
}

const TransactionLineChart = ({ id, type }: TransactionLineChartProps) => {
	const [segment, selectedSegment] = useState<string | undefined>(undefined)
	const { t } = useTranslation()

	const { data, loading } = useQuery<
		{ query: MeterValueGraphQlDto[] },
		{ transactionId: number; measurandTypeId: number }
	>(QUERY_TRANSACTION_METER_VALUES, {
		variables: {
			transactionId: id,
			measurandTypeId: type,
		},
		notifyOnNetworkStatusChange: true,
		onCompleted(data) {
			if (data.query?.length) {
				selectedSegment(data.query[0].phase.toString())
			}
		},
	})

	const transformDataForChart = (data) => {
		const transformedData = {}

		data.forEach((item) => {
			item.data.forEach((dataPoint) => {
				const date = dataPoint.date
				const phase = item.phase

				if (!transformedData[date]) {
					transformedData[date] = { date }
				}

				transformedData[date][`phase_${phase}`] = dataPoint.value
			})
		})

		return Object.values(transformedData)
	}

	const transformedData = useMemo(() => transformDataForChart(data?.query ?? []), [data?.query])

	const phases = useMemo(
		() => [
			...new Set(
				transformDataForChart(data?.query ?? []).flatMap((item) =>
					Object.keys(item).filter((key) => key.startsWith("phase_"))
				)
			),
		],
		[data?.query]
	)

	const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"]

	if (!data?.query?.length) return <></>

	return (
		<Card bordered={false}>
			<Spin spinning={loading}>
				<div style={{ width: "100%", height: "400px", overflowX: "auto", overflowY: "hidden" }}>
					<Flex justify="center">
						<Typography.Title level={4}>
							{t(`MeterValueMeasurandEnum.${MeterValueMeasurandEnum[type]}`)}
						</Typography.Title>
					</Flex>
					<Flex style={{ marginBottom: "8px" }} gap="small" justify="space-between">
						<div>
							{data.query
								.filter((x) => x.phase === +segment)
								.map((x) => (
									<Row gutter={[16, 16]}>
										<Col>
											<Statistic
												className={styles["transactionChartStatistics"]}
												title={t(`labels.min`)}
												value={`${x.min?.toFixed(2)} ${MeterValueUnitEnum[x.unit]}`}
											/>
										</Col>
										<Col>
											<Statistic
												className={styles["transactionChartStatistics"]}
												title={t(`labels.max`)}
												value={`${x.max?.toFixed(2)} ${MeterValueUnitEnum[x.unit]}`}
											/>
										</Col>
										{x.unit > MeterValueUnitEnum.Kvarh && (
											<Col>
												<Statistic
													className={styles["transactionChartStatistics"]}
													title={t(`labels.avg`)}
													value={`${x.avg?.toFixed(2)} ${MeterValueUnitEnum[x.unit]}`}
												/>
											</Col>
										)}
									</Row>
								))}
						</div>
						<div>
							<Segmented
								onChange={(value) => selectedSegment(value.toString())}
								value={segment}
								size="small"
								options={data?.query?.map((x) => ({
									label: x.phase === -1 ? t("texts.all") : MeterValuePhaseEnum[x.phase],
									value: x.phase.toString(),
								}))}
							/>
						</div>
					</Flex>
					<ResponsiveContainer minWidth="600px" width="100%" height="100%">
						<LineChart syncId={"syncId"} margin={{ top: 20, right: 0, left: 0, bottom: 96 }} data={transformedData}>
							<CartesianGrid strokeDasharray="3" />
							<XAxis dataKey="date" tickFormatter={(value) => dayjs(value).format(dateTimeFormatWithoutSeconds)} />
							<YAxis unit={` ${MeterValueUnitEnum[data.query[0].unit]}`} />
							<Tooltip labelFormatter={(value) => dayjs(value).format(dateTimeFormat)} />
							<Legend />
							{phases.map((phase, i) => (
								<Line
									dot={false}
									key={phase}
									type="monotone"
									dataKey={phase}
									name={
										+phase.replace("phase_", "") === -1
											? t("texts.all")
											: MeterValuePhaseEnum[phase.replace("phase_", "")]
									}
									strokeWidth={1}
									unit={` ${MeterValueUnitEnum[data.query[0].unit]}`}
									stroke={COLORS[i]}
								/>
							))}
						</LineChart>
					</ResponsiveContainer>
				</div>
			</Spin>
		</Card>
	)
}

export default TransactionLineChart
