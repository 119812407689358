export enum ChargingRateUnitEnum {
	W = 1,
	A = 2,
}

export enum ChargingProfileKindEnum {
	Absolute = 1,
	Recurring = 2,
	Relative = 3,
}

export enum ChargingProfilePurposeEnum {
	ChargePointMaxProfile = 1,
	TxDefaultProfile = 2,
	TxProfile = 3,
}

export enum ChargingProfileRecurrencyKindEnum {
	Daily = 1,
	Weekly = 2,
}

export enum ChargingProfileStatusEnum {
	New = 1,
	Accepted = 2,
	Rejected = 3,
	NotSupported = 4,
	Disabled = 5,
}
