import { ChartModel, DropdownWrapper } from "../models/customModels"

import { DashboardBaseTypeCountGraphqlDto } from "../../features/dashboard/graphql/types"
import { FetchStatusEnum } from "../enums/fetchStatusEnum"

export const initDropdowmWrapperModel = <T>(): DropdownWrapper<T> => {
	return {
		data: [],
		status: FetchStatusEnum.idle,
	} as DropdownWrapper<T>
}

export const mapToChartModel = (
	data: DashboardBaseTypeCountGraphqlDto[],
	t: (value: string) => string,
	enumType: any,
	resourceName: string,
	unkwnowText: string
): ChartModel[] => {
	console.log(data)

	return data.map((entry, index) => ({
		name: entry.id ? t(`${resourceName}.${enumType[entry.id]}`) : unkwnowText,
		value: entry.count,
	}))
}
