import { RoleEnum } from "../enums/roleEnum"
import { UserAuthorization } from "../../features/auth/types"

export const isAccessGranted = (user: UserAuthorization | undefined, roles: RoleEnum[]): boolean => {
	if (!user) return false

	if (roles.length === 0) return true

	const isPermmissionGranted = roles.some((x) => user.roles?.some((y) => y === x) ?? false)
	return isPermmissionGranted
}
