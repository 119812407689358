export enum MeterValueUnitEnum {
	Wh = 0,
	KWh = 1,
	Varh = 2,
	Kvarh = 3,
	W = 4,
	KW = 5,
	VA = 6,
	KVA = 7,
	Var = 8,
	Kvar = 9,
	A = 10,
	V = 11,
	K = 12,
	Celsius = 13,
	Fahrenheit = 14,
	Percent = 15,
}
