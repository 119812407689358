import { FocusEventHandler, ReactNode } from "react"
import { Form, InputNumber } from "antd"

import { BaseFormItem } from "../../common/interfaces/formInterfaces"
//import styles from "./FormItem.module.scss"
import { useTranslation } from "react-i18next"

interface Props extends BaseFormItem {
	onChange?: (e: number | null) => void
	min?: number
	max?: number
	decimalSeparator?: string
	precision?: number
	showControls?: boolean
	formatter?: (value: number | undefined) => string
	onBlur?: FocusEventHandler<HTMLInputElement>
	addonAfter?: ReactNode
}

const NumberFormItem = ({
	name,
	validationMessage,
	placeholder,
	isRequired,
	hidden,
	disabled,
	rules,
	initialValue,
	readonly,
	onChange,
	prefix,
	label,
	min,
	max,
	precision,
	decimalSeparator = ".",
	showControls = true,
	onBlur,
	addonAfter,
}: Props) => {
	const { t } = useTranslation()

	return (
		<Form.Item
			//className={styles["formItem"]}
			hidden={hidden}
			name={name}
			label={label}
			initialValue={initialValue}
			rules={rules ?? [{ required: isRequired, message: validationMessage ?? t("validations.input.common") }]}
		>
			<InputNumber
				style={{ width: "100%" }}
				prefix={prefix}
				decimalSeparator={decimalSeparator}
				onChange={onChange}
				disabled={disabled}
				readOnly={readonly}
				min={min}
				controls={showControls}
				max={max}
				onBlur={onBlur}
				addonAfter={addonAfter}
				precision={precision}
				placeholder={placeholder}
			/>
		</Form.Item>
	)
}

export default NumberFormItem
