import { Col, Form, Row, Spin, Tabs, message } from "antd"
import {
	createChargePointAsync,
	getChargePointDetailAsync,
	updateChargePointAsync,
} from "../../../../features/chargePoints/chargePointSlice"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { useForm, useWatch } from "antd/es/form/Form"

import BaseEditModal from "../../../../components/modals/BaseEditModal"
import { ChargePointModel } from "../../../../features/chargePoints/types"
import { ChargePointStateEnum } from "../../../../common/enums/chargePointStateEnum"
import { CompassOutlined } from "@ant-design/icons"
import { FetchStatusEnum } from "../../../../common/enums/fetchStatusEnum"
import FormItemWrapper from "../../../../components/formItems/FormItemWrapper"
import InputFormItem from "../../../../components/formItems/InputFormItem"
import NumberFormItem from "../../../../components/formItems/NumberFormItem"
import React from "react"
import SelectFormItem from "../../../../components/formItems/SelectFormItem"
import { SelectItem } from "../../../../common/models/customModels"
import SwitchFormItem from "../../../../components/formItems/SwitchFormItem"
import TextAreaFormItem from "../../../../components/formItems/TextAreaFormItem"
import { getCompaniesDropdownSourceAsync } from "../../../../features/companies/companySlice"
import { getEnumSelectOptions } from "../../../../common/helpers/enumHelpers"
import { nameof } from "ts-simple-nameof"
import { objectToFormData } from "../../../../common/helpers/formDataHelper"
import { t } from "i18next"
import { useEffect } from "react"

interface Props {
	open: boolean
	id?: number
	onClose: (withReload: boolean) => void
}

const ChargePointEditModal = ({ open, onClose, id }: Props) => {
	const [form] = useForm<ChargePointModel>()
	const dispatch = useAppDispatch()
	const { status } = useAppSelector((x) => x.chargePoints)
	const { companiesDropdown } = useAppSelector((x) => x.companies)

	useEffect(() => {
		if (!open) {
			form.resetFields()
			return
		}

		dispatch(getCompaniesDropdownSourceAsync())

		if (!id) return
		getItemDetail(+id)
	}, [open])

	useEffect(() => {}, [])

	const getItemDetail = async (id: number) => {
		var data = await dispatch(getChargePointDetailAsync(id)).unwrap()
		form.setFieldsValue(data)
	}

	const getLocation = () => {
		if (!navigator.geolocation) return

		navigator.geolocation.getCurrentPosition(
			(position) => {
				form.setFields([
					{
						name: nameof<ChargePointModel>((x) => x.latitude),
						value: position.coords.latitude,
					},
					{
						name: nameof<ChargePointModel>((x) => x.longitude),
						value: position.coords.longitude,
					},
				])
			},
			() => {
				message.info("Unable to retrieve your location")
			}
		)
	}

	const onEditFormSubmit = async (model: ChargePointModel) => {
		const formData = objectToFormData(model)

		if (id) await dispatch(updateChargePointAsync({ id: id, data: formData })).unwrap()
		else await dispatch(createChargePointAsync(formData)).unwrap()

		onClose(true)
	}

	const isPublic = useWatch([nameof<ChargePointModel>((x) => x.isPublic)], form)
	const isTagAutoRegisterEnabled = useWatch([nameof<ChargePointModel>((x) => x.isTagAutoRegisterEnabled)], form)

	return (
		<BaseEditModal
			title={t("titles.editChargePoint")}
			isOpen={open}
			loading={status === FetchStatusEnum.loading}
			onCancel={() => onClose(false)}
			onFormSubmit={form.submit}
		>
			<Form layout="vertical" form={form} onFinish={onEditFormSubmit}>
				<InputFormItem name={nameof<ChargePointModel>((x) => x.id)} hidden />

				<Tabs
					items={[
						{
							forceRender: true,
							key: "1",
							label: t("titles.general"),
							children: (
								<>
									<FormItemWrapper
										firstItem={
											<InputFormItem
												name={nameof<ChargePointModel>((x) => x.name)}
												isRequired
												label={t("labels.name")}
												maxLength={100}
											/>
										}
										secondItem={
											<InputFormItem
												name={nameof<ChargePointModel>((x) => x.chargePointId)}
												isRequired
												label={t("labels.chargePointId")}
												maxLength={100}
											/>
										}
									/>
									<FormItemWrapper
										firstItem={
											<InputFormItem
												name={nameof<ChargePointModel>((x) => x.vendor)}
												label={t("labels.vendor")}
												maxLength={20}
											/>
										}
										secondItem={
											<InputFormItem
												name={nameof<ChargePointModel>((x) => x.serialNumber)}
												label={t("labels.serialNumber")}
												maxLength={25}
											/>
										}
									/>
									<TextAreaFormItem
										formProps={{
											name: nameof<ChargePointModel>((x) => x.comment),
											label: t("labels.note"),
										}}
										elementProps={{ maxLength: 500 }}
									/>

									<FormItemWrapper
										firstItem={
											<SelectFormItem
												label={t("labels.company")}
												items={companiesDropdown.data}
												allowClear
												isLoading={companiesDropdown.status === FetchStatusEnum.loading}
												name={nameof<ChargePointModel>((x) => x.companyId)}
											/>
										}
										secondItem={
											<SelectFormItem
												label={t("labels.status")}
												items={getEnumSelectOptions(ChargePointStateEnum, t, "chargePointState").map((x) => ({
													id: x.value,
													name: x.label.toString(),
												}))}
												name={nameof<ChargePointModel>((x) => x.chargePointStateId)}
											/>
										}
									/>
									<Row gutter={[24, 24]}>
										<Col span={12}>
											<SwitchFormItem
												formProps={{
													name: nameof<ChargePointModel>((x) => x.isPublic),
													label: t("labels.public"),
												}}
												elementProps={{
													checkedChildren: t("texts.yes"),
													unCheckedChildren: t("texts.no"),
													value: isPublic,
													onChange: (value) => form.setFieldValue([nameof<ChargePointModel>((x) => x.isPublic)], value),
												}}
											/>
										</Col>
										<Col span={12}>
											<SwitchFormItem
												formProps={{
													name: nameof<ChargePointModel>((x) => x.isTagAutoRegisterEnabled),
													label: t("labels.isTagAutoRegisterEnabled"),
												}}
												elementProps={{
													checkedChildren: t("texts.yes"),
													unCheckedChildren: t("texts.no"),
													value: isTagAutoRegisterEnabled,
													onChange: (value) =>
														form.setFieldValue([nameof<ChargePointModel>((x) => x.isTagAutoRegisterEnabled)], value),
												}}
											/>
										</Col>
									</Row>
								</>
							),
						},
						{
							forceRender: true,
							key: "2",
							label: t("titles.location"),
							children: (
								<>
									<FormItemWrapper
										firstItem={
											<InputFormItem
												name={nameof<ChargePointModel>((x) => x.address)}
												label={t("labels.address")}
												maxLength={100}
											/>
										}
										secondItem={
											<InputFormItem
												name={nameof<ChargePointModel>((x) => x.city)}
												label={t("labels.city")}
												maxLength={100}
											/>
										}
									/>
									<FormItemWrapper
										firstItem={
											<InputFormItem
												name={nameof<ChargePointModel>((x) => x.zipCode)}
												label={t("labels.zipCode")}
												maxLength={100}
											/>
										}
										secondItem={
											<InputFormItem
												name={nameof<ChargePointModel>((x) => x.country)}
												label={t("labels.country")}
												maxLength={100}
											/>
										}
									/>

									<Row wrap={false} gutter={24}>
										<Col flex="auto">
											<NumberFormItem
												label={t("labels.latitude")}
												name={nameof<ChargePointModel>((x) => x.latitude)}
												showControls={false}
											/>
										</Col>
										<Col flex="auto">
											<NumberFormItem
												label={t("labels.longitude")}
												name={nameof<ChargePointModel>((x) => x.longitude)}
												showControls={false}
											/>
										</Col>
										{navigator.geolocation && (
											<Col flex="48px">
												<Row style={{ height: "100%" }} align="middle">
													<CompassOutlined style={{ fontSize: "22px", paddingTop: "6px" }} onClick={getLocation} />
												</Row>
											</Col>
										)}
									</Row>
								</>
							),
						},
						{
							forceRender: true,
							key: "3",
							label: t("titles.externalLinks"),
							children: (
								<>
									<InputFormItem
										type="url"
										name={nameof<ChargePointModel>((x) => x.externalLink)}
										label={t("labels.externalLinkChargepoint")}
										maxLength={1000}
									/>
									<InputFormItem
										type="url"
										name={nameof<ChargePointModel>((x) => x.servicePortalLink)}
										label={t("labels.externalLinkServicePortal")}
										maxLength={1000}
									/>
								</>
							),
						},
					]}
				></Tabs>
			</Form>
		</BaseEditModal>
	)
}

export default ChargePointEditModal
