import { gql } from "@apollo/client"

export const QUERY_ADMIN_DASHBOARD = gql`
	query GetAdminDashboard {
		companyCounts {
			totalCount
			todaysCount
		}
		userCounts {
			totalCount
			todaysCount
		}
		chargeTagCounts {
			totalCount
			todaysCount
			stateCounts {
				id
				count
			}
			typeCounts {
				id
				count
			}
		}
		chargePointCounts {
			publicCount
			privateCount
			totalCount
			todaysCount
			stateCounts {
				id
				count
			}
		}
	}
`

export const QUERY_ADMIN_DASHBOARD_GENERAL_STATISTICS = gql`
	query GetAdminDashboardGeneralStatistics(
		$fromTimestamp: DateTime
		$toTimestamp: DateTime
		$companyIds: [Long!]
		$userIds: [Long!]
		$chargePointIds: [Long!]
	) {
		generalStatistics(
			fromTimestamp: $fromTimestamp
			toTimestamp: $toTimestamp
			companyIds: $companyIds
			userIds: $userIds
			chargePointIds: $chargePointIds
		) {
			totalEnergy
			todayEnergy
			totalTransactions
			todayTransactions
			totalReservations
			todayReservations
		}
	}
`

export const QUERY_TRANSACTIONS_CHART_DATA = gql`
	query GetTransactionsChartData(
		$fromTimestamp: DateTime
		$toTimestamp: DateTime
		$dateRangeType: Long!
		$companyIds: [Long!]
		$userIds: [Long!]
		$chargePointIds: [Long!]
	) {
		query: transactionsChartData(
			fromTimestamp: $fromTimestamp
			toTimestamp: $toTimestamp
			dateRangeType: $dateRangeType
			companyIds: $companyIds
			userIds: $userIds
			chargePointIds: $chargePointIds
		) {
			business
			personal
			undefined
			date
		}
	}
`
