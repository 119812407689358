import { DateFilterTypeEnum } from "../enums/dateFilterTypeEnum"
import { DefaultOptionType } from "antd/es/select"
import { DurationSelectionTypeEnum } from "../enums/durationSelectionTypeEnum"
import dayjs from "dayjs"

export const getEnumSelectOptions = (
	type: any,
	t: (value: string) => string,
	resourceName: string
): DefaultOptionType[] => {
	return Object.values(type)
		.filter((x) => !isNaN(Number(x)))
		.map((x) => ({
			value: Number(x),
			label: t(`${resourceName}.${type[Number(x)]}`),
		}))
}

export const getEnumValues = <T>(type: any): number[] => {
	return Object.values(type)
		.filter((x) => !isNaN(Number(x)))
		.map((x) => Number(x))
}

export const toDateRange = (value: DateFilterTypeEnum): [string, string, DurationSelectionTypeEnum] => {
	const end = dayjs().utcOffset(0).endOf("day").toISOString()

	if (value === DateFilterTypeEnum.Custom) return [undefined, undefined, DurationSelectionTypeEnum.Hours]
	else if (value === DateFilterTypeEnum.Today)
		return [dayjs().utcOffset(0).startOf("days").toISOString(), end, DurationSelectionTypeEnum.Hours]
	else if (value === DateFilterTypeEnum.Week)
		return [dayjs().utcOffset(0).add(-6, "days").startOf("day").toISOString(), end, DurationSelectionTypeEnum.Days]
	else if (value === DateFilterTypeEnum.Month)
		return [dayjs().utcOffset(0).add(-1, "months").startOf("day").toISOString(), end, DurationSelectionTypeEnum.Days]
	else if (value === DateFilterTypeEnum.ThreeMonths)
		return [dayjs().utcOffset(0).add(-2, "months").startOf("day").toISOString(), end, DurationSelectionTypeEnum.Months]
	else if (value === DateFilterTypeEnum.SixMonths)
		return [dayjs().utcOffset(0).add(-5, "months").startOf("day").toISOString(), end, DurationSelectionTypeEnum.Months]
	else if (value === DateFilterTypeEnum.Year)
		return [dayjs().utcOffset(0).add(-11, "months").startOf("day").toISOString(), end, DurationSelectionTypeEnum.Months]
	else if (value === DateFilterTypeEnum.All) return [undefined, undefined, DurationSelectionTypeEnum.Years]
	else return [undefined, undefined, DurationSelectionTypeEnum.Hours]
}

export const toDateFormat = (value: DateFilterTypeEnum): string => {
	if (value === DateFilterTypeEnum.Custom) return "DD.MM.YYYY"
	else if (value === DateFilterTypeEnum.Today) return "HH:mm"
	else if (value === DateFilterTypeEnum.Week) return "DD.MM.YYYY"
	else if (value === DateFilterTypeEnum.Month) return " DD.MM.YYYY"
	else if (value === DateFilterTypeEnum.ThreeMonths) return "MM.YYYY"
	else if (value === DateFilterTypeEnum.SixMonths) return "MM.YYYY"
	else if (value === DateFilterTypeEnum.Year) return "MM.YYYY"
	else if (value === DateFilterTypeEnum.All) return "YYYY"
	else return "DD.MM.YYYY"
}
