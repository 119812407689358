import { GraphqlItemsModel, GraphqlOrderModel, GraphqlQueryItemBaseModel } from "../../../common/models/graphqlModels"
import { WatchQueryFetchPolicy, useQuery } from "@apollo/client"

import { QUERY_USERS_DROPDOWN } from "./queries"
import { UserGraphqlDropdownDto } from "./types"

export const useUsersDropdownQuery = (
	fetchPolicy?: WatchQueryFetchPolicy | undefined,
	order: any = [{ email: "ASC" }]
) =>
	useQuery<GraphqlQueryItemBaseModel<GraphqlItemsModel<UserGraphqlDropdownDto>>, GraphqlOrderModel>(
		QUERY_USERS_DROPDOWN,
		{
			fetchPolicy: fetchPolicy,
			variables: {
				order: order,
			},
			notifyOnNetworkStatusChange: true,
		}
	)
