import { Form, Row, Select } from "antd"

import { BaseFormItem } from "../../common/interfaces/formInterfaces"
import { SelectItem } from "../../common/models/customModels"
//import styles from "./FormItem.module.scss"
import { useTranslation } from "react-i18next"

const { Option } = Select

interface Props<T> extends BaseFormItem {
	items: SelectItem<T>[]
	withSearch?: boolean
	isLoading?: boolean
	mode?: "multiple" | "tags" | undefined
	onChange?: (value: T) => void
	classNameCustom?: string
	allowClear?: boolean
}

const SelectFormItem = <T extends string | number>({
	name,
	label,
	disabled,
	hidden,
	isRequired,
	placeholder,
	validationMessage,
	withSearch,
	initialValue,
	items,
	isLoading = false,
	mode = undefined,
	restField,
	onChange = () => {},
	rules,
	classNameCustom,
	prefix,
	allowClear,
}: Props<T>) => {
	const { t } = useTranslation()

	return (
		<Form.Item
			{...restField}
			hidden={hidden}
			name={name}
			label={label}
			initialValue={initialValue}
			rules={rules ?? [{ required: isRequired, message: validationMessage ?? t("validations.input.common") }]}
		>
			<Select
				showSearch={withSearch}
				optionFilterProp="children"
				filterOption={(input, option: any) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
				mode={mode}
				value={initialValue}
				loading={isLoading}
				disabled={disabled}
				allowClear={allowClear}
				onChange={(value) => onChange(value)}
				placeholder={
					prefix ? (
						<Row align="middle">
							{prefix} {placeholder}
						</Row>
					) : (
						placeholder
					)
				}
			>
				{items.map((x) => {
					return (
						<Option key={x.id} value={x.id}>
							{x.name}
						</Option>
					)
				})}
			</Select>
		</Form.Item>
	)
}

export default SelectFormItem
