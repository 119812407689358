import { BaseState, DropdownWrapper, SelectItem } from "../../common/models/customModels"

import { FetchStatusEnum } from "../../common/enums/fetchStatusEnum"
import { UserModel } from "./types"
import api from "../../common/api/api.json"
import { createMyOwnAsyncThunk } from "../../common/helpers/reduxToolkitHelper"
import { createSlice } from "@reduxjs/toolkit"
import { httpClient } from "../../common/helpers/httpClientHelper"
import { initDropdowmWrapperModel } from "../../common/helpers/modelHelperts"

interface UsersState extends BaseState {
	usersDropdown: DropdownWrapper<SelectItem<number>>
}

const initialState: UsersState = {
	usersDropdown: initDropdowmWrapperModel(),
	status: FetchStatusEnum.idle,
}

export const getUsersDropdownSourceAsync = createMyOwnAsyncThunk<SelectItem<number>[], void>(
	"users/getUsersDropdownSourceAsync",
	() => {
		return httpClient.get<SelectItem<number>[]>(api.admin.users.dropdown)
	}
)

export const createUserAsync = createMyOwnAsyncThunk<UserModel, FormData>("users/createUserAsync", (data) => {
	return httpClient.post<UserModel>(api.admin.users.base, data)
})

export const updateUserAsync = createMyOwnAsyncThunk<UserModel, { id: number; data: FormData }>(
	"users/updateUserAsync",
	({ id, data }) => {
		return httpClient.put<UserModel>(api.admin.users.base + "/" + id, data)
	}
)

export const deleteUserAsync = createMyOwnAsyncThunk<UserModel, { id: number }>("users/deleteUserAsync", ({ id }) => {
	return httpClient.delete<UserModel>(api.admin.users.base + "/" + id)
})

export const usersSlice = createSlice({
	name: "users",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getUsersDropdownSourceAsync.pending, (state, action) => {
			state.usersDropdown.status = FetchStatusEnum.loading
		})
		builder.addCase(getUsersDropdownSourceAsync.fulfilled, (state, action) => {
			state.usersDropdown.data = action.payload
			state.usersDropdown.status = FetchStatusEnum.success
		})
		builder.addCase(getUsersDropdownSourceAsync.rejected, (state, action) => {
			state.usersDropdown.status = FetchStatusEnum.failed
		})

		builder.addCase(createUserAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(createUserAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(createUserAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})

		builder.addCase(updateUserAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(updateUserAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(updateUserAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})

		builder.addCase(deleteUserAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(deleteUserAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(deleteUserAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})
	},
})

export default usersSlice.reducer
