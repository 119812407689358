import { Button, Col, Row, Table } from "antd"
import {
	GraphqlQueryItemsBaseModel,
	GraphqlTableQueryParamsBaseModel,
} from "../../../../../common/models/graphqlModels"
import React, { useState } from "react"
import {
	handleGraphqlFilterTableChange,
	initGraphqlTableQueryParamsBaseModel,
} from "../../../../../common/helpers/graphQlHelpers"

import { ChargePointGraphQlDto } from "../../../../../features/chargePoints/graphql/types"
import { ColumnType } from "antd/es/table"
import MainTable from "../../../../../components/tables/MainTable"
import { QUERY_RESERVATIONS } from "../../../../../features/reservations/graphql/queries"
import { ReservationGraphQlDto } from "../../../../../features/reservations/graphql/types"
import { ReservationStatusEnum } from "../../../../../common/enums/reservationStatusEnum"
import { cancelReservationAsync } from "../../../../../features/versionReceiver/versionReceiver"
import { columnHelper } from "../../../../../common/helpers/tableHelpers"
import { defaultTablePageSize } from "../../../../../common/constants/constants"
import moment from "moment"
import { nameof } from "ts-simple-nameof"
import styles from "../../ChargePoint.module.scss"
import { useAppDispatch } from "../../../../../app/hooks"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

interface GraphQlParamsModel extends GraphqlTableQueryParamsBaseModel {
	chargePointId: number
}

interface Props {
	chargePoint: ChargePointGraphQlDto
}

const ChargePointReservationsTab = ({ chargePoint }: Props) => {
	const defaultOrder = [{ id: "DESC" }]

	const [queryParams, setQueryParams] = useState<GraphQlParamsModel>({
		...initGraphqlTableQueryParamsBaseModel(defaultOrder),
		chargePointId: chargePoint.id,
	})

	const { data, loading, refetch } = useQuery<
		GraphqlQueryItemsBaseModel<ReservationGraphQlDto>,
		GraphqlTableQueryParamsBaseModel
	>(QUERY_RESERVATIONS, {
		variables: queryParams,
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "network-only",
	})

	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const columns: ColumnType<ReservationGraphQlDto>[] = [
		{
			...columnHelper(
				"ConnectorId",
				nameof<ReservationGraphQlDto>((x) => x.connectorId)
			),
		},
		{
			...columnHelper(
				"expiryDate",
				nameof<ReservationGraphQlDto>((x) => x.expiryDate)
			),
			render(value, record, index) {
				return value ? moment(value).format("DD.MM.YYYY:HH:MM.ss") : ""
			},
		},
		{
			...columnHelper(
				"tagId",
				nameof<ReservationGraphQlDto>((x) => x.tagId)
			),
		},
		{
			...columnHelper(
				"status",
				nameof<ReservationGraphQlDto>((x) => x.status)
			),
			render(value, record, index) {
				return ReservationStatusEnum[value]
			},
		},
		{
			title: t("labels.action"),
			key: "action",
			render: (_, record) => {
				return record.status === ReservationStatusEnum.New ? (
					<Button
						onClick={async () => {
							await dispatch(cancelReservationAsync({ id: record.chargePointId, reservationId: record.id })).unwrap()
						}}
					>
						Cancel
					</Button>
				) : (
					<></>
				)
			},
			width: 100,
		},
	]

	return (
		<div style={{ maxHeight: "100%", height: "100%", display: "flex", flexDirection: "column", gap: 16 }}>
			<div
				className={styles["chargerWrapper"]}
				style={{ flex: "1 1 auto", overflow: "hidden", height: "100%", padding: "24px" }}
			>
				<MainTable
					onChange={(pagination, filter, sorter) => {
						handleGraphqlFilterTableChange(pagination, defaultOrder, sorter, filter, (newParams) => {
							setQueryParams({
								...queryParams,
								...newParams,
							})
						})
					}}
					loading={loading}
					columns={columns}
					dataSource={data?.query?.items}
					totalCount={data?.query?.totalCount}
				/>
			</div>
			<div
				style={{ flex: "1 1 auto", overflow: "hidden", height: "100%", padding: "24px" }}
				className={styles["chargerWrapper"]}
			>
				<Row gutter={[16, 16]} style={{ height: "100%" }}>
					<Col style={{ height: "100%" }} flex="auto"></Col>
					<Col style={{ width: "400px", height: "100%" }}></Col>
				</Row>
			</div>
		</div>
	)
}

export default ChargePointReservationsTab
