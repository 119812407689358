import { Card, Col, Row } from "antd"
import {
	ChargePointGraphQlDto,
	ChargePointStatisticsGraphQlDto,
} from "../../../../../features/chargePoints/graphql/types"
import React, { useState, useTransition } from "react"

import { BankOutlined } from "@ant-design/icons"
import { ReactComponent as CardIcon } from "../../../../../assets/cardIcon.svg"
import { ChargePointStateEnum } from "../../../../../common/enums/chargePointStateEnum"
import { ChargeTagStatusEnum } from "../../../../../common/enums/chargeTagStatusEnum"
import { ChargeTagTypeEnum } from "../../../../../common/enums/chargeTagTypeEnum"
import { DashbaordFilterModel } from "../../../../../features/dashboard/graphql/types"
import DashboardPieChart from "../../../../../components/charts/DashboardPieChart"
import GeneralStatisticsCard from "../../../dashboard/cards/GeneralStatisticsCard"
import { QUERY_CHARGEPOINT_STATISTICS_TAGS } from "../../../../../features/chargePoints/graphql/queries"
import SkeletCard from "../../../../../components/cards/SkeletCard"
import TransactionsChartCard from "../../../dashboard/cards/TransactionsChartCard"
import { mapToChartModel } from "../../../../../common/helpers/modelHelperts"
import nav from "../../../../../common/navigation/nav.json"
import styles from "../../ChargePoint.module.scss"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

interface Props {
	chargePoint: ChargePointGraphQlDto
}

const ChargePointStatisticsTab = ({ chargePoint }: Props) => {
	const { t } = useTranslation()

	const [filter] = useState<DashbaordFilterModel>({
		chargePointIds: [chargePoint.id],
	})

	const { data } = useQuery<ChargePointStatisticsGraphQlDto>(QUERY_CHARGEPOINT_STATISTICS_TAGS, {
		variables: {
			id: chargePoint.id,
		},
	})

	return (
		<div className={styles["layout-content"]}>
			<Row gutter={[24, 0]}>
				<Col className={styles["mb-24"]} span={24}>
					<GeneralStatisticsCard showFilterIcon={false} globalFilter={filter} />
				</Col>
			</Row>
			<Row gutter={[24, 24]} style={{ height: "calc(100% - 374px)", minHeight: "300px" }}>
				<Col style={{ height: "100%", width: "100%" }} xs={24} sm={24} md={24} lg={24} xl={16}>
					<TransactionsChartCard showFilterIcon={false} globalFilter={filter} />
				</Col>
				<Col style={{ height: "100%", width: "100%" }} xs={24} sm={24} md={24} lg={24} xl={8}>
					{data?.chargeTagCounts ? (
						<DashboardPieChart
							count={data.chargeTagCounts.totalCount}
							todaysCount={data.chargeTagCounts.todaysCount}
							title={t("titles.chargeTags")}
							icon={<CardIcon />}
							navigatePath={nav.admin.chargeTags.base}
							charts={[
								{
									title: t("labels.type"),
									data: mapToChartModel(
										data.chargeTagCounts.typeCounts,
										t,
										ChargeTagTypeEnum,
										"chargeTagTypeEnum",
										t("texts.unknown")
									),
								},
								{
									title: t("labels.status"),
									data: mapToChartModel(
										data.chargeTagCounts.stateCounts,
										t,
										ChargeTagStatusEnum,
										"chargeTagStatusEnum",
										t("texts.unknown")
									),
								},
							]}
						/>
					) : (
						<SkeletCard />
					)}
				</Col>
			</Row>
		</div>
	)
}

export default ChargePointStatisticsTab
