import { GraphqlItemsModel, GraphqlOrderModel, GraphqlQueryItemBaseModel } from "../../../common/models/graphqlModels"
import { WatchQueryFetchPolicy, useQuery } from "@apollo/client"

import { CompanyGraphqlDropdownDto } from "./types"
import { QUERY_COMPANIES_DROPDOWN } from "./queries"

export const useCompaniesDropdownQuery = (
	fetchPolicy?: WatchQueryFetchPolicy | undefined,
	order: any = [{ name: "ASC" }]
) =>
	useQuery<GraphqlQueryItemBaseModel<GraphqlItemsModel<CompanyGraphqlDropdownDto>>, GraphqlOrderModel>(
		QUERY_COMPANIES_DROPDOWN,
		{
			fetchPolicy: fetchPolicy,
			variables: {
				order: order,
			},
			notifyOnNetworkStatusChange: true,
		}
	)
