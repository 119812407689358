import { gql } from "@apollo/client"

export const QUERY_TRANSACTIONS = gql`
	query GetTransactions(
		$skip: Int
		$take: Int
		$order: [TransactionGraphQlDtoSortInput!]
		$chargePointId: Long
		$where: TransactionGraphQlDtoFilterInput
	) {
		query: transactions(skip: $skip, take: $take, order: $order, chargePointId: $chargePointId, where: $where) {
			totalCount
			items {
				id
				chargePointId
				connectorId
				startTagId
				startTagName
				startTime
				meterStart
				startResult
				stopTagId
				stopTagName
				stopTime
				meterStop
				stopReason
				reservationId
				chargeDuration
				chargeValue
				transactionTypeId
				chargePointName
				user
				measurandTypes
			}
		}
	}
`

export const QUERY_TRANSACTION = gql`
	query GetTransaction($id: Long!) {
		query: transaction(id: $id) {
			id
			chargePointId
			connectorId
			startTagId
			startTagName
			startTime
			meterStart
			startResult
			stopTagId
			stopTagName
			stopTime
			meterStop
			stopReason
			reservationId
			chargeDuration
			chargeValue
			transactionTypeId
			chargePointName
			user
			measurandTypes
		}
	}
`

export const QUERY_TRANSACTION_METER_VALUES = gql`
	query GetTransactionMeterValues($transactionId: Long!, $measurandTypeId: Long!) {
		query: transactionMeterValues(transactionId: $transactionId, measurandTypeId: $measurandTypeId) {
			phase
			unit
			min
			max
			avg
			sum
			data {
				date
				value
				unit
			}
		}
	}
`
