//import "./dashboard.css"

import { BankOutlined, FilterFilled } from "@ant-design/icons"
import { Button, Card, Col, Flex, FloatButton, Form, Popover, Row, Typography } from "antd"
import { DashbaordFilterModel, DashboardAdminGraphqlDto } from "../../../features/dashboard/graphql/types"
import React, { useState } from "react"

import { ReactComponent as CardIcon } from "../../../assets/cardIcon.svg"
import { ChargePointStateEnum } from "../../../common/enums/chargePointStateEnum"
import { ChargeTagStatusEnum } from "../../../common/enums/chargeTagStatusEnum"
import { ChargeTagTypeEnum } from "../../../common/enums/chargeTagTypeEnum"
import { ReactComponent as ChargerIcon } from "../../../assets/chargerIcon.svg"
import DashboardPieChart from "../../../components/charts/DashboardPieChart"
import FilterPopover from "../../../components/popovers/FilterPopover"
import GeneralStatisticsCard from "./cards/GeneralStatisticsCard"
import { ReactComponent as PersonIcon } from "../../../assets/personIcon.svg"
import { QUERY_ADMIN_DASHBOARD } from "../../../features/dashboard/graphql/queries"
import SelectFormItem from "../../../components/formItems/SelectFormItem"
import SkeletCard from "../../../components/cards/SkeletCard"
import TransactionsChartCard from "./cards/TransactionsChartCard"
import { mapToChartModel } from "../../../common/helpers/modelHelperts"
import { nameof } from "ts-simple-nameof"
import nav from "../../../common/navigation/nav.json"
import styles from "./Dashboard.module.scss"
import { useChargePointsDropdownQuery } from "../../../features/chargePoints/graphql/hooks"
import { useCompaniesDropdownQuery } from "../../../features/companies/graphql/hooks"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { useUsersDropdownQuery } from "../../../features/users/graphql/hooks"

const Dashboard = () => {
	const { t } = useTranslation()

	const { data } = useQuery<DashboardAdminGraphqlDto>(QUERY_ADMIN_DASHBOARD, {
		notifyOnNetworkStatusChange: true,
	})

	const [filter, setFilter] = useState<DashbaordFilterModel>(undefined)

	return (
		<div className={styles["layout-content"]}>
			<Row gutter={[24, 0]}>
				<Col xs={24} sm={24} md={12} lg={12} xl={6} className={styles["mb-24"]}>
					{data?.chargePointCounts ? (
						<DashboardPieChart
							count={data.chargePointCounts.totalCount}
							todaysCount={data.chargePointCounts.todaysCount}
							title={t("titles.chargePoints")}
							icon={<ChargerIcon />}
							navigatePath={nav.admin.chargePoints.base}
							charts={[
								{
									title: t("labels.type"),
									data: [
										{ name: t("texts.public"), value: data.chargePointCounts.publicCount },
										{ name: t("texts.private"), value: data.chargePointCounts.privateCount },
									],
								},
								{
									title: t("labels.status"),
									data: mapToChartModel(
										data.chargePointCounts.stateCounts,
										t,
										ChargePointStateEnum,
										"chargePointState",
										t("texts.unknown")
									),
								},
							]}
						/>
					) : (
						<SkeletCard />
					)}
				</Col>

				<Col xs={24} sm={24} md={12} lg={12} xl={6} className={styles["mb-24"]}>
					{data?.chargeTagCounts ? (
						<DashboardPieChart
							count={data.chargeTagCounts.totalCount}
							todaysCount={data.chargeTagCounts.todaysCount}
							title={t("titles.chargeTags")}
							icon={<CardIcon />}
							navigatePath={nav.admin.chargeTags.base}
							charts={[
								{
									title: t("labels.type"),
									data: mapToChartModel(
										data.chargeTagCounts.typeCounts,
										t,
										ChargeTagTypeEnum,
										"chargeTagTypeEnum",
										t("texts.unknown")
									),
								},
								{
									title: t("labels.status"),
									data: mapToChartModel(
										data.chargeTagCounts.stateCounts,
										t,
										ChargeTagStatusEnum,
										"chargeTagStatusEnum",
										t("texts.unknown")
									),
								},
							]}
						/>
					) : (
						<SkeletCard />
					)}
				</Col>

				<Col className={styles["mb-24"]} xs={24} sm={24} md={24} lg={24} xl={12}>
					<Row gutter={[24, 0]}>
						<Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles["mb-24"]}>
							{data?.userCounts ? (
								<DashboardPieChart
									count={data.userCounts.totalCount}
									todaysCount={data.userCounts.todaysCount}
									title={t("titles.users")}
									icon={<PersonIcon />}
									navigatePath={nav.admin.users.base}
								/>
							) : (
								<SkeletCard />
							)}
						</Col>

						<Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles["mb-24"]}>
							{data?.companyCounts ? (
								<DashboardPieChart
									count={data.companyCounts.totalCount}
									todaysCount={data.companyCounts.todaysCount}
									title={t("titles.companies")}
									icon={<BankOutlined />}
									navigatePath={nav.admin.companies.base}
								/>
							) : (
								<SkeletCard />
							)}
						</Col>
					</Row>
					<Row gutter={[24, 0]}>
						<Col span={24}>
							<GeneralStatisticsCard globalFilter={filter} />
						</Col>
					</Row>
				</Col>
			</Row>
			<Row gutter={[24, 24]} style={{ height: "calc(100% - 374px)", minHeight: "300px" }}>
				<Col style={{ height: "100%", width: "100%" }} xs={24} sm={24} md={24} lg={24} xl={12}>
					<TransactionsChartCard globalFilter={filter} />
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card style={{ height: "100%" }} bordered={false} className={styles.criclebox}></Card>
				</Col>
			</Row>
			<FilterPopover onFilterChange={setFilter} />
		</div>
	)
}

export default Dashboard
