export enum MeterValueMeasurandEnum {
	EnergyActiveExportRegister = 0,
	EnergyActiveImportRegister = 1,
	EnergyReactiveExportRegister = 2,
	EnergyReactiveImportRegister = 3,
	EnergyActiveExportInterval = 4,
	EnergyActiveImportInterval = 5,
	EnergyReactiveExportInterval = 6,
	EnergyReactiveImportInterval = 7,
	PowerActiveExport = 8,
	PowerActiveImport = 9,
	PowerOffered = 10,
	PowerReactiveExport = 11,
	PowerReactiveImport = 12,
	PowerFactor = 13,
	CurrentImport = 14,
	CurrentExport = 15,
	CurrentOffered = 16,
	Voltage = 17,
	Frequency = 18,
	Temperature = 19,
	SoC = 20,
	RPM = 21,
}
