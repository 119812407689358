import { Badge, FloatButton, Space, Table, Typography } from "antd"
import { DeleteOutlined, EditOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { GraphqlQueryItemsBaseModel, GraphqlTableQueryParamsBaseModel } from "../../../common/models/graphqlModels"
import React, { useState } from "react"
import { dateTimeFormat, defaultTablePageSize } from "../../../common/constants/constants"
import { getEnumSelectOptions, getEnumValues } from "../../../common/helpers/enumHelpers"
import {
	handleGraphqlFilterTableChange,
	initGraphqlTableQueryParamsBaseModel,
} from "../../../common/helpers/graphQlHelpers"

import { ChargePointAvailabilityEnum } from "../../../common/enums/chargePointAvailabilityEnum"
import ChargePointEditModal from "./modals/ChargePointEditModal"
import { ChargePointGraphQlDto } from "../../../features/chargePoints/graphql/types"
import { ChargePointStateEnum } from "../../../common/enums/chargePointStateEnum"
import type { ColumnType } from "antd/es/table"
import MainTable from "../../../components/tables/MainTable"
import { QUERY_ALL_CHARGEPOINTS } from "../../../features/chargePoints/graphql/queries"
import { columnHelper } from "../../../common/helpers/tableHelpers"
import dayjs from "dayjs"
import { deleteChargePointAsync } from "../../../features/chargePoints/chargePointSlice"
import { getBadge } from "../../../common/helpers/chargePointAvailabilityHelper"
import { nameof } from "ts-simple-nameof"
import { useAppDispatch } from "../../../app/hooks"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

const ChargePoints = () => {
	const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>()
	const navigate = useNavigate()
	const [itemToEdit, setItemToEdit] = useState<ChargePointGraphQlDto>(undefined)

	const dispatch = useAppDispatch()
	const { t } = useTranslation()

	const defaultOrder = [{ name: "ASC" }]

	const [queryParams, setQueryParams] = useState<GraphqlTableQueryParamsBaseModel>({
		...initGraphqlTableQueryParamsBaseModel(defaultOrder),
	})

	const { data, loading, refetch } = useQuery<
		GraphqlQueryItemsBaseModel<ChargePointGraphQlDto>,
		GraphqlTableQueryParamsBaseModel
	>(QUERY_ALL_CHARGEPOINTS, {
		variables: queryParams,
		notifyOnNetworkStatusChange: true,
	})

	const columns: ColumnType<ChargePointGraphQlDto>[] = [
		{
			...columnHelper(
				t("labels.chargePointId"),
				nameof<ChargePointGraphQlDto>((x) => x.chargePointId),
				"Text"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.name"),
				nameof<ChargePointGraphQlDto>((x) => x.name),
				"Text"
			),
			sorter: true,
			render(value, record, index) {
				return record.externalLink ? (
					<Typography.Link style={{ textDecoration: "underline" }} target="_blank" href={record.externalLink}>
						{value}
					</Typography.Link>
				) : (
					value
				)
			},
		},
		{
			...columnHelper(
				t("labels.company"),
				nameof<ChargePointGraphQlDto>((x) => x.company),
				"Text"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.status"),
				nameof<ChargePointGraphQlDto>((x) => x.chargePointStateId),
				"MultiSelect",
				getEnumSelectOptions(ChargePointStateEnum, t, "chargePointState")
			),
			sorter: true,
			render(value) {
				return !value ? "" : t(`chargePointState.${ChargePointStateEnum[+value]}`)
			},
		},
		{
			...columnHelper(
				t("labels.public"),
				nameof<ChargePointGraphQlDto>((x) => x.isPublic),
				"Boolean",
				[
					{ label: t("texts.yes"), value: 1 },
					{ label: t("texts.no"), value: 0 },
				]
			),
			sorter: true,
			render: (value: boolean) => (value ? t("texts.yes") : t("texts.no")),
		},
		{
			...columnHelper(
				t("labels.connectors"),
				nameof<ChargePointGraphQlDto>((x) => x.connectorCount),
				"Number"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.transactionCount"),
				nameof<ChargePointGraphQlDto>((x) => x.transactionCount),
				"Number"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.lastCommunication"),
				nameof<ChargePointGraphQlDto>((x) => x.lastCommunicationTime),
				"Date"
			),
			render(value, record, index) {
				return value ? dayjs(value).format(dateTimeFormat) : ""
			},
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.availability"),
				nameof<ChargePointGraphQlDto>((x) => x.availabilityState),
				"MultiSelect",
				getEnumSelectOptions(ChargePointAvailabilityEnum, t, "chargePointAvailability")
			),
			render(value) {
				return getBadge(value)
			},
			sorter: true,
			fixed: "right",
		},
		{
			title: t("labels.action"),
			key: "action",
			width: 140,
			render: (_, record) => (
				<Space size="large">
					<InfoCircleOutlined style={{ fontSize: "16px" }} onClick={() => navigate(`${record.id}`)} />
					<EditOutlined style={{ fontSize: "16px" }} onClick={() => onEditClick(record)} />
					<DeleteOutlined style={{ fontSize: "16px" }} onClick={() => onDeleteClick(record)} />
				</Space>
			),
			fixed: "right",
		},
	]

	const onEditClick = async (item: ChargePointGraphQlDto) => {
		setItemToEdit(item)
		setIsEditModalOpen(true)
	}

	const onDeleteClick = async (item: ChargePointGraphQlDto) => {
		await dispatch(deleteChargePointAsync({ id: item.id })).unwrap()
	}

	const onEditModalClose = () => {
		setItemToEdit(undefined)
		setIsEditModalOpen(false)
	}

	return (
		<>
			<MainTable
				columns={columns}
				onChange={(pagination, filter, sorter) => {
					handleGraphqlFilterTableChange(pagination, defaultOrder, sorter, filter, (newParams) => {
						setQueryParams({
							...queryParams,
							...newParams,
						})
					})
				}}
				loading={loading}
				dataSource={data?.query?.items ?? []}
				totalCount={data?.query?.totalCount}
			/>
			<FloatButton
				style={{ top: "74px", width: "48px", height: "48px" }}
				type="primary"
				icon={<PlusOutlined />}
				onClick={() => setIsEditModalOpen(true)}
			/>
			<ChargePointEditModal
				id={itemToEdit?.id}
				open={isEditModalOpen}
				onClose={(withReload) => {
					onEditModalClose()
					if (withReload) refetch()
				}}
			/>
		</>
	)
}

export default ChargePoints
