import { Button, Table } from "antd"
import { EditOutlined, InfoCircleFilled, InfoCircleOutlined } from "@ant-design/icons"
import { GraphqlQueryItemsBaseModel, GraphqlTableQueryParamsBaseModel } from "../../../common/models/graphqlModels"
import React, { useEffect, useState } from "react"
import {
	applyDefaultQueryFilter,
	handleGraphqlFilterTableChange,
	initGraphqlTableQueryParamsBaseModel,
} from "../../../common/helpers/graphQlHelpers"
import { dateTimeFormat, globalRed } from "../../../common/constants/constants"

import type { ColumnType } from "antd/es/table"
import MainTable from "../../../components/tables/MainTable"
import { QUERY_TRANSACTIONS } from "../../../features/transactions/graphql/quries"
import { TransactionGraphQlDto } from "../../../features/transactions/graphql/types"
import { TransactionTypeEnum } from "../../../common/enums/transactionTypeEnum"
import { columnHelper } from "../../../common/helpers/tableHelpers"
import dayjs from "dayjs"
import { getEnumSelectOptions } from "../../../common/helpers/enumHelpers"
import moment from "moment"
import { nameof } from "ts-simple-nameof"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

const Transactions = () => {
	const defaultOrder = [{ startTime: "DESC" }]

	const [queryParams, setQueryParams] = useState<GraphqlTableQueryParamsBaseModel>({
		...initGraphqlTableQueryParamsBaseModel(defaultOrder),
	})

	const navigate = useNavigate()

	const { data, loading } = useQuery<
		GraphqlQueryItemsBaseModel<TransactionGraphQlDto>,
		GraphqlTableQueryParamsBaseModel
	>(QUERY_TRANSACTIONS, {
		notifyOnNetworkStatusChange: true,
		variables: queryParams,
		fetchPolicy: "cache-and-network",
	})

	const { t } = useTranslation()

	const userColumns: ColumnType<TransactionGraphQlDto>[] = [
		{
			...columnHelper(
				t("labels.chargePoint"),
				nameof<TransactionGraphQlDto>((x) => x.chargePointName),
				"Text"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.user"),
				nameof<TransactionGraphQlDto>((x) => x.user),
				"Text"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.chargeTag"),
				nameof<TransactionGraphQlDto>((x) => x.startTagName),
				"Text"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.type"),
				nameof<TransactionGraphQlDto>((x) => x.transactionTypeId),
				"MultiSelect",
				getEnumSelectOptions(TransactionTypeEnum, t, "transactionTypeEnum")
			),
			render(value) {
				return t(`transactionTypeEnum.${TransactionTypeEnum[value ?? TransactionTypeEnum.Unspecified]}`)
			},
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.startTime"),
				nameof<TransactionGraphQlDto>((x) => x.startTime),
				"Date"
			),
			render(value) {
				return value ? dayjs(value).format(dateTimeFormat) : ""
			},
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.stopTime"),
				nameof<TransactionGraphQlDto>((x) => x.stopTime),
				"Date"
			),
			render(value) {
				return value ? dayjs(value).format(dateTimeFormat) : ""
			},
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.duration"),
				nameof<TransactionGraphQlDto>((x) => x.chargeDuration)
			),
			render(value) {
				return value ? moment.utc(value * 1000 * 60).format("HH:mm") : ""
			},
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.meterStart"),
				nameof<TransactionGraphQlDto>((x) => x.meterStart),
				"Number"
			),
			render(value) {
				return value ? `${value.toFixed(2)} KW` : ""
			},
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.meterStop"),
				nameof<TransactionGraphQlDto>((x) => x.meterStop),
				"Number"
			),
			render(value) {
				return value ? `${value.toFixed(2)} KW` : ""
			},
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.totalEnergy"),
				nameof<TransactionGraphQlDto>((x) => x.chargeValue),
				"Number"
			),
			render(value) {
				return value ? `${value.toFixed(2)} KW` : ""
			},
			sorter: true,
		},
		{
			...columnHelper("", "actions", "None"),
			render(_, record) {
				return (
					<InfoCircleOutlined style={{ fontSize: "16px", color: globalRed }} onClick={() => navigate(`${record.id}`)} />
				)
			},
			fixed: "right",
			width: 64,
			align: "center",
		},
	]

	return (
		<MainTable
			loading={loading}
			columns={userColumns}
			dataSource={data?.query?.items}
			onChange={(pagination, filter, sorter) => {
				handleGraphqlFilterTableChange(pagination, defaultOrder, sorter, filter, (newParams) => {
					setQueryParams({
						...queryParams,
						...newParams,
					})
				})
			}}
			totalCount={data?.query?.totalCount}
		/>
	)
}

export default Transactions
