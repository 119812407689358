import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { Card, Col, Flex, Row, Segmented, Space, Spin } from "antd"
import {
	DahboardTransactionChartDataGraphqlDto,
	DashbaordFilterModel,
} from "../../../../features/dashboard/graphql/types"
import { getEnumSelectOptions, toDateFormat, toDateRange } from "../../../../common/helpers/enumHelpers"
import { useEffect, useState } from "react"
import { useLazyQuery, useQuery } from "@apollo/client"

import { DateFilterTypeEnum } from "../../../../common/enums/dateFilterTypeEnum"
import { FilterFilled } from "@ant-design/icons"
import { GraphqlQueryItemBaseModel } from "../../../../common/models/graphqlModels"
import { QUERY_TRANSACTIONS_CHART_DATA } from "../../../../features/dashboard/graphql/queries"
import dayjs from "dayjs"
import styles from "../Dashboard.module.scss"
import { use } from "i18next"
import { useTranslation } from "react-i18next"

interface Props {
	globalFilter: DashbaordFilterModel | undefined
	showFilterIcon?: boolean
}

const TransactionsChartCard = ({ globalFilter, showFilterIcon = true }: Props) => {
	const { t } = useTranslation()
	const [dateFilter, setDateFilter] = useState<DateFilterTypeEnum>(DateFilterTypeEnum.Week)

	const defaultRange = toDateRange(dateFilter)

	const [queryParams, setQueryParams] = useState<any>({
		fromTimestamp: defaultRange[0],
		toTimestamp: defaultRange[1],
		dateRangeType: defaultRange[2],
	})

	const { data, loading } = useQuery<GraphqlQueryItemBaseModel<DahboardTransactionChartDataGraphqlDto[]>>(
		QUERY_TRANSACTIONS_CHART_DATA,
		{
			variables: queryParams,
			notifyOnNetworkStatusChange: true,
			fetchPolicy: "network-only",
		}
	)

	useEffect(() => {
		const range = toDateRange(dateFilter)

		let newParams = {
			fromTimestamp: range[0],
			toTimestamp: range[1],
			dateRangeType: range[2],
		}

		if (globalFilter) {
			newParams = {
				...newParams,
				...globalFilter,
			}
		}

		setQueryParams(newParams)
	}, [dateFilter, globalFilter])

	return (
		<Card bordered={false} className={`${styles["transactionsChartCard"]} ${styles.criclebox}`}>
			<Spin spinning={loading}>
				<Flex style={{ height: "100%", width: "100%" }} vertical>
					<Row gutter={16} justify={"space-between"}>
						<Col>
							{showFilterIcon && (
								<FilterFilled className={styles[globalFilter ? "activeFilterIcon" : "normalFilterIcon"]} />
							)}
						</Col>
						<Col>
							<Segmented
								size="small"
								value={dateFilter}
								onChange={(value: number) => {
									setDateFilter(value)
								}}
								options={getEnumSelectOptions(DateFilterTypeEnum, t, "dateFilterTypeEnum")
									.filter(
										(x) =>
											x.value !== DateFilterTypeEnum.Custom &&
											x.value !== DateFilterTypeEnum.Today &&
											x.value !== DateFilterTypeEnum.All
									)
									.map((x) => ({
										value: x.value,
										label: x.label,
									}))}
							/>
						</Col>
					</Row>

					<ResponsiveContainer width="100%" height="100%">
						<BarChart
							width={500}
							height={300}
							data={data?.query?.map((x) => ({
								date: dayjs(x.date).format(toDateFormat(dateFilter)),
								persons: x.personal,
								business: x.business,
								undefined: x.undefined,
							}))}
							margin={{
								top: 20,
								right: 30,
								left: 24,
								bottom: 8,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="date" />
							<YAxis unit={" kWh"} />
							<Tooltip />
							<Legend />
							<Bar name={t("chargeTagTypeEnum.Personal")} dataKey="personal" stackId="a" fill="#0088FE" />
							<Bar name={t("chargeTagTypeEnum.Business")} dataKey="business" stackId="a" fill="#00C49F" />
							<Bar name={t("texts.unknown")} dataKey="undefined" stackId="a" fill="#FFBB28" />
						</BarChart>
					</ResponsiveContainer>
				</Flex>
			</Spin>
		</Card>
	)
}

export default TransactionsChartCard
