import { BaseState, DropdownWrapper, SelectItem } from "../../common/models/customModels"
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { ChargeTagModel } from "./types"
import { DataResponseModel } from "../../common/models/responseModels"
import { FetchStatusEnum } from "../../common/enums/fetchStatusEnum"
import api from "../../common/api/api.json"
import { createMyOwnAsyncThunk } from "../../common/helpers/reduxToolkitHelper"
import { httpClient } from "../../common/helpers/httpClientHelper"
import { initDropdowmWrapperModel } from "../../common/helpers/modelHelperts"

interface chargeTagState extends BaseState {
	tagsDropdown: DropdownWrapper<SelectItem<number>>
}

const initialState: chargeTagState = {
	status: FetchStatusEnum.idle,
	tagsDropdown: initDropdowmWrapperModel(),
}

export const getChargeTagDetailAsync = createMyOwnAsyncThunk<ChargeTagModel, number>(
	"chargeTags/getChargeTagDetailAsync",
	(id) => {
		return httpClient.get<ChargeTagModel>(api.admin.tags.base + "/" + id)
	}
)

export const createChargeTagAsync = createMyOwnAsyncThunk<ChargeTagModel, FormData>(
	"chargeTags/createChargeTagAsync",
	(data) => {
		return httpClient.post<ChargeTagModel>(api.admin.tags.base, data)
	}
)

export const updateChargeTagAsync = createMyOwnAsyncThunk<ChargeTagModel, { id: number; data: FormData }>(
	"chargeTags/updateChargeTagAsync",
	({ id, data }) => {
		return httpClient.put<ChargeTagModel>(api.admin.tags.base + "/" + id, data)
	}
)

export const deleteChargeTagAsync = createMyOwnAsyncThunk<ChargeTagModel, { id: number }>(
	"chargeTags/deleteChargeTagAsync",
	({ id }) => {
		return httpClient.delete<ChargeTagModel>(api.admin.tags.base + "/" + id)
	}
)

export const getTagsDropdownSourceAsync = createMyOwnAsyncThunk<SelectItem<number>[], void>(
	"companies/getTagsDropdownSourceAsync",
	() => {
		return httpClient.get<SelectItem<number>[]>(api.admin.tags.dropdown)
	}
)

export const chargeTagSlice = createSlice({
	name: "chargeTags",
	initialState,
	reducers: {
		setStatus: (state, action: PayloadAction<FetchStatusEnum>) => {
			state.status = action.payload
		},
	},

	extraReducers: (builder) => {
		builder.addCase(getChargeTagDetailAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(getChargeTagDetailAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(getChargeTagDetailAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})

		builder.addCase(createChargeTagAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(createChargeTagAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(createChargeTagAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})

		builder.addCase(updateChargeTagAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(updateChargeTagAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(updateChargeTagAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})

		builder.addCase(deleteChargeTagAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(deleteChargeTagAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(deleteChargeTagAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})

		builder.addCase(getTagsDropdownSourceAsync.pending, (state, action) => {
			state.tagsDropdown.status = FetchStatusEnum.loading
		})
		builder.addCase(getTagsDropdownSourceAsync.fulfilled, (state, action) => {
			state.tagsDropdown.data = action.payload
			state.tagsDropdown.status = FetchStatusEnum.success
		})
		builder.addCase(getTagsDropdownSourceAsync.rejected, (state, action) => {
			state.tagsDropdown.status = FetchStatusEnum.failed
		})
	},
})

export default chargeTagSlice.reducer
