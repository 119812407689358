import { Button, Card, Row, Space, Typography } from "antd"
import { ReactNode, useEffect, useState } from "react"

import HamsterLoader from "../loaders/HamsterLoader"
import logo from "../../assets/logo.png"
import nav from "../../common/navigation/nav.json"
import { useOidc } from "@axa-fr/react-oidc"
import { useTranslation } from "react-i18next"

interface Props extends React.PropsWithChildren<{}> {
	waitBeforeShow?: number
}

const Delayed = ({ children, waitBeforeShow = 500 }: Props) => {
	const [isShown, setIsShown] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsShown(true)
		}, waitBeforeShow)
		return () => clearTimeout(timer)
	}, [waitBeforeShow])

	return isShown ? <>{children}</> : null
}

const getLoadingContainer = (text: string): ReactNode => {
	return (
		<Delayed>
			<Row align="middle" justify="center" style={{ width: "100%", height: "100%" }}>
				<Card
					headStyle={{ padding: "16px" }}
					title={<img width="100%" height="auto" alt="logo" src={logo}></img>}
					style={{ width: "400px" }}
					bodyStyle={{ display: "flex", justifyContent: "center" }}
				>
					<Space size="middle" align="center" direction="vertical">
						<HamsterLoader />
						<Typography.Title level={5}>{text}</Typography.Title>
					</Space>
				</Card>
			</Row>
		</Delayed>
	)
}

export const Loading = () => {
	const { t } = useTranslation()
	return <>{getLoadingContainer(t("texts.loadingPleaseWait"))}</>
}
export const AuthenticatingError = () => {
	const { t } = useTranslation()
	const { login } = useOidc()

	return (
		<Row align="middle" justify="center" style={{ width: "100%", height: "100%" }}>
			<Card
				title={<img width="100%" height="auto" alt="logo" src={logo}></img>}
				style={{ width: "400px" }}
				headStyle={{ padding: "16px" }}
				bodyStyle={{ display: "flex", justifyContent: "center" }}
			>
				<Space align="center" size="large" direction="vertical">
					<Space style={{ textAlign: "center" }} align="center" direction="vertical">
						<Typography.Text style={{ fontSize: "30px" }}>{t("texts.loginFailedSubtitle")}</Typography.Text>
						<Typography.Text style={{ textAlign: "center" }}>{t("texts.loginFailedTitle")}</Typography.Text>
					</Space>
					<Space align="center">
						<Button onClick={() => (window.location.href = nav.dashboard.base)}>{t("btns.mainPage")}</Button>
						<Button type="primary" onClick={() => login()}>
							{t("btns.login")}
						</Button>
					</Space>
				</Space>
			</Card>
		</Row>
	)
}
export const Authenticating = () => {
	const { t } = useTranslation()
	return <>{getLoadingContainer(t("texts.signingYouIn"))}</>
}

export const CallBackSuccess = () => {
	const { t } = useTranslation()
	return <>{getLoadingContainer(t("texts.loginSuccess"))}</>
}
