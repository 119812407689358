import "bootstrap/dist/css/bootstrap.css"
import "leaflet/dist/leaflet.css"

import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

import { Authenticating, AuthenticatingError, CallBackSuccess, Loading } from "./components/oidc/OidcComponents"
import { ConfigProvider, ThemeConfig } from "antd"
import { OidcConfiguration, OidcProvider, TokenRenewMode } from "@axa-fr/react-oidc"

import { ApolloProvider } from "@apollo/client"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import React from "react"
import UTC from "dayjs/plugin/utc"
import { createRoot } from "react-dom/client"
import dayjs from "dayjs"
import { graphqlClient } from "./common/grapqhl/graphqlClient"
import { httpClient } from "./common/helpers/httpClientHelper"
import reportWebVitals from "./reportWebVitals"
import { setupAxiosInterceptors } from "./common/interceptors/axiosInterceptors"
import { store } from "./app/store"

dayjs.extend(UTC)
setupAxiosInterceptors(store)

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href")
const rootElement = document.getElementById("root")
const root = createRoot(rootElement!)

const baseItemsSizes = {
	fontSize: 14,
	controlHeight: 40,
	controlHeightLG: 44,
	fontSizeLG: 16,
	fontSizeSM: 12,
	controlHeightSM: 32,
}

const baseItemsDisabled = {
	colorBgContainerDisabled: "RGBA(0, 0, 0, 0.01)",
	colorTextDisabled: "RGBA(0, 0, 0, 0.7)",
}

const themeConfig: ThemeConfig = {
	token: {
		colorPrimary: "rgb(226, 28, 33)",
		borderRadius: 4,
		colorPrimaryText: "#00b96b",
		colorError: "#E51919",
		colorBorder: "#E7EAF0",
	},
	components: {
		Menu: {
			itemPaddingInline: "24px",
			darkItemBg: "transparent",
		},
		Layout: {
			colorBgHeader: "black",
		},
		/*InputNumber: {
			...baseItemsSizes,
			...baseItemsDisabled,
		},
		Input: {
			...baseItemsSizes,
			...baseItemsDisabled,
		},
		DatePicker: {
			fontSize: 14,
			...baseItemsDisabled,
		},
		Button: {
			...baseItemsSizes,
			colorTextLightSolid: "#FFFFFF",
		},
		Radio: {
			colorTextLightSolid: "#FFFFFF",
			colorBgBase: "#F2F4F7",
			colorBorderBg: "transparent",
			...baseItemsSizes,
		},
		Statistic: {
			colorText: "#000",
			colorTextLightSolid: "#000",
		},
		Select: {
			...baseItemsSizes,
			colorBorder: "#E7EAF0",
			colorPrimaryText: "RGBA(0,0,0,1)",
			colorTextPlaceholder: "#000",
			...baseItemsDisabled,
		},*/
	},
}

const configurationIdentityServer: OidcConfiguration = {
	client_id: process.env.REACT_APP_OIDC_CLIENT_ID ?? "",
	redirect_uri: window.location.origin + process.env.REACT_APP_OIDC_REDIRECT_URI,
	silent_redirect_uri: window.location.origin + process.env.REACT_APP_OIDC_SILENT_REDIRECT_URI,
	scope: `openid email profile roles offline_access ${
		process.env.NODE_ENV !== "production" ? "reservation-portal-api" : "ocpp-api"
	}`,
	authority: process.env.REACT_APP_OIDC_AUTHORITY ?? "",
	service_worker_relative_url: "/OidcServiceWorker.js",
	service_worker_only: false,
	authority_time_cache_wellknowurl_in_second: 60 * 60 * 24,
}

root.render(
	<OidcProvider
		callbackSuccessComponent={CallBackSuccess}
		loadingComponent={Loading}
		sessionLostComponent={AuthenticatingError}
		configuration={configurationIdentityServer}
		authenticatingErrorComponent={AuthenticatingError}
		authenticatingComponent={Authenticating}
	>
		<BrowserRouter basename={baseUrl as any}>
			<Provider store={store}>
				<ConfigProvider theme={themeConfig}>
					<ApolloProvider client={graphqlClient}>
						<App />
					</ApolloProvider>
				</ConfigProvider>
			</Provider>
		</BrowserRouter>
	</OidcProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals()
