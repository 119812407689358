import { Card, Col, Divider, Flex, Row, Segmented, Spin, Statistic } from "antd"
import {
	DashbaordFilterModel,
	DashboardGeneralStatisticsGraphqlDto,
} from "../../../../features/dashboard/graphql/types"
import React, { useEffect, useState } from "react"
import { getEnumSelectOptions, toDateRange } from "../../../../common/helpers/enumHelpers"

import { DateFilterTypeEnum } from "../../../../common/enums/dateFilterTypeEnum"
import { FilterFilled } from "@ant-design/icons"
import { QUERY_ADMIN_DASHBOARD_GENERAL_STATISTICS } from "../../../../features/dashboard/graphql/queries"
import styles from "../Dashboard.module.scss"
import { t } from "i18next"
import { useQuery } from "@apollo/client"

interface Props {
	globalFilter: DashbaordFilterModel | undefined
	showFilterIcon?: boolean
}

export const GeneralStatisticsCard = ({ globalFilter, showFilterIcon = true }: Props) => {
	const [dateFilter, setDateFilter] = useState<DateFilterTypeEnum>(DateFilterTypeEnum.Week)
	const defaultRange = toDateRange(dateFilter)

	const [queryParams, setQueryParams] = useState<any>({
		fromTimestamp: defaultRange[0],
		toTimestamp: defaultRange[1],
	})

	const { data, loading } = useQuery<{ generalStatistics: DashboardGeneralStatisticsGraphqlDto }>(
		QUERY_ADMIN_DASHBOARD_GENERAL_STATISTICS,
		{
			variables: queryParams,
			notifyOnNetworkStatusChange: true,
			fetchPolicy: "network-only",
		}
	)

	useEffect(() => {
		const range = toDateRange(dateFilter)

		let newParams = {
			fromTimestamp: range[0],
			toTimestamp: range[1],
		}

		if (globalFilter) {
			newParams = {
				...newParams,
				...globalFilter,
			}
		}

		setQueryParams(newParams)
	}, [dateFilter, globalFilter])

	return (
		<Card bordered={false} className={styles.criclebox}>
			<Spin spinning={loading}>
				<Row gutter={16} justify="space-between" style={{ marginBottom: "24px" }}>
					<Col>
						{showFilterIcon && (
							<FilterFilled className={styles[globalFilter ? "activeFilterIcon" : "normalFilterIcon"]} />
						)}
					</Col>
					<Col>
						<Segmented
							size="small"
							value={dateFilter}
							onChange={(value: number) => {
								setDateFilter(value)
							}}
							options={getEnumSelectOptions(DateFilterTypeEnum, t, "dateFilterTypeEnum")
								.filter((x) => x.value !== DateFilterTypeEnum.Custom && x.value !== DateFilterTypeEnum.Today)
								.map((x) => ({
									value: x.value,
									label: x.label,
								}))}
						/>
					</Col>
				</Row>
				<Row className={styles["generalStatisticsWrapper"]} style={{ height: "120px" }} wrap={false} gutter={[16, 16]}>
					{data?.generalStatistics && (
						<>
							<Col flex="auto">
								<Flex style={{ height: "100%" }} vertical justify="space-between">
									<Statistic title={t("labels.totalEnergyInKwh")} value={data.generalStatistics.totalEnergy} />
									<span className={styles["bnb2"]}>{`${data.generalStatistics.todayEnergy > 0 ? "+" : ""}${
										data.generalStatistics.todayEnergy
									} ${t("texts.todayLower")}`}</span>
								</Flex>
							</Col>
							<Col flex="none">
								<Divider orientation="center" style={{ height: "120px" }} type="vertical" />
							</Col>
							<Col flex="auto">
								<Flex style={{ height: "100%" }} vertical justify="space-between">
									<Statistic title={t("labels.totalTransactions")} value={data.generalStatistics.totalTransactions} />
									<span className={styles["bnb2"]}>{`${data.generalStatistics.todayTransactions > 0 ? "+" : ""}${
										data.generalStatistics.todayTransactions
									} ${t("texts.todayLower")}`}</span>
								</Flex>
							</Col>
							<Col flex="none">
								<Divider orientation="center" style={{ height: "120px" }} type="vertical" />
							</Col>
							<Col flex="auto">
								<Flex style={{ height: "100%" }} vertical justify="space-between">
									<Statistic title={t("labels.totalReservations")} value={data.generalStatistics.totalReservations} />
									<span className={styles["bnb2"]}>{`${data.generalStatistics.todayReservations > 0 ? "+" : ""}${
										data.generalStatistics.todayReservations
									} ${t("texts.todayLower")}`}</span>
								</Flex>
							</Col>
						</>
					)}
				</Row>
			</Spin>
		</Card>
	)
}

export default GeneralStatisticsCard
