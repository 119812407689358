import { createCompanyAsync, updateCompanyAsync } from "../../../../features/companies/companySlice"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"

import BaseEditModal from "../../../../components/modals/BaseEditModal"
import { CompanyGraphqlDto } from "../../../../features/companies/graphql/types"
import { CompanyModel } from "../../../../features/companies/types"
import { FetchStatusEnum } from "../../../../common/enums/fetchStatusEnum"
import { Form } from "antd"
import FormItemWrapper from "../../../../components/formItems/FormItemWrapper"
import InputFormItem from "../../../../components/formItems/InputFormItem"
import { QUERY_COMPANY_DETAIL } from "../../../../features/companies/graphql/queries"
import { nameof } from "ts-simple-nameof"
import { objectToFormData } from "../../../../common/helpers/formDataHelper"
import { useEffect } from "react"
import { useForm } from "antd/es/form/Form"
import { useLazyQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

interface Props {
	open: boolean
	onClose: (withRefetch: boolean) => void
	id: number | undefined
}

const CompanyEditModal = ({ open, onClose, id }: Props) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [form] = useForm<CompanyModel>()

	const { status } = useAppSelector((x) => x.companies)

	const [getCompany, { loading }] = useLazyQuery<{ query: CompanyGraphqlDto | undefined }, { id: number }>(
		QUERY_COMPANY_DETAIL,
		{
			fetchPolicy: "network-only",
			notifyOnNetworkStatusChange: true,
			onCompleted(data) {
				form.setFieldsValue(data.query)
			},
		}
	)

	useEffect(() => {
		if (!open) {
			form.resetFields()
			return
		}

		if (!id) return

		getCompany({ variables: { id: id } })
	}, [open, id])

	const onEditFormSubmit = async (model: CompanyModel) => {
		const formData = objectToFormData(model)

		if (id) await dispatch(updateCompanyAsync({ id: id, data: formData })).unwrap()
		else await dispatch(createCompanyAsync(formData)).unwrap()

		onClose(true)
	}

	return (
		<BaseEditModal
			title={t("titles.editCompany")}
			isOpen={open}
			loading={loading || status === FetchStatusEnum.loading}
			onCancel={() => onClose(false)}
			onFormSubmit={form.submit}
		>
			<Form layout="vertical" form={form} onFinish={onEditFormSubmit}>
				<InputFormItem name={nameof<CompanyModel>((x) => x.id)} hidden />
				<InputFormItem name={nameof<CompanyModel>((x) => x.name)} isRequired label={t("labels.name")} maxLength={255} />
			</Form>
		</BaseEditModal>
	)
}

export default CompanyEditModal
