import { Card, Col, Flex, Row, Typography } from "antd"
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from "recharts"

import { ChartModel } from "../../common/models/customModels"
import { ReactNode } from "react"
import styles from "./Chart.module.scss"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

interface DashboardPieChartProps {
	count: number
	todaysCount: number
	title: string
	icon: ReactNode
	navigatePath: string
	charts?: { title: string; data: ChartModel[] }[]
}

const DashboardPieChart = ({ count, icon, navigatePath, title, todaysCount, charts }: DashboardPieChartProps) => {
	const { Title } = Typography
	const navigate = useNavigate()
	const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"]
	const { t } = useTranslation()

	const renderActiveShape = (props) => {
		const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props

		return (
			<g>
				<text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
					{payload.name}
				</text>
				<Sector
					cx={cx}
					cy={cy}
					innerRadius={innerRadius}
					outerRadius={outerRadius}
					startAngle={startAngle}
					endAngle={endAngle}
					fill={fill}
				/>
				<Sector
					cx={cx}
					cy={cy}
					startAngle={startAngle}
					endAngle={endAngle}
					innerRadius={outerRadius + 6}
					outerRadius={outerRadius + 10}
					fill={fill}
				/>
			</g>
		)
	}

	return (
		<Card bordered={false} className={styles.criclebox}>
			<div className={styles.number}>
				<Row align="middle" gutter={[24, 0]}>
					<Col flex="auto">
						<span>{title}</span>
						<Title level={3}>
							{count}{" "}
							<small>
								<span className={styles["bnb2"]}>{`${todaysCount > 0 ? "+" : ""}${todaysCount} ${t(
									"texts.todayLower"
								)}`}</span>
							</small>
						</Title>
					</Col>
					<Col flex="48px">
						<div onClick={() => navigate(navigatePath)} className={styles["icon-box"]}>
							{icon}
						</div>
					</Col>
				</Row>
			</div>
			<Row gutter={16} wrap={false}>
				{charts?.map((c, index) => (
					<Col key={index} span={12} style={{ width: "100%", height: "240px" }}>
						<Flex vertical justify="center" align="center" style={{ height: "100%", width: "100%" }}>
							<label style={{ textAlign: "center", fontWeight: "bold", marginTop: "12px" }}>{c.title}</label>
							<ResponsiveContainer>
								<PieChart>
									<Pie
										activeShape={renderActiveShape}
										data={c.data}
										cx="50%"
										cy="50%"
										legendType="circle"
										fill="#8884d8"
										label={false}
										labelLine={false}
										isAnimationActive={false}
										dataKey="value"
									>
										{c.data.map((entry, index) => (
											<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
										))}
									</Pie>
									<Legend height={44} align="left" iconSize={10} fontSize={12} />
									<Tooltip />
								</PieChart>
							</ResponsiveContainer>
						</Flex>
					</Col>
				))}
			</Row>
		</Card>
	)
}

export default DashboardPieChart
