import "react-horizontal-scrolling-menu/dist/styles.css"
import "react-horizontal-scrolling-menu/dist/styles.css"

import { Button, Card, Col, Divider, Flex, Row, Spin, Statistic } from "antd"

import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { QUERY_TRANSACTION } from "../../../features/transactions/graphql/quries"
import React from "react"
import { TransactionGraphQlDto } from "../../../features/transactions/graphql/types"
import { dateTimeFormat } from "../../../common/constants/constants"
import dayjs from "dayjs"
import moment from "moment"
import styles from "../Transactions.module.scss"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"

import { ScrollMenu, VisibilityContext, type publicApiType } from "react-horizontal-scrolling-menu"
import { useIntersectionObserver } from "usehooks-ts"

import "react-horizontal-scrolling-menu/dist/styles.css"
import { TransactionTypeEnum } from "../../../common/enums/transactionTypeEnum"
import { useTranslation } from "react-i18next"
import TransactionLineChart from "../../../components/charts/TransactionLineChart"
const OnScreenContext = React.createContext(true)

const TransactionDetail = () => {
	const { id } = useParams()
	const { t } = useTranslation()

	const { data, loading } = useQuery<{ query: TransactionGraphQlDto | undefined }, { id: number }>(QUERY_TRANSACTION, {
		variables: {
			id: +id,
		},
		notifyOnNetworkStatusChange: true,
	})

	const { isIntersecting: isVisible, ref } = useIntersectionObserver({
		threshold: 1,
	})

	return (
		<>
			<div style={{ height: "auto", overflow: "hidden", width: "100%" }}>
				<Spin className={styles["spinner"]} fullscreen spinning={loading} />
				{data?.query && (
					<div style={{ overflow: "hidden", width: "100%" }} ref={ref}>
						<OnScreenContext.Provider value={isVisible}>
							<Card
								styles={{
									body: {
										padding: "24px 8px",
									},
								}}
								style={{ marginBottom: "16px" }}
								bordered={false}
								className={styles.criclebox}
							>
								<ScrollMenu
									scrollContainerClassName={styles["generalStatisticsScrollMenu"]}
									wrapperClassName={styles["generalStatisticsScrollMenuWrapper"]}
									LeftArrow={LeftArrow}
									RightArrow={RightArrow}
									onWheel={onWheel}
								>
									<ScrollMenuItem key={1} title={t("labels.chargePoint")} value={data.query.chargePointName} />
									<ScrollMenuItem
										key={2}
										title={t("labels.startTime")}
										value={data.query.startTime ? dayjs(data.query.startTime).format(dateTimeFormat) : ""}
									/>
									<ScrollMenuItem
										key={3}
										title={t("labels.stopTime")}
										value={data.query.stopTime ? dayjs(data.query.stopTime).format(dateTimeFormat) : ""}
									/>
									<ScrollMenuItem
										key={4}
										title={t("labels.duration")}
										value={
											data.query.chargeDuration ? moment.utc(data.query.chargeDuration * 1000 * 60).format("HH:mm") : ""
										}
									/>
									<ScrollMenuItem key={5} title={t("labels.totalEnergyInKwh")} value={data.query.chargeValue} />
									<ScrollMenuItem
										key={6}
										title={t("labels.type")}
										value={t(
											`transactionTypeEnum.${
												TransactionTypeEnum[data.query.transactionTypeId ?? TransactionTypeEnum.Unspecified]
											}`
										)}
										showDivider={false}
									/>
									<ScrollMenuItem
										key={7}
										title={t("labels.chargeTag")}
										value={data.query.startTagName}
										showDivider={false}
									/>
								</ScrollMenu>
							</Card>
						</OnScreenContext.Provider>
					</div>
				)}
			</div>
			<Row style={{ height: "calc(100% - 124px)", overflowY: "auto", overflowX: "hidden" }} gutter={[16, 16]}>
				{data?.query?.measurandTypes?.map((measurandType) => (
					<Col xxl={12} xl={12} md={24} sm={24} xs={24}>
						<TransactionLineChart id={+id} type={measurandType} />
					</Col>
				))}
			</Row>
		</>
	)
}

export default TransactionDetail

interface ScrollMenuItemProps {
	title: string
	value: any
	key: any
	showDivider?: boolean
}

const ScrollMenuItem = ({ key, title, value, showDivider = true }: ScrollMenuItemProps) => {
	return (
		<div style={{ minWidth: "300px" }} data-cy={key} key={key}>
			<Flex style={{ width: "100%" }}>
				<Statistic style={{ width: "100%" }} title={title} value={value} />
				{showDivider && <Divider orientation="center" style={{ height: "64px", margin: "0px 16px" }} type="vertical" />}
			</Flex>
		</div>
	)
}

const onWheel = (apiObj: publicApiType, ev: React.WheelEvent): void => {
	const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15
	if (isThouchpad) {
		ev.stopPropagation()
		return
	}
	if (ev.deltaY < 0) {
		apiObj.scrollNext()
	} else {
		apiObj.scrollPrev()
	}
}

const LeftArrow = () => {
	const visibility = React.useContext<publicApiType>(VisibilityContext)
	const isFirstItemVisible = visibility.useIsVisible("first", true)

	const isOnScreen = React.useContext(OnScreenContext)
	const [disabled, setDisabled] = React.useState(isFirstItemVisible)
	React.useEffect(() => {
		if (isOnScreen) {
			setDisabled(isFirstItemVisible)
		}
	}, [isOnScreen, isFirstItemVisible])

	return (
		<Arrow disabled={disabled} onClick={() => visibility.scrollPrev()}>
			<LeftOutlined style={{ fontSize: "24px" }} />
		</Arrow>
	)
}

const RightArrow = () => {
	const visibility = React.useContext<publicApiType>(VisibilityContext)
	const isLastItemVisible = visibility.useIsVisible("last", false)

	const isOnScreen = React.useContext(OnScreenContext)
	const [disabled, setDisabled] = React.useState(isLastItemVisible)
	React.useEffect(() => {
		if (isOnScreen) {
			setDisabled(isLastItemVisible)
		}
	}, [isOnScreen, isLastItemVisible])

	return (
		<Arrow disabled={disabled} onClick={() => visibility.scrollNext()}>
			<RightOutlined style={{ fontSize: "24px" }} />
		</Arrow>
	)
}

const Arrow = ({
	children,
	disabled,
	onClick,
}: {
	children: React.ReactNode
	disabled: boolean
	onClick: VoidFunction
}) => {
	return (
		<Flex justify="center" align="center">
			<Button style={{ padding: "2px", height: "100%" }} type="text" disabled={disabled} onClick={onClick}>
				{children}
			</Button>
		</Flex>
	)
}
