import { gql } from "@apollo/client"

export const QUERY_CHARGETAGS = gql`
	query GetChargeTags(
		$skip: Int
		$take: Int
		$order: [ChargeTagGraphQlDtoSortInput!]
		$where: ChargeTagGraphQlDtoFilterInput
	) {
		query: chargeTags(skip: $skip, take: $take, order: $order, where: $where) {
			totalCount
			items {
				id
				tagId
				name
				expiryDate
				user
				chargeTagTypeId
				createTimestamp
				chargeTagStatusId
			}
		}
	}
`
