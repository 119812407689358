import { AsyncThunk, PayloadAction, SerializedError, createAsyncThunk } from "@reduxjs/toolkit"
import axios, { AxiosError, AxiosResponse } from "axios"

import { ErrorResponseModel } from "../models/responseModels"
import { RootState } from "../../app/store"
import { UploadFile } from "antd/lib/upload/interface"
import i18n from "../../app/i18n"
import { notifyError } from "./notificationHelpers"

interface ThunkAPIConfig {
	dispatch: any
	getState: () => RootState
}

export const createMyOwnAsyncThunk = <Returned, ThunkArg = any>(
	type: string,
	apiCall: (args: ThunkArg, thunkAPI: ThunkAPIConfig) => Promise<AxiosResponse<Returned>>
): AsyncThunk<Returned, ThunkArg, ThunkAPIConfig> => {
	return createAsyncThunk<Returned, ThunkArg, ThunkAPIConfig>(type, async (arg, thunkAPI) => {
		try {
			const result = await apiCall(arg, thunkAPI as any)
			return result.data
		} catch (err) {
			return thunkAPI.rejectWithValue(errorToErrorResponseModel(err)) as any
		}
	})
}

const errorToErrorResponseModel = (error: any): ErrorResponseModel => {
	if (error as AxiosError<ErrorResponseModel>) {
		return {
			status: (error as AxiosError).response?.status ?? 400,
			data: (error as AxiosError).response?.data ?? [i18n.t("notifications.error.common")],
		} as ErrorResponseModel
	}

	return {
		status: 400,
		data: [i18n.t("notifications.error.common")],
	} as ErrorResponseModel
}
