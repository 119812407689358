import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"
import { FloatButton, Space, Table } from "antd"
import { GraphqlQueryItemsBaseModel, GraphqlTableQueryParamsBaseModel } from "../../../common/models/graphqlModels"
import React, { useState } from "react"
import {
	handleGraphqlFilterTableChange,
	initGraphqlTableQueryParamsBaseModel,
} from "../../../common/helpers/graphQlHelpers"

import type { ColumnType } from "antd/es/table"
import MainTable from "../../../components/tables/MainTable"
import { QUERY_USERS } from "../../../features/users/graphql/queries"
import UserEditModal from "./modals/UserEditModal"
import { UserGraphqlDto } from "../../../features/users/graphql/types"
import { columnHelper } from "../../../common/helpers/tableHelpers"
import { defaultTablePageSize } from "../../../common/constants/constants"
import { deleteUserAsync } from "../../../features/users/usersSlice"
import { nameof } from "ts-simple-nameof"
import { useAppDispatch } from "../../../app/hooks"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

const Users = () => {
	const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>()
	const [idToEdit, setIdToEdit] = useState<number>(undefined)

	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const defaultOrder = [{ email: "ASC" }]

	const [queryParams, setQueryParams] = useState<GraphqlTableQueryParamsBaseModel>({
		...initGraphqlTableQueryParamsBaseModel(defaultOrder),
	})

	const { data, loading, refetch } = useQuery<
		GraphqlQueryItemsBaseModel<UserGraphqlDto>,
		GraphqlTableQueryParamsBaseModel
	>(QUERY_USERS, {
		variables: queryParams,
		notifyOnNetworkStatusChange: true,
	})

	const columns: ColumnType<UserGraphqlDto>[] = [
		{
			...columnHelper(
				t("labels.fullname"),
				nameof<UserGraphqlDto>((x) => x.fullname),
				"Text"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.email"),
				nameof<UserGraphqlDto>((x) => x.email),
				"Text"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.company"),
				nameof<UserGraphqlDto>((x) => x.companyName),
				"Text"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.chargeTagCount"),
				nameof<UserGraphqlDto>((x) => x.chargeTagCount),
				"Number"
			),
			sorter: true,
		},
		{
			title: t("labels.action"),
			render: (_, record) => (
				<Space size="large">
					<EditOutlined
						style={{ fontSize: "16px" }}
						onClick={() => {
							setIdToEdit(record.id)
							setIsEditModalOpen(true)
						}}
					/>
					<DeleteOutlined style={{ fontSize: "16px" }} onClick={() => onDeleteClick(record)} />
				</Space>
			),
			width: 100,
		},
	]

	const onDeleteClick = async (item: UserGraphqlDto) => {
		await dispatch(deleteUserAsync({ id: item.id })).unwrap()
		refetch()
	}

	const onCreateItemClick = () => {
		setIsEditModalOpen(true)
	}

	return (
		<>
			<MainTable
				onChange={(pagination, filter, sorter) => {
					handleGraphqlFilterTableChange(pagination, defaultOrder, sorter, filter, (newParams) => {
						setQueryParams({
							...queryParams,
							...newParams,
						})
					})
				}}
				loading={loading}
				columns={columns}
				dataSource={data?.query?.items ?? []}
				totalCount={data?.query?.totalCount}
			/>
			<UserEditModal
				open={isEditModalOpen}
				onClose={(withRefetch) => {
					setIsEditModalOpen(false)
					setIdToEdit(undefined)
					if (withRefetch) refetch()
				}}
				id={idToEdit}
			/>
			<FloatButton
				onClick={onCreateItemClick}
				style={{ top: "74px", width: "48px", height: "48px" }}
				type="primary"
				icon={<PlusOutlined />}
			/>
		</>
	)
}

export default Users
