import { BaseFormItemProps, FormItemWrapperProps } from "../../common/interfaces/formInterfaces"
import TextArea, { TextAreaProps } from "antd/lib/input/TextArea"

import { Form } from "antd"

export interface TextAreaFormItemProps extends FormItemWrapperProps<BaseFormItemProps, TextAreaProps> {}

const TextAreaFormItem = ({ formProps, elementProps }: TextAreaFormItemProps) => {
	return (
		<Form.Item {...formProps}>
			<TextArea {...elementProps} />
		</Form.Item>
	)
}

export default TextAreaFormItem
