import { gql } from "@apollo/client"

export const QUERY_RESERVATIONS = gql`
	query GetReservations(
		$skip: Int
		$take: Int
		$order: [ReservationGraphQlDtoSortInput!]
		$chargePointId: Long
		$where: ReservationGraphQlDtoFilterInput
	) {
		query: reservations(skip: $skip, take: $take, order: $order, chargePointId: $chargePointId, where: $where) {
			totalCount
			items {
				id
				connectorId
				expiryDate
				tagId
				status
				chargePointId
			}
		}
	}
`
