//import { ContentTypeEnum } from "../enums/contentTypeEnum"
//import { UploadFile } from "antd/lib/upload/interface"
import axios from "axios"
//import fileDownload from "js-file-download"
//import i18n from "../../app/i18n"
//import { notifyError } from "./notificationHelpers"

export const getBaseUrl = (): string => {
	const baseUrl = process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_URL ?? ""}/api/` : "/api/"
	return baseUrl ?? ""
}

export const httpClient = axios.create({
	baseURL: "/api/",
	withCredentials: true,
})

export const requestHttpClient = axios.create({
	baseURL: "/requests",
	withCredentials: true,
	timeout: 15000,
	headers: {
		"X-API-Key": "111",
	},
})

export const ocppHttpClient = axios.create({
	baseURL: "/ocpp",
	withCredentials: true,
	headers: {
		"X-API-Key": "111",
	},
})

// export const downloadFile = async (downloadUrl: string, file: UploadFile) => {
// 	try {
// 		const resposne = await httpClient.get(downloadUrl + "/" + file.uid, {
// 			responseType: "blob",
// 		})

// 		fileDownload(resposne.data, file.name)
// 	} catch {
// 		//notifyError(i18n.t("notifications.error.fileDownload"))
// 	}
// }

// export const downloadDislaimer = async () => {
// 	try {
// 		const resposne = await axios.get(window.location.origin + "/files/LegalNotice.docx", {
// 			responseType: "blob",
// 		})

// 		fileDownload(resposne.data, "Disclaimer.docx")
// 	} catch {
// 		//notifyError(i18n.t("notifications.error.fileDownload"))
// 	}
// }
