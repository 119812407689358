import { ChargePointGraphQlDto, ChargePointLocationGraphQlDto } from "../../features/chargePoints/graphql/types"
import { GraphqlQueryItemBaseModel, GraphqlQueryItemsBaseModel } from "../../common/models/graphqlModels"
import { MapContainer, Marker, TileLayer } from "react-leaflet"

import { FloatButton } from "antd"
import { QUERY_CHARGEPOINTS_LOCATIONS } from "../../features/chargePoints/graphql/queries"
import { SearchOutlined } from "@ant-design/icons"
import chargerIconSrc from "../../assets/chargerIcon.svg"
import styles from "./Map.module.scss"
import { useQuery } from "@apollo/client"

const Map = () => {
	const { data } = useQuery<GraphqlQueryItemsBaseModel<ChargePointLocationGraphQlDto>>(QUERY_CHARGEPOINTS_LOCATIONS, {
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "network-only",
		variables: {
			where: { and: [{ latitude: { neq: null } }, { longitude: { neq: null } }, { isPublic: { eq: true } }] },
		},
	})

	return (
		<>
			<MapContainer style={{ height: "100%", width: "100%" }} center={[49.8175, 15.473]} zoom={8}>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				{data?.query?.items?.map((x) => {
					return (
						<Marker
							icon={L.icon({
								iconUrl: chargerIconSrc,
								iconSize: [36, 36],
								className: styles["mapIcon"],
							})}
							position={[x.latitude, x.longitude]}
						></Marker>
					)
				})}
			</MapContainer>
			<FloatButton style={{ top: "74px", width: "48px", height: "48px" }} type="primary" icon={<SearchOutlined />} />
		</>
	)
}

export default Map
