import { BaseFormItemProps, FormItemWrapperProps } from "../../common/interfaces/formInterfaces"
import { ConfigProvider, DatePicker, Form } from "antd"
import { dateFormat, dateTimeFormat } from "../../common/constants/constants"
import dayjs, { Dayjs } from "dayjs"

import { DatePickerProps } from "antd/lib"
import styles from "./FormItem.module.scss"
import { useTranslation } from "react-i18next"

const DateFormItem = ({ formProps, elementProps }: FormItemWrapperProps<BaseFormItemProps, DatePickerProps>) => {
	const { t } = useTranslation()

	return (
		<Form.Item
			{...formProps}
			className={styles.datePickerFormItem}
			rules={
				formProps.isRequired
					? [
							{
								required: true,
								message: t("validations.input.common"),
							},
					  ]
					: formProps.rules
			}
		>
			<DatePicker
				className={styles.datePicker}
				{...elementProps}
				format={elementProps.format ?? elementProps.showTime ? dateTimeFormat : dateFormat}
				changeOnBlur={elementProps.changeOnBlur ?? true}
			/>
		</Form.Item>
	)
}

export default DateFormItem
