import { BankOutlined, FilterFilled } from "@ant-design/icons"
import { Button, Card, Col, Flex, FloatButton, Popover, Row, Typography } from "antd"
import React, { useState } from "react"

import { ChargePointStateEnum } from "../../common/enums/chargePointStateEnum"
import { ChargeTagStatusEnum } from "../../common/enums/chargeTagStatusEnum"
import { ChargeTagTypeEnum } from "../../common/enums/chargeTagTypeEnum"
import { DashbaordFilterModel } from "../../features/dashboard/graphql/types"
import { Form } from "react-router-dom"
import GeneralStatisticsCard from "../admin/dashboard/cards/GeneralStatisticsCard"
import SelectFormItem from "../../components/formItems/SelectFormItem"
import TransactionsChartCard from "../admin/dashboard/cards/TransactionsChartCard"
import { mapToChartModel } from "../../common/helpers/modelHelperts"
import { nameof } from "ts-simple-nameof"
import styles from "./Dashboard.module.scss"
import { useAppSelector } from "../../app/hooks"
import { useTranslation } from "react-i18next"

const Dashboard = () => {
	const { t } = useTranslation()
	const { userId } = useAppSelector((state) => state.auth.user)

	const [filter] = useState<DashbaordFilterModel>({
		userIds: [userId],
	})

	return (
		<div className={styles["layout-content"]}>
			<Row gutter={[24, 0]}>
				<Col className={styles["mb-24"]} xs={24} sm={24} md={24} lg={24} xl={24}>
					<Row gutter={[24, 0]}>
						<Col span={24}>
							<GeneralStatisticsCard globalFilter={filter} showFilterIcon={false} />
						</Col>
					</Row>
				</Col>
			</Row>
			<Row gutter={[24, 24]} style={{ height: "calc(100% - 374px)", minHeight: "300px" }}>
				<Col style={{ height: "100%", width: "100%" }} xs={24} sm={24} md={24} lg={24} xl={12}>
					<TransactionsChartCard globalFilter={filter} showFilterIcon={false} />
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={12}>
					<Card style={{ height: "100%" }} bordered={false} className={styles.criclebox}></Card>
				</Col>
			</Row>
		</div>
	)
}

export default Dashboard
