import { Button, Modal, Row, Spin, Typography } from "antd"
import React, { ReactNode } from "react"

import ConditionalRenderer from "../wrappers/ConditionalRenderer"
import { FetchStatusEnum } from "../../common/enums/fetchStatusEnum"
import { ReactComponent as ModalCloseIcon } from "../../assets/modalCloseIcon.svg"
import styles from "./Modals.module.scss"
import { useTranslation } from "react-i18next"

interface Props extends React.PropsWithChildren<{}> {
	onCancel: () => void
	onFormSubmit?: () => void
	isOpen: boolean
	status?: FetchStatusEnum
	title?: string
	width?: string
	className?: string
	hideFooter?: boolean
	customFooter?: ReactNode
	loading?: boolean
}

const BaseEditModal = ({
	onCancel,
	onFormSubmit,
	isOpen,
	status,
	children,
	title,
	width = "50%",
	className,
	hideFooter,
	customFooter,
	loading = false,
}: Props) => {
	const { t } = useTranslation()

	const defaultFooter = (
		<Row align="middle" justify="space-between">
			<Button disabled={loading} onClick={() => onCancel()} type="text" className={styles["cancelBtn"]}>
				{t("btns.cancelChanges")}
			</Button>
			<Button
				disabled={loading}
				onClick={onFormSubmit}
				block={false}
				type="primary"
				loading={status === FetchStatusEnum.loading}
			>
				{t("btns.saveChanges")}
			</Button>
		</Row>
	)

	return (
		<Modal
			className={`${styles["baseModal"]} ${className}`}
			centered
			maskClosable={false}
			width={width}
			onCancel={onCancel}
			open={isOpen}
			footer={
				hideFooter ? null : (
					<ConditionalRenderer show={!hideFooter}>{customFooter ?? defaultFooter}</ConditionalRenderer>
				)
			}
			title={
				!!title && (
					<Row justify="start" align="middle">
						<Typography.Title level={2}>{title}</Typography.Title>
					</Row>
				)
			}
		>
			<Spin spinning={loading} style={{ maxHeight: "100%" }}>
				{children}
			</Spin>
		</Modal>
	)
}

export default BaseEditModal
