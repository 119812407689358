import {
	GraphqlCondition,
	GraphqlItemsModel,
	GraphqlOrderModel,
	GraphqlQueryItemBaseModel,
} from "../../../common/models/graphqlModels"
import { WatchQueryFetchPolicy, useQuery } from "@apollo/client"

import { ChargePointGraphQlDropdownDto } from "./types"
import { QUERY_CHARGEPOINTS_DROPDOWN } from "./queries"

interface OrderFilterModel extends GraphqlOrderModel, GraphqlCondition {}

export const useChargePointsDropdownQuery = (
	fetchPolicy?: WatchQueryFetchPolicy | undefined,
	order: any = [{ name: "ASC" }],
	where: any = { name: { neq: null } }
) =>
	useQuery<GraphqlQueryItemBaseModel<GraphqlItemsModel<ChargePointGraphQlDropdownDto>>, OrderFilterModel>(
		QUERY_CHARGEPOINTS_DROPDOWN,
		{
			fetchPolicy: fetchPolicy,
			variables: {
				order: order,
				where: where,
			},
			notifyOnNetworkStatusChange: true,
		}
	)
