import { Button, Result, Row } from "antd"

import nav from "../../common/navigation/nav.json"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const NotFound = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	return (
		<Row justify="center" align="middle" style={{ height: "100%", width: "100%" }}>
			<Result
				status="404"
				title="404"
				subTitle={t("texts.pageNotFound")}
				extra={
					<Button onClick={() => navigate(nav.dashboard.base, { replace: true })} type="primary">
						{t("btns.mainPage")}
					</Button>
				}
			/>
		</Row>
	)
}

export default NotFound
