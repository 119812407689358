export enum MeterValuePhaseEnum {
	All = -1,
	L1 = 0,
	L2 = 1,
	L3 = 2,
	N = 3,
	L1N = 4,
	L2N = 5,
	L3N = 6,
	L1L2 = 7,
	L2L3 = 8,
	L3L1 = 9,
}
