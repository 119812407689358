import React from "react"

interface Props extends React.PropsWithChildren<{}> {
    show: boolean
}

const ConditionalRenderer = ({ show, children }: Props) => {
    return <>{show && children}</>
}

export default ConditionalRenderer
