import {
	ChargePointChargingProfileGraphQlDto,
	ChargePointGraphQlDto,
} from "../../../../../features/chargePoints/graphql/types"
import {
	ChargingProfileKindEnum,
	ChargingProfilePurposeEnum,
	ChargingProfileRecurrencyKindEnum,
	ChargingProfileStatusEnum,
	ChargingRateUnitEnum,
} from "../../../../../common/enums/chargingProfileEnums"
import {
	GraphqlQueryItemsBaseModel,
	GraphqlTableQueryParamsBaseModel,
} from "../../../../../common/models/graphqlModels"
import React, { useState } from "react"
import {
	handleGraphqlFilterTableChange,
	initGraphqlTableQueryParamsBaseModel,
} from "../../../../../common/helpers/graphQlHelpers"

import MainTable from "../../../../../components/tables/MainTable"
import { QUERY_CHARGEPOINT_CHARGING_PROFILES } from "../../../../../features/chargePoints/graphql/queries"
import { Table } from "antd"
import { columnHelper } from "../../../../../common/helpers/tableHelpers"
import { defaultTablePageSize } from "../../../../../common/constants/constants"
import { getEnumSelectOptions } from "../../../../../common/helpers/enumHelpers"
import { nameof } from "ts-simple-nameof"
import styles from "../../ChargePoint.module.scss"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

interface GraphQlParamsModel extends GraphqlTableQueryParamsBaseModel {
	id: number
}

interface Props {
	chargePoint: ChargePointGraphQlDto
}

const ChargePointChargingProfileTab = ({ chargePoint }: Props) => {
	const defaultOrder = [{ id: "DESC" }]

	const [queryParams, setQueryParams] = useState<GraphQlParamsModel>({
		...initGraphqlTableQueryParamsBaseModel(defaultOrder),
		id: chargePoint.id,
	})

	const { data, loading, refetch } = useQuery<
		GraphqlQueryItemsBaseModel<ChargePointChargingProfileGraphQlDto>,
		GraphqlTableQueryParamsBaseModel
	>(QUERY_CHARGEPOINT_CHARGING_PROFILES, {
		variables: queryParams,
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "network-only",
	})

	const { t } = useTranslation()

	//create colums for graphql model and add to columns array
	const columns = [
		{
			...columnHelper(
				t("labels.connector"),
				nameof<ChargePointChargingProfileGraphQlDto>((x) => x.connectorId),
				"Number"
			),
		},
		{
			...columnHelper(
				t("labels.stackLevel"),
				nameof<ChargePointChargingProfileGraphQlDto>((x) => x.stackLevel),
				"Number"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.chargingProfilePurpose"),
				nameof<ChargePointChargingProfileGraphQlDto>((x) => x.chargingProfilePurposeId),
				"MultiSelect",
				getEnumSelectOptions(ChargingProfilePurposeEnum, t, "chargingProfilePurposeEnum")
			),
			sorter: true,
			render: (value) => {
				return value ? t(`chargingProfilePurposeEnum.${ChargingProfilePurposeEnum[value]}`) : ""
			},
		},
		{
			...columnHelper(
				t("labels.chargingProfileKind"),
				nameof<ChargePointChargingProfileGraphQlDto>((x) => x.chargingProfileKindId),
				"MultiSelect",
				getEnumSelectOptions(ChargingProfileKindEnum, t, "chargingProfileKindEnum")
			),
			sorter: true,
			render: (value) => {
				return value ? t(`chargingProfileKindEnum.${ChargingProfileKindEnum[value]}`) : ""
			},
		},
		{
			...columnHelper(
				t("labels.chargingProfileRecurrencyKind"),
				nameof<ChargePointChargingProfileGraphQlDto>((x) => x.chargingProfileRecurrencyKindId),
				"MultiSelect",
				getEnumSelectOptions(ChargingProfileRecurrencyKindEnum, t, "chargingProfileRecurrencyKindEnum")
			),
			sorter: true,
			render: (value) => {
				return value ? t(`chargingProfileRecurrencyKindEnum.${ChargingProfileRecurrencyKindEnum[value]}`) : ""
			},
		},
		{
			...columnHelper(
				t("labels.status"),
				nameof<ChargePointChargingProfileGraphQlDto>((x) => x.chargingProfileStatusId),
				"MultiSelect",
				getEnumSelectOptions(ChargingProfileStatusEnum, t, "chargingProfileStatusEnum")
			),
			sorter: true,
			render: (value) => {
				return value ? t(`chargingProfileStatusEnum.${ChargingProfileStatusEnum[value]}`) : ""
			},
		},
		{
			...columnHelper(
				t("labels.maxValue"),
				nameof<ChargePointChargingProfileGraphQlDto>((x) => x.limit),
				"Number"
			),
			sorter: true,
			render: (value, record) => {
				return `${value} ${t(`chargingRateUnitEnum.${ChargingRateUnitEnum[record.chargingRateUnitId]}`)}`
			},
		},
	]

	return (
		<div style={{ maxHeight: "100%", height: "100%", display: "flex", flexDirection: "column", gap: 16 }}>
			<div
				className={styles["chargerWrapper"]}
				style={{ flex: "1 1 auto", overflow: "hidden", height: "100%", padding: "24px" }}
			>
				<MainTable
					loading={loading}
					columns={columns}
					onChange={(pagination, filter, sorter) => {
						handleGraphqlFilterTableChange(pagination, defaultOrder, sorter, filter, (newParams) => {
							setQueryParams({
								...queryParams,
								...newParams,
							})
						})
					}}
					dataSource={data?.query?.items}
					totalCount={data?.query?.totalCount}
				/>
			</div>
		</div>
	)
}

export default ChargePointChargingProfileTab
