import {
	AuthorizeRequest,
	BootNotificationRequest,
	DataTransferRequest,
	MeterValue,
	MeterValuesRequest,
	SampledValue,
	SampledValueContext,
	SampledValueFormat,
	SampledValueLocation,
	SampledValueMeasurand,
	SampledValuePhase,
	SampledValueUnit,
	StartTransactionRequest,
	StatusNotificationRequest,
	StatusNotificationRequestErrorCode,
	StatusNotificationRequestStatus,
	StopTransactionRequest,
	StopTransactionRequestReason,
} from "../features/versionReceiver/types"
import { Button, Form, Space } from "antd"
import React, { useEffect, useState } from "react"
import { resetConnector, unlockConnector } from "../features/versionReceiver/versionReceiver"

import InputFormItem from "../components/formItems/InputFormItem"
import { TransactionActionEnum } from "../common/enums/transactionActionEnum"
import { nameof } from "ts-simple-nameof"
import { t } from "i18next"
import { useAppDispatch } from "../app/hooks"
import { useForm } from "antd/es/form/Form"
import useWebSocket from "react-use-websocket"

interface FormModel {
	chargepoint: string
	connectorId: number
	idTag: string
}

enum ResetRequestType {
	Hard = 0,

	Soft = 1,
}

const Home = () => {
	const dispatch = useAppDispatch()
	const [isConnect, setIsConnect] = useState<boolean>(false)

	const form = useForm<FormModel>()[0]

	useEffect(() => {}, [])

	const { sendMessage } = useWebSocket(
		`${process.env.REACT_APP_WS_URL}/ocpp/${form.getFieldValue(nameof<FormModel>((x) => x.chargepoint))}`,
		{
			protocols: ["ocpp1.6", "ocpp2.0"],
			reconnectAttempts: 10,
			reconnectInterval: 3000,
			onOpen: (value) => console.log("conection opened", value),
			onClose: (value) => console.log("conection closed", value),
			onError: (value) => console.log("conection error", value),
			onMessage(event) {
				console.log("Event", event)
			},
		},
		isConnect
	)

	const handleActionBtnClick = (actionType: TransactionActionEnum, jsonPayload: any) => {
		const strParams = JSON.stringify([
			2,
			form.getFieldValue(nameof<FormModel>((x) => x.chargepoint)).toString(),
			TransactionActionEnum[actionType],
			jsonPayload,
		])
		sendMessage(strParams)
	}

	const startTransacrion = {
		connectorId: 123,
		idTag: "aa",
		meterStart: 3,
		reservationId: 321,
		timestamp: "4/4/2002",
	} as StartTransactionRequest

	const stopTransactionPayload = {
		idTag: "aa",
		meterStop: 4,
		timestamp: "2-3-2023",
		reason: StopTransactionRequestReason.Local,
		transactionData: [
			{
				timestamp: "2-2-2023",
				sampledValue: [
					{
						value: "",
						context: SampledValueContext.Interruption_Begin,
						format: SampledValueFormat.Raw,
						measurand: SampledValueMeasurand.Current_Offered,
						phase: SampledValuePhase.L1N,
						location: SampledValueLocation.Cable,
						unit: SampledValueUnit.Celcius,
					} as SampledValue,
				],
			},
		],
		transactionId: 1,
	} as StopTransactionRequest

	const authorizeTransactionPayload = {
		idTag: "aa",
	} as AuthorizeRequest

	const bootNotificationPayload = {
		idTag: "aa",
		chargeBoxSerialNumber: "box SN",
		chargePointModel: "test model",
		chargePointSerialNumber: "chp SN",
		chargePointVendor: "ventod",
		firmwareVersion: "fw version",
		iccid: "iccid",
		imsi: "imsi",
		meterSerialNumber: "meter SN",
		meterType: "meter type",
	} as BootNotificationRequest

	const dataTransferPayload = {
		data: "data",
		messageId: "msg id",
		vendorId: "vendorId",
	} as DataTransferRequest

	const heartbeatPayload = {
		name: "hearbeat name",
	}

	const meterValuesPayload = {
		connectorId: 123,
		meterValue: [
			{
				sampledValue: [
					{
						value: "test value",
						context: SampledValueContext.Interruption_Begin,
						format: SampledValueFormat.Raw,
						measurand: SampledValueMeasurand.Current_Offered,
						phase: SampledValuePhase.L1N,
						location: SampledValueLocation.Cable,
						unit: SampledValueUnit.Celcius,
					} as SampledValue,
				],
				timestamp: "2-2-2023",
			} as MeterValue,
		],
		transactionId: 123,
	} as MeterValuesRequest

	const statusNotificationPayload = {
		connectorId: 123,
		errorCode: StatusNotificationRequestErrorCode.NoError,
		info: "ok info",
		status: StatusNotificationRequestStatus.Charging,
		vendorErrorCode: "no err",
		vendorId: "vendor id",
		timestamp: "2-2-2023",
	} as StatusNotificationRequest

	return <></>

	return (
		<Space direction="vertical" style={{ margin: "16px" }} size={12}>
			<Form form={form}>
				<InputFormItem name={nameof<FormModel>((x) => x.chargepoint)} label="Chargpoint" />
				<InputFormItem name={nameof<FormModel>((x) => x.connectorId)} label="Connector" />
				<InputFormItem name={nameof<FormModel>((x) => x.idTag)} label="idTag" />
			</Form>
			<Space size="middle">
				<Button onClick={() => setIsConnect(!isConnect)} block={false} type="primary">
					{isConnect ? t("disconnect") : t("connect")}
				</Button>
				<Button
					onClick={() =>
						dispatch(
							unlockConnector({
								id: form.getFieldValue(nameof<FormModel>((x) => x.chargepoint)),
								connectorId: form.getFieldValue(nameof<FormModel>((x) => x.connectorId)),
							})
						)
					}
					block={false}
					type="primary"
				>
					{t("unlock")}
				</Button>
				<Button
					onClick={() =>
						dispatch(
							resetConnector({
								id: form.getFieldValue(nameof<FormModel>((x) => x.chargepoint)),
								type: ResetRequestType.Soft,
							})
						)
					}
					block={false}
					type="primary"
				>
					{t("reset")}
				</Button>
				<Button
					onClick={() => handleActionBtnClick(TransactionActionEnum.StartTransaction, startTransacrion)}
					block={false}
					type="primary"
				>
					{t("start Transaction")}
				</Button>
				<Button
					onClick={() => handleActionBtnClick(TransactionActionEnum.StopTransaction, stopTransactionPayload)}
					block={false}
					type="primary"
				>
					{t("stop Transaction")}
				</Button>
				<Button
					onClick={() => handleActionBtnClick(TransactionActionEnum.Authorize, authorizeTransactionPayload)}
					block={false}
					type="primary"
				>
					{t("authorize Transaction")}
				</Button>
				<Button
					onClick={() => handleActionBtnClick(TransactionActionEnum.BootNotification, bootNotificationPayload)}
					block={false}
					type="primary"
				>
					{t("Boot notifications")}
				</Button>
				<Button
					onClick={() => handleActionBtnClick(TransactionActionEnum.DataTransfer, dataTransferPayload)}
					block={false}
					type="primary"
				>
					{t("data trasfer")}
				</Button>
				<Button
					onClick={() => handleActionBtnClick(TransactionActionEnum.Heartbeat, heartbeatPayload)}
					block={false}
					type="primary"
				>
					{t("Heartbeat")}
				</Button>
				<Button
					onClick={() => handleActionBtnClick(TransactionActionEnum.MeterValues, meterValuesPayload)}
					block={false}
					type="primary"
				>
					{t("Meter values")}
				</Button>

				<Button
					onClick={() => handleActionBtnClick(TransactionActionEnum.StatusNotification, statusNotificationPayload)}
					block={false}
					type="primary"
				>
					{t("status notifications")}
				</Button>
			</Space>
		</Space>
	)
}

export default Home
