import { Button, Flex, FloatButton, Form, Popover, Typography } from "antd"
import React, { useState } from "react"

import { DashbaordFilterModel } from "../../features/dashboard/graphql/types"
import { FilterFilled } from "@ant-design/icons"
import SelectFormItem from "../formItems/SelectFormItem"
import { nameof } from "ts-simple-nameof"
import { t } from "i18next"
import { useChargePointsDropdownQuery } from "../../features/chargePoints/graphql/hooks"
import { useCompaniesDropdownQuery } from "../../features/companies/graphql/hooks"
import { useUsersDropdownQuery } from "../../features/users/graphql/hooks"

interface Props {
	onFilterChange: (filter: DashbaordFilterModel) => void
}

export const FilterPopover = ({ onFilterChange }: Props) => {
	const [isFilterVisible, setIsFilterVisible] = useState(false)

	const chargePointDropdownQuery = useChargePointsDropdownQuery()
	const companiesDropdownQuery = useCompaniesDropdownQuery()
	const usersDropdonwQuery = useUsersDropdownQuery()

	return (
		<Popover
			placement="bottomRight"
			trigger={"click"}
			open={isFilterVisible}
			overlayStyle={{ width: "300px" }}
			title={<Typography.Title level={4}>{t("labels.filtering")}</Typography.Title>}
			content={
				<>
					<Form
						onFinish={(f) => {
							onFilterChange({
								chargePointIds: f.chargePointIds?.length === 0 ? undefined : f.chargePointIds,
								companyIds: f.companyIds?.length === 0 ? undefined : f.companyIds,
								userIds: f.userIds?.length === 0 ? undefined : f.userIds,
							})
							setIsFilterVisible(false)
						}}
						layout="vertical"
					>
						<SelectFormItem
							label={t("titles.companies")}
							items={companiesDropdownQuery?.data?.query?.items ?? []}
							allowClear
							mode="multiple"
							withSearch
							isLoading={companiesDropdownQuery?.loading}
							name={nameof<DashbaordFilterModel>((x) => x.companyIds)}
						/>
						<SelectFormItem
							label={t("titles.users")}
							allowClear
							withSearch
							mode="multiple"
							items={usersDropdonwQuery?.data?.query?.items ?? []}
							isLoading={usersDropdonwQuery?.loading}
							name={nameof<DashbaordFilterModel>((x) => x.userIds)}
						/>
						<SelectFormItem
							label={t("titles.chargePoints")}
							withSearch
							mode="multiple"
							items={chargePointDropdownQuery?.data?.query?.items ?? []}
							isLoading={chargePointDropdownQuery?.loading}
							allowClear
							name={nameof<DashbaordFilterModel>((x) => x.chargePointIds)}
						/>
						<Flex style={{ width: "100%" }} justify={"space-between"}>
							<Button
								onClick={() => {
									onFilterChange(undefined)
									setIsFilterVisible(false)
								}}
							>
								{t("btns.cancel")}
							</Button>
							<Button type="primary" htmlType="submit">
								{t("btns.filter")}
							</Button>
						</Flex>
					</Form>
				</>
			}
		>
			<FloatButton
				style={{ top: "74px", width: "48px", height: "48px" }}
				type="primary"
				icon={<FilterFilled />}
				onClick={() => setIsFilterVisible(!isFilterVisible)}
			/>
		</Popover>
	)
}

export default FilterPopover
