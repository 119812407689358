import { gql } from "@apollo/client"

export const QUERY_COMPANIES = gql`
	query GetCompanies(
		$skip: Int
		$take: Int
		$order: [CompanyGraphqlDtoSortInput!]
		$where: CompanyGraphqlDtoFilterInput
	) {
		query: companies(skip: $skip, take: $take, order: $order, where: $where) {
			totalCount
			items {
				id
				name
				chargePointCount
				userCount
			}
		}
	}
`

export const QUERY_COMPANY_DETAIL = gql`
	query GetCompany($id: Long!) {
		query: company(id: $id) {
			id
			name
		}
	}
`

export const QUERY_COMPANIES_DROPDOWN = gql`
	query GetCompaniesDropdown($order: [CompanyGraphqlDtoSortInput!], $where: CompanyGraphqlDtoFilterInput) {
		query: companies(order: $order, where: $where) {
			totalCount
			items {
				name
				id
			}
		}
	}
`
