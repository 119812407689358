import * as signalR from "@microsoft/signalr"

import { AimOutlined, EditOutlined } from "@ant-design/icons"
import { FloatButton, Skeleton, Spin, Tabs, notification } from "antd"
import React, { useEffect, useState } from "react"
import { notifyError, notifySuccess } from "../../../../common/helpers/notificationHelpers"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"

import ChargePointChargeTagsTab from "./tabs/ChargePointChargeTagsTab"
import ChargePointChargingProfileTab from "./tabs/ChargePointChargingProfileTab"
import ChargePointEditModal from "../modals/ChargePointEditModal"
import ChargePointGeneralTab from "./tabs/ChargePointGeneralTab"
import { ChargePointGraphQlDto } from "../../../../features/chargePoints/graphql/types"
import ChargePointReservationsTab from "./tabs/ChargePointReservationsTab"
import ChargePointStatisticsTab from "./tabs/ChargePointStatisticsTab"
import ChargePointTransactionsTab from "./tabs/ChargePointTransactionsTab"
import { FeatureEnum } from "../../../../common/enums/featureEnum"
import { FetchStatusEnum } from "../../../../common/enums/fetchStatusEnum"
import { QUERY_CHARGEPOINT } from "../../../../features/chargePoints/graphql/queries"
import { getConfigurationAsync } from "../../../../features/versionReceiver/versionReceiver"
import styles from "../ChargePoint.module.scss"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

enum ChargePointDetailTabEnum {
	General = "0",
	Transactions = "1",
	Reservations = "2",
	Identifiers = "3",
	ChargingProfiles = "4",
	Statistics = "5",
	Tags = "6",
	ServicePortal = "7",
}

const ChargePointAdminDetail = () => {
	const { id } = useParams()
	const [isEditOpen, setIsEditOpen] = useState<boolean>(false)
	const dispatch = useAppDispatch()
	const { status } = useAppSelector((state) => state.versionReceiver)

	const { t } = useTranslation()

	const { data, loading, refetch } = useQuery<{ query: ChargePointGraphQlDto | undefined }, { id: number }>(
		QUERY_CHARGEPOINT,
		{
			variables: {
				id: +id,
			},
			notifyOnNetworkStatusChange: true,
		}
	)

	const connection = new signalR.HubConnectionBuilder().withUrl("/hubs/mobile").build()

	connection.on("receiveMeterValues", (data) => {
		console.log(data)
	})

	useEffect(() => {
		connection
			.start()
			.then(() => console.log("AJAJAJ"))
			.catch((e) => {
				console.log("ERROR", e)
			})
	}, [])

	const getTabs = (features: FeatureEnum[]): any[] => {
		var items = [
			{
				label: t("titles.general"),
				key: ChargePointDetailTabEnum.General,
				style: { height: "100%" },
				children: data?.query ? <ChargePointGeneralTab chargePoint={data.query} /> : <Skeleton active={loading} />,
			},
			{
				label: t("titles.transactions"),
				key: ChargePointDetailTabEnum.Transactions,
				children: data?.query ? <ChargePointTransactionsTab chargePoint={data.query} /> : <Skeleton active={loading} />,
			},
		]

		if (features.includes(FeatureEnum.Reservation)) {
			items.push({
				label: t("titles.reservations"),
				key: ChargePointDetailTabEnum.Reservations,
				children: data?.query ? <ChargePointReservationsTab chargePoint={data.query} /> : <Skeleton active={loading} />,
			})
		}

		items.push({
			label: t("titles.chargeTags"),
			key: ChargePointDetailTabEnum.Tags,
			children: data?.query ? <ChargePointChargeTagsTab chargePoint={data.query} /> : <Skeleton active={loading} />,
		})

		if (features.includes(FeatureEnum.SmartCharging)) {
			items.push({
				label: t("titles.chargingProfiles"),
				key: ChargePointDetailTabEnum.ChargingProfiles,
				children: data?.query ? (
					<ChargePointChargingProfileTab chargePoint={data.query} />
				) : (
					<Skeleton active={loading} />
				),
			})
		}

		items.push({
			label: t("titles.statistics"),
			key: ChargePointDetailTabEnum.Statistics,
			children: data?.query ? <ChargePointStatisticsTab chargePoint={data.query} /> : <Skeleton active={loading} />,
		})

		return items
	}

	const onPing = async () => {
		if (!data?.query?.chargePointId) return
		try {
			const response = await dispatch(getConfigurationAsync(data.query.chargePointId)).unwrap()
			console.log(response)
			notifySuccess(t("titles.chargePoints"), t("notifications.success.ping"))
		} catch (e) {
			notifyError(t("titles.chargePoints"), t("notifications.error.ping"))
		}
	}

	return (
		<>
			<Tabs
				className={styles["tabs"]}
				style={{ height: "100%" }}
				tabPosition={"left"}
				items={getTabs(data?.query?.features ?? [])}
			/>
			<ChargePointEditModal
				id={+id}
				onClose={(withReload) => {
					setIsEditOpen(false)
					if (withReload) refetch()
				}}
				open={isEditOpen}
			/>
			<FloatButton
				className={styles["editFloatBtn"]}
				onClick={() => setIsEditOpen(true)}
				type="primary"
				icon={<EditOutlined />}
			/>
			<Spin fullscreen spinning={status === FetchStatusEnum.loading} />
			<FloatButton
				className={styles["pinkFloatBtn"]}
				onClick={() => onPing()}
				type="default"
				tooltip={"ping"}
				icon={<AimOutlined />}
			/>
		</>
	)
}

export default ChargePointAdminDetail
