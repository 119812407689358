import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"
import { FloatButton, Form, Space, Table } from "antd"
import { GraphqlQueryItemsBaseModel, GraphqlTableQueryParamsBaseModel } from "../../../common/models/graphqlModels"
import React, { useEffect, useState } from "react"
import {
	createChargeTagAsync,
	deleteChargeTagAsync,
	getChargeTagDetailAsync,
	updateChargeTagAsync,
} from "../../../features/chargeTags/chargeTagSlice"
import { dateTimeFormat, defaultTablePageSize } from "../../../common/constants/constants"
import { datesToDayjs, datesToMoment, objectToFormData } from "../../../common/helpers/formDataHelper"
import {
	handleGraphqlFilterTableChange,
	initGraphqlTableQueryParamsBaseModel,
} from "../../../common/helpers/graphQlHelpers"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { useForm, useWatch } from "antd/es/form/Form"

import BaseEditModal from "../../../components/modals/BaseEditModal"
import { ChargeTagGraphQlDto } from "../../../features/chargeTags/graphql/types"
import { ChargeTagModel } from "../../../features/chargeTags/types"
import { ChargeTagStatusEnum } from "../../../common/enums/chargeTagStatusEnum"
import { ChargeTagTypeEnum } from "../../../common/enums/chargeTagTypeEnum"
import type { ColumnType } from "antd/es/table"
import DateFormItem from "../../../components/formItems/DateFormItem"
import { FetchStatusEnum } from "../../../common/enums/fetchStatusEnum"
import FormItemWrapper from "../../../components/formItems/FormItemWrapper"
import InputFormItem from "../../../components/formItems/InputFormItem"
import MainTable from "../../../components/tables/MainTable"
import { QUERY_CHARGETAGS } from "../../../features/chargeTags/graphql/queries"
import SelectFormItem from "../../../components/formItems/SelectFormItem"
import SwitchFormItem from "../../../components/formItems/SwitchFormItem"
import { columnHelper } from "../../../common/helpers/tableHelpers"
import dayjs from "dayjs"
import { getEnumSelectOptions } from "../../../common/helpers/enumHelpers"
import { getUsersDropdownSourceAsync } from "../../../features/users/usersSlice"
import { nameof } from "ts-simple-nameof"
import { t } from "i18next"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

const ChargeTags = () => {
	const [item, setItem] = useState<ChargeTagModel>(undefined)
	const [form] = useForm<ChargeTagModel>()
	const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>()

	const { usersDropdown } = useAppSelector((x) => x.users)
	const { status } = useAppSelector((x) => x.chargeTags)

	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const defaultOrder = [{ name: "ASC" }]

	const [queryParams, setQueryParams] = useState<GraphqlTableQueryParamsBaseModel>({
		...initGraphqlTableQueryParamsBaseModel(defaultOrder),
	})

	useEffect(() => {
		dispatch(getUsersDropdownSourceAsync())
	}, [])

	const { data, loading, refetch } = useQuery<
		GraphqlQueryItemsBaseModel<ChargeTagGraphQlDto>,
		GraphqlTableQueryParamsBaseModel
	>(QUERY_CHARGETAGS, {
		variables: queryParams,
		notifyOnNetworkStatusChange: true,
	})

	const columns: ColumnType<ChargeTagGraphQlDto>[] = [
		{
			...columnHelper(
				t("labels.chargeTagId"),
				nameof<ChargeTagGraphQlDto>((x) => x.tagId),
				"Text"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.name"),
				nameof<ChargeTagGraphQlDto>((x) => x.name),
				"Text"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.user"),
				nameof<ChargeTagGraphQlDto>((x) => x.user),
				"Text"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.expiryDate"),
				nameof<ChargeTagGraphQlDto>((x) => x.expiryDate),
				"Date"
			),
			render: (_, record) => (record.expiryDate ? dayjs(record.expiryDate).format(dateTimeFormat) : ""),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.type"),
				nameof<ChargeTagGraphQlDto>((x) => x.chargeTagTypeId),
				"MultiSelect",
				getEnumSelectOptions(ChargeTagTypeEnum, t, "chargeTagTypeEnum")
			),
			render: (value, record) => {
				return !value ? "" : t(`chargeTagTypeEnum.${ChargeTagTypeEnum[+value]}`)
			},
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.status"),
				nameof<ChargeTagGraphQlDto>((x) => x.chargeTagStatusId),
				"MultiSelect",
				getEnumSelectOptions(ChargeTagStatusEnum, t, "chargeTagStatusEnum")
			),
			render: (value, record) => {
				return !value ? "" : t(`chargeTagStatusEnum.${ChargeTagStatusEnum[+value]}`)
			},
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.createTimestamp"),
				nameof<ChargeTagGraphQlDto>((x) => x.createTimestamp),
				"Date"
			),
			render: (value, record) => (value ? dayjs(value).format(dateTimeFormat) : ""),
			sorter: true,
		},
		{
			title: t("labels.action"),
			render: (_, record) => (
				<Space size="large">
					<EditOutlined style={{ fontSize: "16px" }} onClick={() => onEditClick(record)} />
					<DeleteOutlined style={{ fontSize: "16px" }} onClick={() => onDeleteClick(record)} />
				</Space>
			),
			width: 100,
		},
	]

	const onEditClick = async (item: ChargeTagGraphQlDto) => {
		var data = await dispatch(getChargeTagDetailAsync(item.id)).unwrap()

		console.log(data)

		setItem(data)
		form.setFieldsValue(datesToDayjs(data))

		setIsEditModalOpen(true)
	}

	const onDeleteClick = async (item: ChargeTagGraphQlDto) => {
		await dispatch(deleteChargeTagAsync({ id: item.id })).unwrap()
	}

	const onEditModalClose = () => {
		setIsEditModalOpen(false)
		setItem(undefined)
		form.resetFields()
	}

	const onEditFormSubmit = async (model: ChargeTagModel) => {
		const formData = objectToFormData(model)

		if (item) await dispatch(updateChargeTagAsync({ id: item.id, data: formData })).unwrap()
		else await dispatch(createChargeTagAsync(formData)).unwrap()

		onEditModalClose()
		refetch()
	}

	const onCreateItemClick = () => {
		form.resetFields()
		setIsEditModalOpen(true)
	}

	const isBlocked = useWatch([nameof<ChargeTagModel>((x) => x.blocked)], form)

	return (
		<>
			<MainTable
				onChange={(pagination, filter, sorter) => {
					handleGraphqlFilterTableChange(pagination, defaultOrder, sorter, filter, (newParams) => {
						setQueryParams({
							...queryParams,
							...newParams,
						})
					})
				}}
				totalCount={data?.query?.totalCount}
				loading={loading}
				columns={columns}
				dataSource={data?.query?.items ?? []}
			/>
			<BaseEditModal
				title={t("titles.editChargeTag")}
				isOpen={isEditModalOpen}
				loading={loading || status === FetchStatusEnum.loading}
				onCancel={onEditModalClose}
				onFormSubmit={form.submit}
			>
				<Form layout="vertical" form={form} onFinish={onEditFormSubmit}>
					<InputFormItem name={nameof<ChargeTagModel>((x) => x.id)} hidden />
					<FormItemWrapper
						firstItem={
							<InputFormItem
								name={nameof<ChargeTagModel>((x) => x.name)}
								isRequired
								label={t("labels.name")}
								maxLength={100}
							/>
						}
						secondItem={
							<InputFormItem
								name={nameof<ChargeTagModel>((x) => x.tagId)}
								isRequired
								label={t("labels.chargeTagId")}
								maxLength={500}
							/>
						}
					/>
					<FormItemWrapper
						firstItem={
							<DateFormItem
								formProps={{
									name: nameof<ChargeTagModel>((x) => x.expiryDate),
									label: t("labels.expiryDate"),
								}}
								elementProps={{ showTime: true }}
							/>
						}
						secondItem={
							<SelectFormItem
								items={getEnumSelectOptions(ChargeTagTypeEnum, t, "chargeTagTypeEnum").map((x) => ({
									id: x.value,
									name: x.label.toString(),
								}))}
								name={nameof<ChargeTagModel>((x) => x.chargeTagTypeId)}
								label={t("labels.type")}
							/>
						}
					/>
					<FormItemWrapper
						firstItem={
							<SelectFormItem
								items={getEnumSelectOptions(ChargeTagStatusEnum, t, "chargeTagStatusEnum").map((x) => ({
									id: x.value,
									name: x.label.toString(),
								}))}
								name={nameof<ChargeTagModel>((x) => x.chargeTagStatusId)}
								label={t("labels.status")}
							/>
						}
						secondItem={
							<SelectFormItem
								items={usersDropdown.data}
								isLoading={usersDropdown.status === FetchStatusEnum.loading}
								name={nameof<ChargeTagModel>((x) => x.userId)}
								allowClear
								label={t("labels.user")}
							/>
						}
					/>
				</Form>
			</BaseEditModal>
			<FloatButton
				onClick={onCreateItemClick}
				style={{ top: "74px", width: "48px", height: "48px" }}
				type="primary"
				icon={<PlusOutlined />}
			/>
		</>
	)
}

export default ChargeTags
