import {
	ApiOutlined,
	GlobalOutlined,
	InfoCircleOutlined,
	MoreOutlined,
	PlayCircleOutlined,
	ReloadOutlined,
	ToolOutlined,
	TrademarkOutlined,
	UnlockOutlined,
} from "@ant-design/icons"
import { Avatar, Badge, Button, Col, Descriptions, Form, List, Popover, Row, Space, Tooltip, Typography } from "antd"
import {
	GraphqlQueryItemsBaseModel,
	GraphqlTableQueryParamsBaseModel,
} from "../../../../../common/models/graphqlModels"
import { MapContainer, Marker, TileLayer } from "react-leaflet"
import React, { ReactNode, useEffect, useState } from "react"
import {
	getConfigurationAsync,
	reserveNowAsync,
	resetConnector,
	setSmartChargingAsync,
	startTransactionAsync,
	unlockConnector,
} from "../../../../../features/versionReceiver/versionReceiver"
import { getEnumSelectOptions, getEnumValues } from "../../../../../common/helpers/enumHelpers"

import BaseEditModal from "../../../../../components/modals/BaseEditModal"
import { ChargePointGraphQlDto } from "../../../../../features/chargePoints/graphql/types"
import { ChargePointStateEnum } from "../../../../../common/enums/chargePointStateEnum"
import { ChargeTagGraphQlDto } from "../../../../../features/chargeTags/graphql/types"
import { ChargeTagStatusEnum } from "../../../../../common/enums/chargeTagStatusEnum"
import { ReactComponent as ChargerIcon } from "../../../../../assets/chargerDetailIcon.svg"
import { ChargingRateUnitEnum } from "../../../../../common/enums/chargingProfileEnums"
import { ReactComponent as ConnectorIcon } from "../../../../../assets/connectorIcon.svg"
import { ConnectorStatusEnum } from "../../../../../features/versionReceiver/types"
import { FeatureEnum } from "../../../../../common/enums/featureEnum"
import FormItemWrapper from "../../../../../components/formItems/FormItemWrapper"
import L from "leaflet"
import NumberFormItem from "../../../../../components/formItems/NumberFormItem"
import { QUERY_CHARGETAGS } from "../../../../../features/chargeTags/graphql/queries"
import { ResetRequestType } from "../../../../../common/enums/resetRequestTypeEnum"
import SelectFormItem from "../../../../../components/formItems/SelectFormItem"
import { SmartChargingModel } from "../../../../../features/chargePoints/types"
import chargerIconSrc from "../../../../../assets/chargerIcon.svg"
import { dateTimeFormat } from "../../../../../common/constants/constants"
import dayjs from "dayjs"
import { getBadge } from "../../../../../common/helpers/chargePointAvailabilityHelper"
import { initGraphqlTableQueryParamsBaseModel } from "../../../../../common/helpers/graphQlHelpers"
import { nameof } from "ts-simple-nameof"
import styles from "../../ChargePoint.module.scss"
import { useAppDispatch } from "../../../../../app/hooks"
import { useForm } from "antd/es/form/Form"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

enum TagModalActionType {
	ConnectorReservation = 1,
	StartTransaction = 2,
	GlobalReservation = 3,
}

interface TagActionModel {
	tagId: string
	connectorId: number
}

interface Props {
	chargePoint: ChargePointGraphQlDto
}

export const ChargePointGeneralTab = ({ chargePoint }: Props) => {
	const hasLocationSet = chargePoint?.latitude && chargePoint?.longitude
	const [form] = useForm<TagActionModel>()
	const [tagModalActionType, setTagModalActionType] = useState<TagModalActionType | undefined>(undefined)

	const smartChargingForm = useForm<SmartChargingModel>()
	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const [isSmartChargingModalOpen, setIsSmartChargingModalOpen] = useState<boolean>(false)

	useEffect(() => {
		if (!tagModalActionType) form.resetFields()
	}, [tagModalActionType])

	const [queryParamsTags] = useState<GraphqlTableQueryParamsBaseModel>({
		...initGraphqlTableQueryParamsBaseModel(),
		where: {
			chargeTagStatusId: { neq: ChargeTagStatusEnum.Blocked },
		},
	})

	const tagsQuery = useQuery<GraphqlQueryItemsBaseModel<ChargeTagGraphQlDto>, GraphqlTableQueryParamsBaseModel>(
		QUERY_CHARGETAGS,
		{
			variables: queryParamsTags,
			notifyOnNetworkStatusChange: true,
		}
	)

	const onResetClick = async (type: ResetRequestType) => {
		await dispatch(
			resetConnector({
				id: chargePoint.chargePointId,
				type: type,
			})
		).unwrap()
	}

	const onReserveClick = async (connector: number, tagId: string) => {
		await dispatch(
			reserveNowAsync({
				id: chargePoint.chargePointId,
				idTag: tagId,
				connectorId: connector,
			})
		).unwrap()
	}

	const onStartTransactionClick = async (connector: number, tagId: string) => {
		await dispatch(
			startTransactionAsync({
				id: chargePoint.chargePointId,
				idTag: tagId,
				connectorId: connector,
			})
		).unwrap()
	}

	const onConnectorActionClick = (action: TagModalActionType, connector: number) => {
		form.setFieldValue(
			nameof<TagActionModel>((x) => x.connectorId),
			connector
		)

		setTagModalActionType(action)
	}

	const state = (): ReactNode => {
		const x = getEnumValues(ChargePointStateEnum).find((x) => x === chargePoint.chargePointStateId)
		return !x ? "" : t(`chargePointState.${ChargePointStateEnum[+x]}`)
	}

	const onGetConfigurationClick = async () => {
		await dispatch(getConfigurationAsync(chargePoint.chargePointId)).unwrap()
	}

	const onSetSmartChargingClick = async (model: SmartChargingModel) => {
		await dispatch(setSmartChargingAsync({ id: chargePoint.chargePointId, model: model })).unwrap()
		setIsSmartChargingModalOpen(false)
		smartChargingForm[0].resetFields()
	}

	const connectorsLayout = {
		xxl: 4,
		xl: 4,
		md: 4,
		sm: 6,
		xs: 6,
	}

	const chargerLayout = {
		xxl: 16,
		xl: 16,
		md: 16,
		sm: 12,
		xs: 12,
	}

	const moreBtnActionsItems = [
		{
			label: t("btns.loadConfiguration"),
			onClick: (_) => onGetConfigurationClick(),
		},
		{
			label: t("btns.smartCharging"),
			onClick: (_) => {
				setIsSmartChargingModalOpen(true)
			},
		},
	]

	return (
		<div
			className={styles.fullSize}
			style={{
				display: "flex",
				height: "100%",
				flexDirection: "column",
				gap: 16,
			}}
		>
			<div style={{ flex: "1" }}>
				<Row className={styles.fullHeight} gutter={[16, 16]}>
					<Col xxl={12} xl={12} md={24} sm={24} xs={24}>
						<div className={styles["chargerWrapper"]}>
							<Row
								gutter={[16, 16]}
								//className={styles.fullHeight}
								align="top"
								style={{ height: "calc(100% - 32px)", margin: "0px 8px" }}
								justify="center"
								wrap={false}
							>
								<Col className={styles.fullHeight} {...connectorsLayout}>
									<Row className={styles.fullHeight} gutter={[16, 16]}>
										{chargePoint.connectors?.map((x, i) => {
											if (i % 2 !== 0) return null

											return (
												<Col span={24}>
													<ChargePointConnector
														onConnectorActionClick={onConnectorActionClick}
														status={x.lastStatus ? ConnectorStatusEnum[x.lastStatus] : ConnectorStatusEnum.Undefined}
														number={x.connectorId}
														chargePointId={chargePoint.chargePointId}
														features={chargePoint.features ?? []}
													/>
												</Col>
											)
										})}
									</Row>
								</Col>
								<Col className={styles.fullHeight} {...chargerLayout}>
									<div className={styles["chargePointWrapper"]}>
										<div className={styles["statusIconWrapper"]}>{getBadge(chargePoint.availabilityState)}</div>
										<div className={styles["chargePointIconWrapper"]}>
											<ChargerIcon />
										</div>
										{/*<div className={styles["chargePointQrWrapper"]}>
											<QrcodeOutlined style={{ fontSize: "32px" }} />
										</div>*/}
									</div>
								</Col>
								<Col className={styles.fullHeight} {...connectorsLayout}>
									<Row className={styles.fullHeight} gutter={[16, 16]}>
										{chargePoint.connectors?.map((x, i) => {
											if (i % 2 === 0) return null

											return (
												<Col span={24}>
													<ChargePointConnector
														status={x.lastStatus ? ConnectorStatusEnum[x.lastStatus] : ConnectorStatusEnum.Undefined}
														number={x.connectorId}
														onConnectorActionClick={onConnectorActionClick}
														chargePointId={chargePoint.chargePointId}
														features={chargePoint.features ?? []}
													/>
												</Col>
											)
										})}
									</Row>
								</Col>
							</Row>
							<Row justify="center" align="middle" style={{ height: "32px" }}>
								<div>
									<Space align="center">
										<Popover
											placement="bottom"
											title={t("texts.selectType")}
											content={
												<Space direction="vertical">
													{[ResetRequestType.Soft, ResetRequestType.Hard].map((x) => (
														<span
															style={{ cursor: "pointer" }}
															onClick={() => onResetClick(x)}
														>{`${ResetRequestType[x]}`}</span>
													))}
												</Space>
											}
										>
											<Button icon={<ReloadOutlined />}>{t("btns.reset")}</Button>
										</Popover>
										{chargePoint.features?.includes(FeatureEnum.Reservation) && (
											<Button
												onClick={() => onConnectorActionClick(TagModalActionType.GlobalReservation, 0)}
												icon={<TrademarkOutlined />}
											>
												{t("btns.reserve")}
											</Button>
										)}
										<Button icon={<ApiOutlined />}>{t("btns.availability")}</Button>
										<Popover
											content={
												<Space direction="vertical">
													{moreBtnActionsItems.map((x, i) => (
														<span
															key={i}
															style={{ cursor: "pointer" }}
															onClick={(e) => x.onClick(e)}
														>{`${x.label}`}</span>
													))}
												</Space>
											}
										>
											<Button icon={<MoreOutlined style={{ fontSize: "14px" }} width={24} height={24} />} />
										</Popover>
									</Space>
								</div>
							</Row>
						</div>
					</Col>
					<Col xxl={12} xl={12} md={24} sm={24} xs={24}>
						<div style={{ height: "100%", padding: "24px" }} className={styles["chargerWrapper"]}>
							<Descriptions
								size="small"
								title={
									<Row align="middle" gutter={[16, 16]} justify="space-between">
										<Col>
											<span className="ant-descriptions-title">{t("labels.chargePointInfo")}</span>
										</Col>
										<Col>
											<Row gutter={[16, 16]}>
												<Col>
													{chargePoint.comment && (
														<Tooltip title={chargePoint.comment}>
															<InfoCircleOutlined
																width={20}
																height={20}
																style={{ cursor: "pointer", fontSize: "20px" }}
															/>
														</Tooltip>
													)}
												</Col>
												<Col>
													{chargePoint.externalLink && (
														<Tooltip title={t("labels.externalLinkChargepoint")}>
															<Typography.Link href={chargePoint.externalLink} target="_blank">
																<GlobalOutlined width={20} height={20} style={{ fontSize: "20px" }} />
															</Typography.Link>
														</Tooltip>
													)}
												</Col>
												<Col>
													{chargePoint.servicePortalLink && (
														<Tooltip title={t("labels.externalLinkServicePortal")}>
															<Typography.Link href={chargePoint.servicePortalLink} target="_blank">
																<ToolOutlined width={20} height={20} style={{ fontSize: "20px" }} />
															</Typography.Link>
														</Tooltip>
													)}
												</Col>
											</Row>
										</Col>
									</Row>
								}
								className={styles["chargerDescription"]}
								layout="vertical"
								items={[
									{
										key: nameof<ChargePointGraphQlDto>((x) => x.name),
										label: t("labels.name"),
										children: chargePoint.name,
									},
									{
										key: nameof<ChargePointGraphQlDto>((x) => x.serialNumber),
										label: t("labels.serialNumber"),
										children: chargePoint.serialNumber,
									},
									{
										key: nameof<ChargePointGraphQlDto>((x) => x.connectors),
										label: t("labels.connectors"),
										children: chargePoint.connectorCount,
									},
									{
										key: nameof<ChargePointGraphQlDto>((x) => x.vendor),
										label: t("labels.vendor"),
										children: chargePoint.vendor,
									},
									{
										key: nameof<ChargePointGraphQlDto>((x) => x.firmware),
										label: t("labels.firmware"),
										children: chargePoint.firmware,
									},
									{
										key: nameof<ChargePointGraphQlDto>((x) => x.lastCommunicationTime),
										label: t("labels.lastCommunication"),
										children: chargePoint.lastCommunicationTime
											? dayjs(chargePoint.lastCommunicationTime).format(dateTimeFormat)
											: "",
									},
									{
										key: nameof<ChargePointGraphQlDto>((x) => x.isPublic),
										label: t("labels.public"),
										children: chargePoint.isPublic ? t("texts.yes") : t("texts.no"),
									},
									{
										key: nameof<ChargePointGraphQlDto>((x) => x.chargePointStateId),
										label: t("labels.status"),
										children: state(),
									},
									{
										key: nameof<ChargePointGraphQlDto>((x) => x.chargePointStateId),
										label: t("labels.createTimestamp"),
										children: chargePoint.createTimestamp
											? dayjs(chargePoint.createTimestamp).format(dateTimeFormat)
											: "",
									},
									{
										key: nameof<ChargePointGraphQlDto>((x) => x.features),
										label: t("labels.features"),
										span: 3,
										children: chargePoint.features?.map((x) => t(`featureEnum.${FeatureEnum[x]}`)).join(", "),
									},
								]}
							/>
						</div>
					</Col>
				</Row>
			</div>
			<div style={{ flex: "1", height: "100%" }} className={styles["chargerWrapper"]}>
				<Row gutter={[16, 16]} className={styles.fullHeight}>
					<Col style={{ height: "100%" }} flex="auto">
						<div
							className={styles.fullHeight}
							style={{
								overflow: "auto",
							}}
						>
							<List
								itemLayout="horizontal"
								dataSource={[]}
								renderItem={(item, index) => (
									<List.Item>
										<List.Item.Meta
											avatar={<Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />}
											title={<a href="https://ant.design">{item.title}</a>}
											description="Ant Design, a design language for background applications, is refined by Ant UED Team"
										/>
									</List.Item>
								)}
							/>
						</div>
					</Col>
					<Col style={{ width: "400px", height: "100%", margin: "0px 24px" }}>
						<MapContainer
							className={styles.fullSize}
							center={hasLocationSet ? [chargePoint.latitude, chargePoint.longitude] : [49.8175, 15.473]}
							zoom={13}
						>
							<TileLayer
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
							{hasLocationSet && (
								<Marker
									icon={L.icon({
										iconUrl: chargerIconSrc,
										iconSize: [36, 36],
										className: styles["mapIcon"],
									})}
									position={[chargePoint.latitude, chargePoint.longitude]}
								></Marker>
							)}
						</MapContainer>
					</Col>
				</Row>
			</div>
			<BaseEditModal hideFooter onCancel={() => setTagModalActionType(undefined)} isOpen={!!tagModalActionType}>
				<Form
					form={form}
					onFinish={async (model: TagActionModel) => {
						if (
							tagModalActionType === TagModalActionType.ConnectorReservation ||
							tagModalActionType === TagModalActionType.GlobalReservation
						)
							await onReserveClick(model.connectorId, model.tagId)
						else if (tagModalActionType === TagModalActionType.StartTransaction)
							await onStartTransactionClick(model.connectorId, model.tagId)

						setTagModalActionType(undefined)
					}}
					layout="vertical"
				>
					<SelectFormItem
						isRequired
						label="TagId"
						items={tagsQuery.data?.query?.items?.map((x) => ({ id: x.tagId, name: x.name }))}
						name={nameof<TagActionModel>((x) => x.tagId)}
					/>
					<Button type="primary" htmlType="submit">
						{t("btns.confirm")}
					</Button>
				</Form>
			</BaseEditModal>
			<BaseEditModal
				title="Smart Charging"
				onFormSubmit={() => smartChargingForm[0].submit()}
				onCancel={() => {
					setIsSmartChargingModalOpen(false)
					smartChargingForm[0].resetFields()
				}}
				isOpen={isSmartChargingModalOpen}
			>
				<Form onFinish={onSetSmartChargingClick} form={smartChargingForm[0]} layout="vertical">
					<FormItemWrapper
						firstItem={
							<SelectFormItem
								isRequired
								label={t("labels.unit")}
								name={nameof<SmartChargingModel>((x) => x.unit)}
								items={getEnumSelectOptions(ChargingRateUnitEnum, t, "chargingRateUnitEnum").map((x) => ({
									id: x.value,
									name: x.label.toString(),
								}))}
							/>
						}
						secondItem={
							<NumberFormItem
								isRequired
								min={0}
								label={t("labels.maxValue")}
								name={nameof<SmartChargingModel>((x) => x.maxValue)}
								showControls={false}
							/>
						}
					></FormItemWrapper>
				</Form>
			</BaseEditModal>
		</div>
	)
}

export default ChargePointGeneralTab

interface ChargePointConnectorProps {
	number: number
	status: ConnectorStatusEnum
	chargePointId: string
	onConnectorActionClick: (action: TagModalActionType, connector: number) => void
	features: FeatureEnum[]
}

const ChargePointConnector = ({
	number,
	status,
	chargePointId,
	onConnectorActionClick,
	features,
}: ChargePointConnectorProps) => {
	const dispatch = useAppDispatch()
	const { t } = useTranslation()

	const onUnlockClick = async () => {
		await dispatch(
			unlockConnector({
				id: chargePointId,
				connectorId: number,
			})
		).unwrap()
	}

	return (
		<Popover
			trigger="click"
			placement="right"
			title={`Connector ${number} (${ConnectorStatusEnum[status]})`}
			content={
				<Space style={{ width: "100%" }} direction="vertical">
					<Button onClick={onUnlockClick} icon={<UnlockOutlined />} block>
						{t("btns.unlock")}
					</Button>
					{features.includes(FeatureEnum.Reservation) && (
						<Button
							onClick={() => onConnectorActionClick(TagModalActionType.ConnectorReservation, number)}
							icon={<TrademarkOutlined />}
							block
						>
							{t("btns.reserve")}
						</Button>
					)}
					<Button
						onClick={() => onConnectorActionClick(TagModalActionType.StartTransaction, number)}
						icon={<PlayCircleOutlined />}
						block
					>
						{t("btns.start")}
					</Button>
				</Space>
			}
		>
			<div className={`${styles["connectorIconWrapper"]} ${styles[ConnectorStatusEnum[status]]}`}>
				<ConnectorIcon />
			</div>
		</Popover>
	)
}
