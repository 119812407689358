import "moment/locale/cs"

import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import dayjs from "dayjs"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import moment from "moment"

moment.locale("cs")
dayjs.locale("cs")

i18n
	.use(Backend)
	.use(
		new LanguageDetector(null, {
			lookupLocalStorage: "i18nextLng",
		})
	)
	.use(initReactI18next)
	.init({
		fallbackLng: localStorage.getItem("i18nextLng") ?? "cs",
		lng: localStorage.getItem("i18nextLng") ?? "cs",
		supportedLngs: ["cs", "en"],
		debug: true,
		preload: ["cs", "en"],
		backend: {
			loadPath: "/locales/{{lng}}.json",
			requestOptions: {
				cache: "no-store",
			},
		},
		interpolation: {
			escapeValue: false,
		},
	})

export default i18n
