import * as api from "../../common/api/api.json"

import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AppThunk } from "../../app/store"
import { FetchStatusEnum } from "../../common/enums/fetchStatusEnum"
import { UserAuthorization } from "./types"
import { createMyOwnAsyncThunk } from "../../common/helpers/reduxToolkitHelper"
import { httpClient } from "../../common/helpers/httpClientHelper"

interface AuthState {
	status: FetchStatusEnum
	user: UserAuthorization | undefined
}

const initialState: AuthState = {
	status: FetchStatusEnum.idle,
	user: undefined,
}

export const getUserAuthroizationAsync = createMyOwnAsyncThunk<UserAuthorization, boolean>(
	"auth/getUserAuthroizationAsync",
	() => {
		return httpClient.get<UserAuthorization>("auth/user")
	}
)

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getUserAuthroizationAsync.pending, (state, action) => {
			if (action.meta.arg) return
			state.status = FetchStatusEnum.success
		})
		builder.addCase(getUserAuthroizationAsync.fulfilled, (state, action) => {
			state.user = action.payload
			if (action.meta.arg) return
			state.status = FetchStatusEnum.success
		})
		builder.addCase(getUserAuthroizationAsync.rejected, (state, action) => {
			if (action.meta.arg) return
			state.status = FetchStatusEnum.success
		})
	},
})

export default authSlice.reducer
