import { FilterValue, SorterResult } from "antd/es/table/interface"
import { GraphqlCondition, GraphqlTableQueryParamsBaseModel } from "../models/graphqlModels"

import { TablePaginationConfig } from "antd"
import { defaultTablePageSize } from "../constants/constants"

export const initGraphqlTableQueryParamsBaseModel = (order: any[] = []) => {
	return {
		skip: 0,
		take: defaultTablePageSize,
		order: order,
	} as GraphqlTableQueryParamsBaseModel
}

export const handleGraphqlFilterTableChange = <T>(
	pagination: TablePaginationConfig,
	defaultOrder: SorterResult<T> | SorterResult<T>[] | any,
	sorter: SorterResult<T> | SorterResult<T>[],
	filter: Record<string, FilterValue | null | any>,
	callback: (filter: GraphqlTableQueryParamsBaseModel) => void
) => {
	const newSorter = sorter as SorterResult<T>
	const graphqlSorter: Record<string, any> = {}

	const fitlterArrays: any[] = []

	Object.entries(filter).forEach((x) => {
		if (!x[1]) return

		if (Array.isArray(x[1]) && (x[1][0].and?.length || x[1][0].or?.length)) {
			fitlterArrays.push(x[1][0])
		} else {
			fitlterArrays.push({ [x[0]]: Array.isArray(x[1]) ? x[1][0] : x[1] })
		}
	})

	const tableFilter: any = { and: fitlterArrays }

	const newParams = {
		skip: ((pagination.current ?? 0) - 1) * (pagination.pageSize ?? defaultTablePageSize),
		take: pagination.pageSize,
		order: defaultOrder,
		where: tableFilter,
	} as GraphqlTableQueryParamsBaseModel

	if (!!newSorter?.field && !!newSorter?.order) {
		graphqlSorter[newSorter.field.toString()] = newSorter.order === "ascend" ? "ASC" : "DESC"
		newParams.order = [graphqlSorter]
	}

	callback(newParams)
}

export const applyDefaultQueryFilter = (filter: GraphqlTableQueryParamsBaseModel, condition: any): GraphqlCondition => {
	if (!condition) return filter.where

	let newWhere = { ...filter.where }

	if (!newWhere?.and?.length) {
		newWhere = { and: [condition] }
		return newWhere
	}

	const newAndArray = [...newWhere.and]
	newAndArray.unshift(condition)
	newWhere.and = newAndArray

	return newWhere
}
