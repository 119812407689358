import { Form, Input } from "antd"

import { BaseFormItem } from "../../common/interfaces/formInterfaces"
import { InputFormType } from "../../common/types/types"
import React from "react"
import { Rule } from "antd/lib/form"
//import { useTranslation } from 'react-i18next'
import { format } from "react-string-format"
import { useTranslation } from "react-i18next"

//import styles from './FormItem.module.scss'

interface Props extends BaseFormItem {
	type?: InputFormType
	onChange?: (e: any) => void
	maxLength?: number
}

const InputFormItem = ({
	name,
	validationMessage,
	placeholder,
	isRequired,
	hidden,
	disabled,
	initialValue,
	type = "text",
	readonly,
	onChange,
	prefix,
	label,
	restField,
	rules,
	maxLength = undefined,
}: Props) => {
	const { t } = useTranslation()

	const getRules = (): Rule[] => {
		const rules: Rule[] = [
			{
				required: isRequired,
				message: validationMessage ?? t("validations.input.common"),
			},
		]

		if (type === "email") {
			rules.push({
				type: "email",
				message: t("validations.input.email"),
			})
		}

		if (type === "url") {
			rules.push({
				type: "url",
				message: t("validations.input.url"),
			})
		}

		if (maxLength !== undefined) {
			rules.push({
				max: maxLength,
				message: format(t("validations.input.maxLength"), maxLength),
			})
		}

		return rules
	}

	return (
		<Form.Item
			{...restField}
			hidden={hidden}
			name={name}
			getValueFromEvent={(e) => {
				return e.target.value.replace(/(?:\r\n|\r|\n|\t)/g, "")
			}}
			label={label}
			initialValue={initialValue}
			rules={rules ?? getRules()}
		>
			<Input
				prefix={prefix}
				onChange={onChange}
				disabled={disabled}
				readOnly={readonly}
				type={type}
				placeholder={placeholder}
			/>
		</Form.Item>
	)
}

export default InputFormItem
