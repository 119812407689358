import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { notifyError, notifySuccess } from "../../common/helpers/notificationHelpers"

import { FetchStatusEnum } from "../../common/enums/fetchStatusEnum"
import { SmartChargingModel } from "../chargePoints/types"
import i18n from "../../app/i18n"
import { requestHttpClient } from "../../common/helpers/httpClientHelper"
import { timeout } from "workbox-core/_private"

enum Commands {
	ResetConnectorCommand = "Reset",
	UnlockConnectorCommand = "UnlockConnector",
	RemoteStartTransaction = "RemoteStartTransaction",
	RemoteStopTransaction = "RemoteStopTransaction",
	ReserveNow = "ReserveNow",
	CancelReservation = "CancelReservation",
	GetConfiguration = "GetConfiguration",
	SetChargingProfil = "SetChargingProfile",
}
interface versionReceiverState {
	status: FetchStatusEnum
}

const initialState: versionReceiverState = {
	status: FetchStatusEnum.idle,
}

export const unlockConnector = createAsyncThunk<void, { id: string; connectorId: number }>(
	"versionReceiver/unlockConnector",
	async ({ id, connectorId }) => {
		const response = await requestHttpClient.post<void>(`/${id}/${Commands.UnlockConnectorCommand}`, { connectorId })
		return response.data
	}
)

export const resetConnector = createAsyncThunk<void, { id: string; type: number }>(
	"versionReceiver/resetConnector",
	async ({ id, type }) => {
		const response = await requestHttpClient.post<void>(`${id}/${Commands.ResetConnectorCommand}`, { type })
		return response.data
	}
)

export const startTransactionAsync = createAsyncThunk<void, { id: string; idTag: string; connectorId?: number }>(
	"versionReceiver/startTransactionAsync",
	async ({ id, idTag, connectorId }) => {
		const response = await requestHttpClient.post<void>(`${id}/${Commands.RemoteStartTransaction}`, {
			idTag,
			connectorId,
		})
		return response.data
	}
)

export const stopTransactionAsync = createAsyncThunk<void, { id: string; transactionId: number }>(
	"versionReceiver/stopTransactionAsync",
	async ({ id, transactionId }) => {
		const response = await requestHttpClient.post<void>(`${id}/${Commands.RemoteStopTransaction}`, {
			transactionId,
		})
		return response.data
	}
)

export const reserveNowAsync = createAsyncThunk<void, { id: string; idTag: string; connectorId: number }>(
	"versionReceiver/reserveNowAsync",
	async ({ id, idTag, connectorId }) => {
		const response = await requestHttpClient.post<void>(`${id}/${Commands.ReserveNow}`, {
			idTag,
			connectorId,
		})
		return response.data
	}
)

export const cancelReservationAsync = createAsyncThunk<void, { id: string; reservationId: number }>(
	"versionReceiver/cancelReservationAsync",
	async ({ id, reservationId }) => {
		const response = await requestHttpClient.post<void>(`${id}/${Commands.CancelReservation}`, {
			reservationId,
		})
		return response.data
	}
)

export const getConfigurationAsync = createAsyncThunk<any, string>("versionReceiver/getConfiguration", async (id) => {
	const response = await requestHttpClient.post<any>(`${id}/${Commands.GetConfiguration}`)
	return response.data
})

export const setSmartChargingAsync = createAsyncThunk<void, { id: string; model: SmartChargingModel }>(
	"versionReceiver/setSmartChargingAsync",
	async ({ id, model }) => {
		const response = await requestHttpClient.post<void>(`${id}/${Commands.SetChargingProfil}`, model)
		return response.data
	}
)

export const versionReceiverSlice = createSlice({
	name: "versionReceiver",
	initialState,
	reducers: {
		setStatus: (state, action: PayloadAction<FetchStatusEnum>) => {
			state.status = action.payload
		},
	},

	extraReducers: (builder) => {
		builder.addCase(unlockConnector.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(unlockConnector.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
			notifySuccess(i18n.t("titles.chargePoints"), i18n.t("notifications.error.command"))
		})
		builder.addCase(unlockConnector.rejected, (state) => {
			state.status = FetchStatusEnum.failed
			notifyError(i18n.t("titles.chargePoints"), i18n.t("notifications.error.command"))
		})

		builder.addCase(resetConnector.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(resetConnector.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
			notifySuccess(i18n.t("titles.chargePoints"), i18n.t("notifications.error.command"))
		})
		builder.addCase(resetConnector.rejected, (state) => {
			state.status = FetchStatusEnum.failed
			notifyError(i18n.t("titles.chargePoints"), i18n.t("notifications.error.command"))
		})

		builder.addCase(startTransactionAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(startTransactionAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
			notifySuccess(i18n.t("titles.chargePoints"), i18n.t("notifications.error.command"))
		})
		builder.addCase(startTransactionAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
			notifyError(i18n.t("titles.chargePoints"), i18n.t("notifications.error.command"))
		})

		builder.addCase(stopTransactionAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(stopTransactionAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
			notifySuccess(i18n.t("titles.chargePoints"), i18n.t("notifications.error.command"))
		})
		builder.addCase(stopTransactionAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
			notifyError(i18n.t("titles.chargePoints"), i18n.t("notifications.error.command"))
		})

		builder.addCase(reserveNowAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(reserveNowAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
			notifySuccess(i18n.t("titles.chargePoints"), i18n.t("notifications.error.command"))
		})
		builder.addCase(reserveNowAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
			notifyError(i18n.t("titles.chargePoints"), i18n.t("notifications.error.command"))
		})

		builder.addCase(cancelReservationAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(cancelReservationAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
			notifySuccess(i18n.t("titles.chargePoints"), i18n.t("notifications.error.command"))
		})
		builder.addCase(cancelReservationAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
			notifyError(i18n.t("titles.chargePoints"), i18n.t("notifications.error.command"))
		})

		builder.addCase(getConfigurationAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(getConfigurationAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(getConfigurationAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})
	},
})

export default versionReceiverSlice.reducer
