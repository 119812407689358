import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
// import { logoutAsync, setUserData } from "../../features/auth/authSlice"
//import { removeLoginDataFromLocalStorage, saveLoginDataToLocalStorage } from "../helpers/tokenHelper"

// import { LoginResponseModel } from "../../features/auth/types"
// import apiData from "../api/api.json"
// import i18n from "../../app/i18n"
import { httpClient as instance } from "../helpers/httpClientHelper"
// import { languageMap } from "../constants/constants"

let isAlreadyFetchingAccessToken = false
let subscribers: any[] = []

function onAccessTokenFetched() {
	subscribers = subscribers.filter((callback) => callback())
}

function addSubscriber(callback: () => void) {
	subscribers.push(callback)
}

export const setupAxiosInterceptors = (store: any) => {
	//instance.interceptors.request.use(axiosRequestInterceptor)
	instance.interceptors.response.use(axiosRequestSuccessInterceptor, (error) =>
		axiosRequestErrorInterceptor(error, store)
	)
}

const axiosRequestInterceptor = (request: AxiosRequestConfig): AxiosRequestConfig => {
	request.headers = {
		...request.headers,
		"Accept-Language": ["en-US"],
	}

	return request
}

const axiosRequestSuccessInterceptor = (response: AxiosResponse) => response
const axiosRequestErrorInterceptor = (error: any, store: any) => {
	const {
		config,
		response: { status },
	} = error
	const originalRequest = config

	// if (isAlreadyFetchingAccessToken && status !== 200 && config.url === apiData.auth.refreshtoken) {
	// 	removeLoginDataFromLocalStorage()
	// 	store.dispatch(logoutAsync())
	// 	subscribers = []
	// 	return
	// }

	// if (status === 401 && config.url !== apiData.auth.login) {
	// 	if (!isAlreadyFetchingAccessToken) {
	// 		isAlreadyFetchingAccessToken = true

	// 		instance.post<LoginResponseModel>(apiData.auth.refreshtoken).then((response) => {
	// 			if (response !== undefined) {
	// 				store.dispatch(setUserData(response.data))
	// 				saveLoginDataToLocalStorage(response.data)
	// 			}
	// 			isAlreadyFetchingAccessToken = false
	// 			onAccessTokenFetched()
	// 		})
	// 	}

	// 	const retryOriginalRequest = new Promise((resolve) => {
	// 		addSubscriber(() => {
	// 			resolve(axios(originalRequest))
	// 		})
	// 	})
	// 	return retryOriginalRequest
	// }

	return Promise.reject(error)
}
