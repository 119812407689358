export enum FeatureEnum {
	Core = 1,

	LocalAuthListManagement = 2,

	Reservation = 3,

	SmartCharging = 4,

	RemoteTrigger = 5,

	FirmwareManagement = 6,
}
