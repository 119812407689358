import { GraphqlQueryItemsBaseModel, GraphqlTableQueryParamsBaseModel } from "../../common/models/graphqlModels"
import {
	applyDefaultQueryFilter,
	handleGraphqlFilterTableChange,
	initGraphqlTableQueryParamsBaseModel,
} from "../../common/helpers/graphQlHelpers"
import { dateTimeFormat, defaultTablePageSize } from "../../common/constants/constants"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useEffect, useState } from "react"
import { useLazyQuery, useQuery } from "@apollo/client"

import { Button } from "antd"
import { ColumnType } from "antd/es/table"
import MainTable from "../../components/tables/MainTable"
import { QUERY_RESERVATIONS } from "../../features/reservations/graphql/queries"
import { ReservationGraphQlDto } from "../../features/reservations/graphql/types"
import { ReservationStatusEnum } from "../../common/enums/reservationStatusEnum"
import { Table } from "antd"
import { cancelReservationAsync } from "../../features/versionReceiver/versionReceiver"
import { columnHelper } from "../../common/helpers/tableHelpers"
import dayjs from "dayjs"
import moment from "moment"
import { nameof } from "ts-simple-nameof"
import { useTranslation } from "react-i18next"

interface Props {
	type: "user" | "admin"
}

const Reservations = ({ type }: Props) => {
	const { userId } = useAppSelector((state) => state.auth.user)

	const defaultOrder = [{ id: "DESC" }]
	const defaultWhere = type === "admin" ? undefined : { userId: { eq: userId } }

	const [queryParams, setQueryParams] = useState<GraphqlTableQueryParamsBaseModel>({
		...initGraphqlTableQueryParamsBaseModel(defaultOrder),
		where: defaultWhere,
	})

	const [getData, { data, loading }] = useLazyQuery<
		GraphqlQueryItemsBaseModel<ReservationGraphQlDto>,
		GraphqlTableQueryParamsBaseModel
	>(QUERY_RESERVATIONS, {
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "cache-and-network",
	})

	useEffect(() => {
		getData({ variables: queryParams })
	}, [type, queryParams])

	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const columns: ColumnType<ReservationGraphQlDto>[] = [
		{
			...columnHelper(
				"ConnectorId",
				nameof<ReservationGraphQlDto>((x) => x.connectorId)
			),
		},
		{
			...columnHelper(
				"expiryDate",
				nameof<ReservationGraphQlDto>((x) => x.expiryDate)
			),
			render(value, record, index) {
				return dayjs(value).format(dateTimeFormat)
			},
		},
		{
			...columnHelper(
				"tagId",
				nameof<ReservationGraphQlDto>((x) => x.tagId)
			),
		},
		{
			...columnHelper(
				"status",
				nameof<ReservationGraphQlDto>((x) => x.status)
			),
			render(value, record, index) {
				return ReservationStatusEnum[value]
			},
		},
		{
			title: t("labels.action"),
			key: "action",
			render: (_, record) => {
				return record.status === ReservationStatusEnum.New ? (
					<Button
						onClick={async () => {
							await dispatch(cancelReservationAsync({ id: record.chargePointId, reservationId: record.id })).unwrap()
						}}
					>
						{t("btns.cancel")}
					</Button>
				) : (
					<></>
				)
			},
			width: 100,
		},
	]

	return (
		<MainTable
			loading={loading}
			columns={columns}
			onChange={(pagination, filter, sorter) => {
				handleGraphqlFilterTableChange(pagination, defaultOrder, sorter, filter, (newParams) => {
					setQueryParams({
						...queryParams,
						...newParams,
						where: applyDefaultQueryFilter(newParams, defaultWhere),
					})
				})
			}}
			totalCount={data?.query?.totalCount}
			dataSource={data?.query?.items}
		/>
	)
}

export default Reservations
