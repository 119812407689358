import { BaseFormItemProps } from "../interfaces/formInterfaces"
import { Dayjs } from "dayjs"
import { Moment } from "moment"
import { Rule } from "antd/es/form"
import dayjs from "dayjs"
import { isoDateFormatRegex } from "../constants/constants"
import moment from "moment"

export const objectToFormData = (obj: any, rootName?: any, ignoreList?: any[]) => {
	var formData = new FormData()

	function appendFormData(data: any, root: string) {
		if (!ignore(root)) {
			root = root || ""
			if (data instanceof File) {
				formData.append(root, data)
			} else if (data instanceof moment) {
				data && formData.append(root, (data as Moment).toISOString())
			} else if (data instanceof dayjs) {
				data && formData.append(root, (data as Dayjs).toISOString())
			} else if (Array.isArray(data)) {
				for (var i = 0; i < data.length; i++) {
					appendFormData(data[i], root + "[" + i + "]")
				}
			} else if (typeof data === "object" && data) {
				for (var key in data) {
					if (data.hasOwnProperty(key)) {
						if (root === "") {
							appendFormData(data[key], key)
						} else {
							appendFormData(data[key], root + "." + key)
						}
					}
				}
			} else {
				if (data !== null && typeof data !== "undefined") {
					formData.append(root, data)
				}
			}
		}
	}

	function ignore(root: any) {
		return (
			Array.isArray(ignoreList) &&
			ignoreList.some(function (x) {
				return x === root
			})
		)
	}

	appendFormData(obj, rootName)

	return formData
}

const isIsoDateString = (value: any): boolean => {
	return value && typeof value === "string" && isoDateFormatRegex.test(value)
}

export const datesToMoment = (model: any) => {
	const data = { ...model }

	if (data === null || data === undefined || typeof data !== "object") {
		return data
	}

	for (const key of Object.keys(data)) {
		const value = data[key]
		if (isIsoDateString(value)) data[key] = moment(value)
		else if (typeof value === "object") datesToMoment(value)
	}

	return data
}

export const datesToDayjs = (model: any) => {
	const data = { ...model }

	if (data === null || data === undefined || typeof data !== "object") {
		return data
	}

	for (const key of Object.keys(data)) {
		const value = data[key]
		if (isIsoDateString(value)) data[key] = dayjs(value)
		else if (typeof value === "object") datesToMoment(value)
	}

	return data
}
