import { gql } from "@apollo/client"

export const QUERY_USERS = gql`
	query GetUsers($skip: Int, $take: Int, $order: [UserGraphqlDtoSortInput!], $where: UserGraphqlDtoFilterInput) {
		query: users(skip: $skip, take: $take, order: $order, where: $where) {
			totalCount
			items {
				id
				fullname
				email
				companyName
				chargeTagCount
			}
		}
	}
`

export const QUERY_USER_DETAIL = gql`
	query GetUser($id: Long!) {
		query: user(id: $id) {
			id
			name
			surname
			email
			companyId
			chargeTagIds
		}
	}
`

export const QUERY_USERS_DROPDOWN = gql`
	query GetUsersDropdown($order: [UserGraphqlDtoSortInput!], $where: UserGraphqlDtoFilterInput) {
		query: users(order: $order, where: $where) {
			totalCount
			items {
				id
				name: email
			}
		}
	}
`
