import Form, { useForm } from "antd/es/form/Form"
import { createUserAsync, updateUserAsync } from "../../../../features/users/usersSlice"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"

import BaseEditModal from "../../../../components/modals/BaseEditModal"
import { ChargePointModel } from "../../../../features/chargePoints/types"
import { FetchStatusEnum } from "../../../../common/enums/fetchStatusEnum"
import FormItemWrapper from "../../../../components/formItems/FormItemWrapper"
import InputFormItem from "../../../../components/formItems/InputFormItem"
import { QUERY_COMPANY_DETAIL } from "../../../../features/companies/graphql/queries"
import { QUERY_USER_DETAIL } from "../../../../features/users/graphql/queries"
import React from "react"
import SelectFormItem from "../../../../components/formItems/SelectFormItem"
import { UserDetailGraphQlDto } from "../../../../features/users/graphql/types"
import { UserModel } from "../../../../features/users/types"
import { getCompaniesDropdownSourceAsync } from "../../../../features/companies/companySlice"
import { getTagsDropdownSourceAsync } from "../../../../features/chargeTags/chargeTagSlice"
import { nameof } from "ts-simple-nameof"
import { objectToFormData } from "../../../../common/helpers/formDataHelper"
import { useEffect } from "react"
import { useLazyQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

interface Props {
	open: boolean
	onClose: (withRefetch: boolean) => void
	id: number | undefined
}

const UserEditModal = ({ open, onClose, id }: Props) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [form] = useForm<UserModel>()

	const { companiesDropdown } = useAppSelector((x) => x.companies)
	const { status } = useAppSelector((x) => x.users)
	const { tagsDropdown } = useAppSelector((x) => x.chargeTags)

	const [getUser, { loading }] = useLazyQuery<{ query: UserDetailGraphQlDto | undefined }, { id: number }>(
		QUERY_USER_DETAIL,
		{
			fetchPolicy: "network-only",
			notifyOnNetworkStatusChange: true,
			onCompleted(data) {
				form.setFieldsValue(data.query)
			},
		}
	)

	useEffect(() => {
		if (!open) {
			form.resetFields()
			return
		}

		dispatch(getCompaniesDropdownSourceAsync())
		dispatch(getTagsDropdownSourceAsync())

		if (!id) return
		getUser({ variables: { id: id } })
	}, [open, id])

	const onEditFormSubmit = async (model: UserModel) => {
		const formData = objectToFormData(model)

		if (id) await dispatch(updateUserAsync({ id: id, data: formData })).unwrap()
		else await dispatch(createUserAsync(formData)).unwrap()

		onClose(true)
	}

	return (
		<BaseEditModal
			title={t("titles.editUser")}
			isOpen={open}
			loading={loading || status === FetchStatusEnum.loading}
			onCancel={() => onClose(false)}
			onFormSubmit={form.submit}
		>
			<Form layout="vertical" form={form} onFinish={onEditFormSubmit}>
				<InputFormItem name={nameof<UserModel>((x) => x.id)} hidden />
				<FormItemWrapper
					firstItem={
						<InputFormItem
							name={nameof<UserModel>((x) => x.name)}
							isRequired
							label={t("labels.firstname")}
							maxLength={255}
						/>
					}
					secondItem={
						<InputFormItem
							name={nameof<UserModel>((x) => x.surname)}
							isRequired
							label={t("labels.surname")}
							maxLength={255}
						/>
					}
				/>
				<FormItemWrapper
					firstItem={
						<InputFormItem
							name={nameof<UserModel>((x) => x.email)}
							isRequired
							label={t("labels.email")}
							maxLength={255}
							disabled={!!id}
						/>
					}
					secondItem={
						<SelectFormItem
							label={t("labels.company")}
							items={companiesDropdown.data}
							allowClear
							isLoading={companiesDropdown.status === FetchStatusEnum.loading}
							name={nameof<UserModel>((x) => x.companyId)}
						/>
					}
				/>
				<SelectFormItem
					label={t("titles.chargeTags")}
					items={tagsDropdown.data}
					allowClear
					disabled
					mode="multiple"
					isLoading={tagsDropdown.status === FetchStatusEnum.loading}
					name={nameof<UserModel>((x) => x.chargeTagIds)}
				/>
			</Form>
		</BaseEditModal>
	)
}

export default UserEditModal
