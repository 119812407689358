import { ApolloClient, ApolloLink, FetchResult, InMemoryCache, Observable, createHttpLink } from "@apollo/client"

import api from "../../common/api/api.json"
import { httpClient } from "../helpers/httpClientHelper"
import { onError } from "@apollo/client/link/error"
import { store } from "../../app/store"

const httpLink = createHttpLink({
	uri: process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_URL ?? ""}/graphql` : `/graphql`,
	credentials: "include",
})

export const graphqlClient = new ApolloClient({
	cache: new InMemoryCache(),
	link: ApolloLink.from([httpLink]),
})
