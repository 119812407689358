import { BaseFormItemProps, FormItemWrapperProps } from "../../common/interfaces/formInterfaces"
import { ConfigProvider, Form, Space, Switch, SwitchProps } from "antd"
import React, { ReactNode } from "react"

import styles from "./FormItem.module.scss"
import { t } from "i18next"
import { useTranslation } from "react-i18next"

export interface SwitchFormItemProps extends FormItemWrapperProps<BaseFormItemProps, SwitchProps> {
	rightSideNode?: ReactNode
}

const SwitchFormItem = ({ formProps, elementProps, rightSideNode }: SwitchFormItemProps) => {
	const { t } = useTranslation()

	return (
		<Form.Item
			{...formProps}
			className={`${styles["primaryFormItemStyle"]}`}
			valuePropName="checked"
			rules={
				formProps.isRequired
					? [
							{
								required: true,
								message: t("validations.input.common"),
							},
					  ]
					: formProps.rules
			}
		>
			<Space>
				<Switch {...elementProps} />
				{rightSideNode}
			</Space>
		</Form.Item>
	)
}

export default SwitchFormItem
