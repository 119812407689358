import { FloatButton, Tooltip } from "antd"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"
import { useEffect, useState } from "react"

import { ChargePointAvailabilityEnum } from "../../../common/enums/chargePointAvailabilityEnum"
import { ChargePointLocationGraphQlDto } from "../../../features/chargePoints/graphql/types"
import { DashbaordFilterModel } from "../../../features/dashboard/graphql/types"
import { FilterFilled } from "@ant-design/icons"
import FilterPopover from "../../../components/popovers/FilterPopover"
import { GraphqlQueryItemsBaseModel } from "../../../common/models/graphqlModels"
import { QUERY_CHARGEPOINTS_LOCATIONS } from "../../../features/chargePoints/graphql/queries"
import chargerIconBlueSrc from "../../../assets/chargerIconBlue.svg"
import chargerIconGreenSrc from "../../../assets/chargerIconGreen.svg"
import chargerIconRedSrc from "../../../assets/chargerIconRed.svg"
import chargerIconSrc from "../../../assets/chargerIcon.svg"
import styles from "./Map.module.scss"
import { useQuery } from "@apollo/client"

const Map = () => {
	const [filter, setFilter] = useState<DashbaordFilterModel>(undefined)

	const { data } = useQuery<GraphqlQueryItemsBaseModel<ChargePointLocationGraphQlDto>>(QUERY_CHARGEPOINTS_LOCATIONS, {
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "network-only",
		variables: {
			where: {
				and: [
					{ latitude: { neq: null } },
					{ longitude: { neq: null } },
					{ id: filter?.chargePointIds?.length ? { in: filter.chargePointIds } : undefined },
					{ companyId: filter?.companyIds?.length ? { in: filter.companyIds } : undefined },
					{ userIds: filter?.userIds?.length ? { some: { in: filter.userIds } } : undefined },
				],
			},
		},
	})

	const getIcon = (availabilityState: ChargePointAvailabilityEnum) => {
		switch (availabilityState) {
			case ChargePointAvailabilityEnum.Occupied:
				return chargerIconGreenSrc
			case ChargePointAvailabilityEnum.Offline:
				return chargerIconRedSrc
			case ChargePointAvailabilityEnum.Available:
				return chargerIconBlueSrc
			default:
				return chargerIconSrc
		}
	}

	return (
		<>
			<MapContainer style={{ height: "100%", width: "100%" }} center={[49.8175, 15.473]} zoom={8}>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				{data?.query?.items?.map((x) => {
					return (
						<Marker
							icon={L.icon({
								iconUrl: getIcon(x.availabilityState),
								iconSize: [36, 36],
								className: styles[`mapIcon`],
							})}
							position={[x.latitude, x.longitude]}
						>
							{x.name && <Popup>{x.name}</Popup>}
						</Marker>
					)
				})}
			</MapContainer>
			<FilterPopover onFilterChange={setFilter} />
		</>
	)
}

export default Map
