import { BaseState, DropdownWrapper, SelectItem } from "../../common/models/customModels"
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { CompanyModel } from "./types"
import { DataResponseModel } from "../../common/models/responseModels"
import { FetchStatusEnum } from "../../common/enums/fetchStatusEnum"
import api from "../../common/api/api.json"
import { createMyOwnAsyncThunk } from "../../common/helpers/reduxToolkitHelper"
import { httpClient } from "../../common/helpers/httpClientHelper"
import { initDropdowmWrapperModel } from "../../common/helpers/modelHelperts"

interface chargeTagState extends BaseState {
	companiesDropdown: DropdownWrapper<SelectItem<number>>
}

const initialState: chargeTagState = {
	status: FetchStatusEnum.idle,
	companiesDropdown: initDropdowmWrapperModel(),
}

export const createCompanyAsync = createMyOwnAsyncThunk<CompanyModel, FormData>(
	"companies/createCompanyAsync",
	(data) => {
		return httpClient.post<CompanyModel>(api.admin.companies.base, data)
	}
)

export const updateCompanyAsync = createMyOwnAsyncThunk<CompanyModel, { id: number; data: FormData }>(
	"companies/updateCompanyAsync",
	({ id, data }) => {
		return httpClient.put<CompanyModel>(api.admin.companies.base + "/" + id, data)
	}
)

export const deleteCompanyAsync = createMyOwnAsyncThunk<CompanyModel, { id: number }>(
	"companies/deleteCompanyAsync",
	({ id }) => {
		return httpClient.delete<CompanyModel>(api.admin.companies.base + "/" + id)
	}
)

export const getCompaniesDropdownSourceAsync = createMyOwnAsyncThunk<SelectItem<number>[], void>(
	"companies/getCompaniesDropdownSourceAsync",
	() => {
		return httpClient.get<SelectItem<number>[]>(api.admin.companies.dropdown)
	}
)

export const chargeTagSlice = createSlice({
	name: "companies",
	initialState,
	reducers: {
		setStatus: (state, action: PayloadAction<FetchStatusEnum>) => {
			state.status = action.payload
		},
	},

	extraReducers: (builder) => {
		builder.addCase(getCompaniesDropdownSourceAsync.pending, (state, action) => {
			state.companiesDropdown.status = FetchStatusEnum.loading
		})
		builder.addCase(getCompaniesDropdownSourceAsync.fulfilled, (state, action) => {
			state.companiesDropdown.data = action.payload
			state.companiesDropdown.status = FetchStatusEnum.success
		})
		builder.addCase(getCompaniesDropdownSourceAsync.rejected, (state, action) => {
			state.companiesDropdown.status = FetchStatusEnum.failed
		})

		builder.addCase(createCompanyAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(createCompanyAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(createCompanyAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})

		builder.addCase(updateCompanyAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(updateCompanyAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(updateCompanyAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})

		builder.addCase(deleteCompanyAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(deleteCompanyAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(deleteCompanyAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})
	},
})

export default chargeTagSlice.reducer
