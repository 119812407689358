import { Badge } from "antd"
import { ChargePointAvailabilityEnum } from "../enums/chargePointAvailabilityEnum"
import i18n from "../../app/i18n"

export const getBadge = (availabilityState: ChargePointAvailabilityEnum) => {
	const title = i18n.t(`chargePointAvailability.${ChargePointAvailabilityEnum[availabilityState]}`)

	switch (availabilityState) {
		case ChargePointAvailabilityEnum.Available:
			return <Badge color="#0088fe" text={title}></Badge>
		case ChargePointAvailabilityEnum.Offline:
			return <Badge color="#e51919" text={title}></Badge>
		case ChargePointAvailabilityEnum.Occupied:
			return <Badge color="0088fe" text={title}></Badge>
		default:
			return <></>
	}
}
