import { Button, Result, Row, Spin } from "antd"
import { Outlet, useNavigate } from "react-router-dom"
import React, { useContext } from "react"

import { RoleEnum } from "../../common/enums/roleEnum"
import nav from "../../common/navigation/nav.json"
import { useAppSelector } from "../../app/hooks"
import { useOidc } from "@axa-fr/react-oidc"
import { useTranslation } from "react-i18next"

interface Props {
	roles?: RoleEnum[]
}

const PrivateRoute = ({ roles }: Props) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { login, isAuthenticated } = useOidc()
	const { user } = useAppSelector((x) => x.auth)

	if (isAuthenticated && !user) {
		return (
			<Spin
				delay={1000}
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
				}}
				spinning
			/>
		)
	}

	return isAuthenticated && (!roles || (!!roles && user.roles.some((x) => roles.some((y) => y === x)))) ? (
		<Outlet />
	) : (
		<Row style={{ height: "100%" }} align="middle" justify="center">
			<Result
				status="403"
				title={t("texts.loginRequiredTitle")}
				subTitle={t("texts.loginRequiredSubtitle")}
				extra={[
					<Button onClick={() => navigate(nav.dashboard.base, { replace: true })} type="default">
						{t("btns.mainPage")}
					</Button>,
					<Button onClick={() => login()} type="primary">
						{t("btns.login")}
					</Button>,
				]}
			/>
		</Row>
	)
}

export default PrivateRoute
