export const languageMap: Record<string, string> = {
	en: "en-US",
	cs: "cs-CZ",
}

export const dateFormat = "DD.MM.YYYY"
export const dateTimeFormat = "DD.MM.YYYY HH:mm:ss"
export const dateTimeFormatWithoutSeconds = "DD.MM.YYYY HH:mm"
export const dateFormatISO = "YYYY-MM-DDTHH:mm:ss.SSS[Z]"

export const isoDateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z?/

export const passwordRegex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$"

export const defaultTablePageSize = 50

export const globalRed = "#e51919"
