import { Button, Col, Row, Table } from "antd"
import {
	GraphqlQueryItemsBaseModel,
	GraphqlTableQueryParamsBaseModel,
} from "../../../../../common/models/graphqlModels"
import React, { useState } from "react"
import {
	handleGraphqlFilterTableChange,
	initGraphqlTableQueryParamsBaseModel,
} from "../../../../../common/helpers/graphQlHelpers"

import { ChargePointGraphQlDto } from "../../../../../features/chargePoints/graphql/types"
import { ColumnType } from "antd/es/table"
import MainTable from "../../../../../components/tables/MainTable"
import { QUERY_TRANSACTIONS } from "../../../../../features/transactions/graphql/quries"
import { TransactionGraphQlDto } from "../../../../../features/transactions/graphql/types"
import { TransactionTypeEnum } from "../../../../../common/enums/transactionTypeEnum"
import { columnHelper } from "../../../../../common/helpers/tableHelpers"
import { dateTimeFormat } from "../../../../../common/constants/constants"
import dayjs from "dayjs"
import { getEnumSelectOptions } from "../../../../../common/helpers/enumHelpers"
import moment from "moment"
import { nameof } from "ts-simple-nameof"
import { stopTransactionAsync } from "../../../../../features/versionReceiver/versionReceiver"
import styles from "../../ChargePoint.module.scss"
import { useAppDispatch } from "../../../../../app/hooks"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

interface GraphQlParamsModel extends GraphqlTableQueryParamsBaseModel {
	chargePointId: number
}

interface Props {
	chargePoint: ChargePointGraphQlDto
}

const ChargePointTransactionsTab = ({ chargePoint }: Props) => {
	const defaultOrder = [{ id: "DESC" }]

	const [queryParams, setQueryParams] = useState<GraphQlParamsModel>({
		...initGraphqlTableQueryParamsBaseModel(defaultOrder),
		chargePointId: chargePoint.id,
	})

	const { data, loading } = useQuery<
		GraphqlQueryItemsBaseModel<TransactionGraphQlDto>,
		GraphqlTableQueryParamsBaseModel
	>(QUERY_TRANSACTIONS, {
		variables: queryParams,
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "network-only",
	})

	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const columns: ColumnType<TransactionGraphQlDto>[] = [
		{
			...columnHelper(
				t("labels.user"),
				nameof<TransactionGraphQlDto>((x) => x.user),
				"Text"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.chargeTag"),
				nameof<TransactionGraphQlDto>((x) => x.startTagName),
				"Text"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.type"),
				nameof<TransactionGraphQlDto>((x) => x.transactionTypeId),
				"MultiSelect",
				getEnumSelectOptions(TransactionTypeEnum, t, "transactionTypeEnum")
			),
			render(value) {
				return t(`transactionTypeEnum.${TransactionTypeEnum[value ?? TransactionTypeEnum.Unspecified]}`)
			},
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.startTime"),
				nameof<TransactionGraphQlDto>((x) => x.startTime),
				"Date"
			),
			render(value) {
				return value ? dayjs(value).format(dateTimeFormat) : ""
			},
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.stopTime"),
				nameof<TransactionGraphQlDto>((x) => x.stopTime),
				"Date"
			),
			render(value) {
				return value ? dayjs(value).format(dateTimeFormat) : ""
			},
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.duration"),
				nameof<TransactionGraphQlDto>((x) => x.chargeDuration)
			),
			render(value) {
				return value ? moment.utc(value * 1000 * 60).format("HH:mm") : ""
			},
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.meterStart"),
				nameof<TransactionGraphQlDto>((x) => x.meterStart),
				"Number"
			),
			render(value) {
				return value ? `${value.toFixed(2)} KW` : ""
			},
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.meterStop"),
				nameof<TransactionGraphQlDto>((x) => x.meterStop),
				"Number"
			),
			render(value) {
				return value ? `${value.toFixed(2)} KW` : ""
			},
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.totalEnergy"),
				nameof<TransactionGraphQlDto>((x) => x.chargeValue),
				"Number"
			),
			render(value) {
				return value ? `${value.toFixed(2)} KW` : ""
			},
			sorter: true,
		},
		{
			title: t("labels.action"),
			key: "action",
			render: (_, record) => {
				return !record.stopTime ? (
					<Button
						onClick={async () => {
							await dispatch(stopTransactionAsync({ id: record.chargePointId, transactionId: record.id })).unwrap()
						}}
					>
						Stop
					</Button>
				) : (
					<></>
				)
			},
			fixed: "right",
			width: 100,
		},
	]

	return (
		<div style={{ maxHeight: "100%", height: "100%", display: "flex", flexDirection: "column", gap: 16 }}>
			<div
				className={styles["chargerWrapper"]}
				style={{ flex: "1 1 auto", overflow: "hidden", height: "100%", padding: "24px" }}
			>
				<MainTable
					onChange={(pagination, filter, sorter) => {
						handleGraphqlFilterTableChange(pagination, defaultOrder, sorter, filter, (newParams) => {
							setQueryParams({
								...queryParams,
								...newParams,
							})
						})
					}}
					totalCount={data?.query?.totalCount}
					loading={loading}
					columns={columns}
					dataSource={data?.query?.items}
				/>
			</div>
			{/* <div
				style={{ flex: "1 1 auto", overflow: "hidden", height: "100%", padding: "24px" }}
				className={styles["chargerWrapper"]}
			>
				<Row gutter={[16, 16]} style={{ height: "100%" }}>
					<Col style={{ height: "100%" }} flex="auto"></Col>
					<Col style={{ width: "400px", height: "100%" }}></Col>
				</Row>
			</div> */}
		</div>
	)
}

export default ChargePointTransactionsTab
