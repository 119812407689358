import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { BaseState } from "../../common/models/customModels"
import { ChargePointModel } from "./types"
import { FetchStatusEnum } from "../../common/enums/fetchStatusEnum"
import api from "../../common/api/api.json"
import { createMyOwnAsyncThunk } from "../../common/helpers/reduxToolkitHelper"
import { httpClient } from "../../common/helpers/httpClientHelper"

interface chargePointState extends BaseState {}

const initialState: chargePointState = {
	status: FetchStatusEnum.idle,
}

export const getChargePointDetailAsync = createMyOwnAsyncThunk<ChargePointModel, number>(
	"chargePoints/getChargePointDetailAsync",
	(id) => {
		return httpClient.get<ChargePointModel>(api.chargePoint.base + "/" + id)
	}
)

export const createChargePointAsync = createMyOwnAsyncThunk<ChargePointModel, FormData>(
	"chargePoints/createChargePointAsync",
	(data) => {
		return httpClient.post<ChargePointModel>(api.chargePoint.base, data)
	}
)

export const updateChargePointAsync = createMyOwnAsyncThunk<ChargePointModel, { id: number; data: FormData }>(
	"chargePoints/updateChargePointAsync",
	({ id, data }) => {
		return httpClient.put<ChargePointModel>(api.chargePoint.base + "/" + id, data)
	}
)

export const deleteChargePointAsync = createMyOwnAsyncThunk<ChargePointModel, { id: number }>(
	"chargePoints/deleteChargePointAsync",
	({ id }) => {
		return httpClient.delete<ChargePointModel>(api.chargePoint.base + "/" + id)
	}
)

export const updateChargePointChargeTagAsync = createMyOwnAsyncThunk<
	void,
	{ id: number; tagId: number; data: FormData }
>("chargePoints/updateChargePointChargeTagAsync", ({ id, tagId, data }) => {
	return httpClient.post<void>(`${api.chargePoint.base}/${id}/tags/${tagId}`, data)
})

export const chargePointSlice = createSlice({
	name: "chargePoints",
	initialState,
	reducers: {
		setStatus: (state, action: PayloadAction<FetchStatusEnum>) => {
			state.status = action.payload
		},
	},

	extraReducers: (builder) => {
		builder.addCase(getChargePointDetailAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(getChargePointDetailAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(getChargePointDetailAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})

		builder.addCase(createChargePointAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(createChargePointAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(createChargePointAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})

		builder.addCase(updateChargePointAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(updateChargePointAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(updateChargePointAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})

		builder.addCase(deleteChargePointAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(deleteChargePointAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(deleteChargePointAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})

		builder.addCase(updateChargePointChargeTagAsync.pending, (state) => {
			state.status = FetchStatusEnum.loading
		})
		builder.addCase(updateChargePointChargeTagAsync.fulfilled, (state, action) => {
			state.status = FetchStatusEnum.success
		})
		builder.addCase(updateChargePointChargeTagAsync.rejected, (state) => {
			state.status = FetchStatusEnum.failed
		})
	},
})

export default chargePointSlice.reducer
