import { Card, Skeleton } from "antd"

import styles from "./Card.module.scss"

const SkeletCard = () => {
	return (
		<Card bordered={false} className={styles.criclebox}>
			<Skeleton />
		</Card>
	)
}

export default SkeletCard
