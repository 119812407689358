import "./custom.css"

import { Route, Routes } from "react-router-dom"

import AdminDashboard from "./containers/admin/dashboard/Dashboard"
import AdminMap from "./containers/admin/map/Map"
import AdminTransactions from "./containers/admin/transactions/Transactions"
import AppLayout from "./components/AppLayout"
import ChargePointAdminDetail from "./containers/admin/chargePoints/details/ChargePointAdminDetail"
import ChargePoints from "./containers/admin/chargePoints/ChargePoints"
import ChargeTags from "./containers/admin/chargeTags/ChargeTags"
import Companies from "./containers/admin/companies/Company"
import Dashboard from "./containers/dashboard/Dashboard"
import Home from "./containers/Home"
import Map from "./containers/map/Map"
import NotFound from "./components/results/NotFound"
import PrivateRoute from "./components/routes/PrivateRoute"
import React from "react"
import Reservations from "./containers/reservations/Reservations"
import { RoleEnum } from "./common/enums/roleEnum"
import TransactionDetail from "./containers/transactions/detail/TransactionDetail"
import Transactions from "./containers/transactions/Transactions"
import Users from "./containers/admin/users/Users"
import nav from "./common/navigation/nav.json"

const App = () => {
	return (
		<Routes>
			<Route path={nav.dashboard.base} element={<AppLayout />}>
				<Route path={nav.dashboard.base} element={<Home />} />
				<Route path={nav.map.base} element={<Map />} />

				<Route path={nav.dashboard.base} element={<PrivateRoute />}>
					<Route path={nav.dashboard.dashboard} element={<Dashboard />} />
					<Route path={nav.transactions.base} element={<Transactions />} />
					<Route path={nav.transactions.detail} element={<TransactionDetail />} />
					<Route path={nav.reservations.base} element={<Reservations type="user" />} />
				</Route>

				<Route path={nav.admin.base} element={<PrivateRoute roles={[RoleEnum.Admin]} />}>
					<Route path={nav.admin.base} element={<AdminDashboard />} />
					<Route path={nav.admin.map.base} element={<AdminMap />} />
					<Route path={nav.admin.users.base} element={<Users />} />
					<Route path={nav.admin.companies.base} element={<Companies />} />
					<Route path={nav.admin.chargePoints.base} element={<ChargePoints />} />
					<Route path={nav.admin.chargeTags.base} element={<ChargeTags />} />
					<Route path={nav.admin.chargePoints.detail} element={<ChargePointAdminDetail />} />
					<Route path={nav.admin.transactions.base} element={<AdminTransactions />} />
					<Route path={nav.admin.transactions.detail} element={<TransactionDetail />} />
					<Route path={nav.admin.reservations.base} element={<Reservations type="admin" />} />
				</Route>
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	)
}
export default App
