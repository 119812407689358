import {
	ChargePointChargeTagGraphQlDto,
	ChargePointChargingProfileGraphQlDto,
	ChargePointGraphQlDto,
} from "../../../../../features/chargePoints/graphql/types"
import {
	GraphqlQueryItemsBaseModel,
	GraphqlTableQueryParamsBaseModel,
} from "../../../../../common/models/graphqlModels"
import React, { useState } from "react"
import {
	handleGraphqlFilterTableChange,
	initGraphqlTableQueryParamsBaseModel,
} from "../../../../../common/helpers/graphQlHelpers"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"

import { ChargeTagStatusEnum } from "../../../../../common/enums/chargeTagStatusEnum"
import { ChargeTagTypeEnum } from "../../../../../common/enums/chargeTagTypeEnum"
import { FetchStatusEnum } from "../../../../../common/enums/fetchStatusEnum"
import MainTable from "../../../../../components/tables/MainTable"
import { QUERY_CHARGEPOINT_CHARGETAGS } from "../../../../../features/chargePoints/graphql/queries"
import { Switch } from "antd"
import { columnHelper } from "../../../../../common/helpers/tableHelpers"
import { dateTimeFormat } from "../../../../../common/constants/constants"
import dayjs from "dayjs"
import { getEnumSelectOptions } from "../../../../../common/helpers/enumHelpers"
import { nameof } from "ts-simple-nameof"
import { objectToFormData } from "../../../../../common/helpers/formDataHelper"
import styles from "../../ChargePoint.module.scss"
import { updateChargePointChargeTagAsync } from "../../../../../features/chargePoints/chargePointSlice"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"

interface GraphQlParamsModel extends GraphqlTableQueryParamsBaseModel {
	id: number
}

interface Props {
	chargePoint: ChargePointGraphQlDto
}

const ChargePointChargeTagsTab = ({ chargePoint }: Props) => {
	const dispatch = useAppDispatch()

	const { status } = useAppSelector((x) => x.chargePoints)

	const defaultOrder = [{ name: "ASC" }]

	const [queryParams, setQueryParams] = useState<GraphQlParamsModel>({
		...initGraphqlTableQueryParamsBaseModel(defaultOrder),
		id: chargePoint.id,
	})

	const { data, loading, refetch } = useQuery<
		GraphqlQueryItemsBaseModel<ChargePointChargingProfileGraphQlDto>,
		GraphqlTableQueryParamsBaseModel
	>(QUERY_CHARGEPOINT_CHARGETAGS, {
		variables: queryParams,
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "network-only",
	})

	const { t } = useTranslation()

	const columns = [
		{
			...columnHelper(
				t("labels.name"),
				nameof<ChargePointChargeTagGraphQlDto>((x) => x.name),
				"Text"
			),
		},
		{
			...columnHelper(
				t("labels.user"),
				nameof<ChargePointChargeTagGraphQlDto>((x) => x.user),
				"Text"
			),
			sorter: true,
		},
		{
			...columnHelper(
				t("labels.expiryDate"),
				nameof<ChargePointChargeTagGraphQlDto>((x) => x.expiryDate),
				"Date"
			),
			sorter: true,
			render: (value, record) => (value ? dayjs(value).format(dateTimeFormat) : ""),
		},
		{
			...columnHelper(
				t("labels.type"),
				nameof<ChargePointChargeTagGraphQlDto>((x) => x.chargeTagTypeId),
				"MultiSelect",
				getEnumSelectOptions(ChargeTagTypeEnum, t, "chargeTagTypeEnum")
			),
			sorter: true,
			render: (value) => {
				return !value ? "" : t(`chargeTagTypeEnum.${ChargeTagTypeEnum[+value]}`)
			},
		},
		{
			...columnHelper(
				t("labels.status"),
				nameof<ChargePointChargeTagGraphQlDto>((x) => x.chargeTagStatusId),
				"MultiSelect",
				getEnumSelectOptions(ChargeTagStatusEnum, t, "chargeTagStatusEnum")
			),
			sorter: true,
			render: (value) => {
				return !value ? "" : t(`chargeTagStatusEnum.${ChargeTagStatusEnum[+value]}`)
			},
		},
		{
			...columnHelper(
				t("labels.firstIdentification"),
				nameof<ChargePointChargeTagGraphQlDto>((x) => x.firstAuthorizationTimestamp),
				"Date"
			),
			sorter: true,
			render: (value, record) => (value ? dayjs(value).format(dateTimeFormat) : ""),
		},
		{
			...columnHelper(
				t("labels.enabled"),
				nameof<ChargePointChargeTagGraphQlDto>((x) => x.isEnabled),
				"Boolean",
				[
					{ label: t("texts.yes"), value: 1 },
					{ label: t("texts.no"), value: 0 },
				]
			),
			sorter: true,
			render: (value, record: ChargePointChargeTagGraphQlDto) => {
				return (
					<Switch
						defaultChecked={value}
						checkedChildren={t("texts.yes")}
						unCheckedChildren={t("texts.no")}
						onChange={async (checked) => {
							const data = { isEnabled: checked }
							await dispatch(
								updateChargePointChargeTagAsync({ id: chargePoint.id, tagId: record.id, data: objectToFormData(data) })
							).unwrap()

							refetch()
						}}
					/>
				)
			},
		},
	]

	return (
		<div style={{ maxHeight: "100%", height: "100%", display: "flex", flexDirection: "column", gap: 16 }}>
			<div
				className={styles["chargerWrapper"]}
				style={{ flex: "1 1 auto", overflow: "hidden", height: "100%", padding: "24px" }}
			>
				<MainTable
					loading={loading || status === FetchStatusEnum.loading}
					columns={columns}
					onChange={(pagination, filter, sorter) => {
						handleGraphqlFilterTableChange(pagination, defaultOrder, sorter, filter, (newParams) => {
							setQueryParams({
								...queryParams,
								...newParams,
							})
						})
					}}
					totalCount={data?.query?.totalCount}
					dataSource={data?.query?.items}
				/>
			</div>
		</div>
	)
}

export default ChargePointChargeTagsTab
