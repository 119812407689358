import { notification } from "antd"

const settings = {
	duration: 5.0,
}

export const notifySuccess = (title: string, message?: string) => {
	notification["success"]({
		message: title,
		description: message,
		...settings,
	})
}

export const notifyError = (title: string, message?: string) => {
	notification["error"]({
		message: title,
		description: message,
		...settings,
	})
}
