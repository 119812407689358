import { Button, DatePicker, Flex, Input, InputNumber, InputRef, Select, Space, Switch, Typography } from "antd"
import React, { useRef, useState } from "react"
import { dateFormat, dateTimeFormat } from "../constants/constants"

import { DefaultOptionType } from "antd/es/select"
import { FilterDropdownProps } from "antd/lib/table/interface"
import { NumberFilterTypeEnum } from "../enums/numberFilterTypeEnum"
import { SearchOutlined } from "@ant-design/icons"
import { TextSearchTypeEnum } from "../enums/textSearchTypeEnum"
import i18n from "../../app/i18n"
import { useTranslation } from "react-i18next"

export type TableFilterType = "None" | "Text" | "Number" | "Date" | "SingleSelect" | "MultiSelect" | "Boolean"

export const columnHelper = (
	title: string,
	dataIndex: string,
	filterType: TableFilterType = "None",
	filterOptions: DefaultOptionType[] = []
) => ({
	title: title,
	dataIndex: dataIndex,
	...(filterType !== "None" && { ...FilterColumn(dataIndex, filterType, filterOptions) }),
})

const FilterColumn = (dataIndex: any, filterType: TableFilterType, filterOptions: DefaultOptionType[]) => {
	const { t } = useTranslation()

	const [datePickerShotTime, setDatePickerShowTime] = useState<boolean>(false)

	const searchInputRef = useRef<InputRef>(null)
	const [textSearchType, setSelectedSearchType] = useState<TextSearchTypeEnum>(TextSearchTypeEnum.Contains)

	const [numberFilterType, setSelectedNumberFilterType] = useState<NumberFilterTypeEnum>(NumberFilterTypeEnum.Equal)
	const numberInputRef = useRef<HTMLInputElement>(null)

	const [selectFilterValue, setSelectFilterValue] = useState<string | number | null | string[] | number[]>(null)

	const handleSearch = (selectedKeys: string[], confirm: FilterDropdownProps["confirm"], dataIndex: string) => {
		console.log(selectedKeys, dataIndex)
		confirm()
	}

	const handleReset = (clearFilters: () => void, confirm: FilterDropdownProps["confirm"]) => {
		clearFilters()

		if (filterType === "Text") {
			searchInputRef.current.input.value = null
			setSelectedSearchType(TextSearchTypeEnum.Contains)
		}

		if (filterType === "Number") {
			numberInputRef.current.value = null
			setSelectedNumberFilterType(NumberFilterTypeEnum.Equal)
		}

		if (filterType === "SingleSelect" || filterType === "MultiSelect" || filterType === "Boolean") {
			setSelectFilterValue(null)
		}

		if (filterType === "Date") {
			setDatePickerShowTime(false)
		}

		confirm()
	}

	return {
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
			<div style={{ padding: 16 }}>
				<Space direction="vertical">
					<Typography.Text strong>
						{filterType === "Text" ? t("labels.searching") : t("labels.filtering")}
					</Typography.Text>

					<Space direction="vertical" size="middle">
						{filterType === "Text" && (
							<Space direction="vertical">
								<Select
									style={{ width: "220px" }}
									size="middle"
									onChange={(e) => {
										setSelectedSearchType(e)
										const filter = {} as any
										filter[e] = searchInputRef.current.input.value ?? ""
										setSelectedKeys([filter])
									}}
									value={textSearchType}
									placeholder={i18n.t("placeholders.value")}
									options={[
										{ value: TextSearchTypeEnum.Contains, label: t("textSearchTypeEnum.contains") },
										{ value: TextSearchTypeEnum.StartsWith, label: t("textSearchTypeEnum.startsWith") },
										{ value: TextSearchTypeEnum.EndsWith, label: t("textSearchTypeEnum.endsWith") },
									]}
								/>
								<Input
									width="220px"
									size="middle"
									ref={searchInputRef}
									onChange={(e) => {
										const filter = {} as any
										filter[textSearchType] = e.target.value ?? ""
										setSelectedKeys(e.target.value ? [filter] : [])
									}}
									value={searchInputRef.current?.input?.value}
									prefix={<SearchOutlined width={"20px"} />}
								/>
							</Space>
						)}
						{filterType === "Number" && (
							<Space direction="vertical">
								<Select
									style={{ width: "220px" }}
									onChange={(e) => {
										setSelectedNumberFilterType(e)
										const filter = {} as any
										filter[e] = +numberInputRef.current.value
										setSelectedKeys([filter])
									}}
									value={numberFilterType}
									options={[
										{ value: NumberFilterTypeEnum.Equal, label: t("numberFilterTypeEnum.eq") },
										{ value: NumberFilterTypeEnum.GreaterThan, label: t("numberFilterTypeEnum.gt") },
										{ value: NumberFilterTypeEnum.GreaterEqualThan, label: t("numberFilterTypeEnum.gte") },
										{ value: NumberFilterTypeEnum.LowerThan, label: t("numberFilterTypeEnum.lt") },
										{ value: NumberFilterTypeEnum.LowerEqualThan, label: t("numberFilterTypeEnum.lte") },
									]}
								/>
								<InputNumber
									onChange={(e) => {
										const filter = {} as any
										filter[numberFilterType] = +e
										setSelectedKeys(isNaN(+e) ? [] : [filter])
									}}
									controls={false}
									value={numberInputRef.current?.value}
									ref={numberInputRef}
									style={{ width: "220px" }}
								/>
							</Space>
						)}
						{(filterType === "SingleSelect" || filterType === "MultiSelect" || filterType === "Boolean") && (
							<Select
								style={{ width: "220px" }}
								onChange={(e) => {
									setSelectFilterValue(e)
									const filter = {} as any
									if (filterType === "Boolean") filter["eq"] = e === 1 ? true : false
									else filter["in"] = e
									setSelectedKeys([filter])
								}}
								showSearch={false}
								value={selectFilterValue}
								mode={filterType === "MultiSelect" ? "multiple" : undefined}
								options={filterOptions}
							/>
						)}
						{filterType === "Date" && (
							<>
								<Flex justify="flex-end" align="center">
									<span style={{ margin: "0px 8px" }}>{t("labels.time")}</span>
									<Switch
										size="small"
										onChange={(checked) => setDatePickerShowTime(checked)}
										checked={datePickerShotTime}
									/>
								</Flex>

								<DatePicker.RangePicker
									placeholder={[t("texts.from"), t("texts.to")]}
									allowEmpty={[true, true]}
									showTime={datePickerShotTime}
									format={datePickerShotTime ? dateTimeFormat : dateFormat}
									onChange={(date, dateString) => {
										console.log(date, dateString)
										if (!date) {
											setSelectedKeys([])
											return
										}

										const filter = {} as any
										if (!!date[0] && !date[1]) {
											filter["gte"] = date[0].toISOString()
										}
										if (!!date[1] && !date[0]) {
											filter["lte"] = date[1].toISOString()
										}
										if (!!date[0] && !!date[1]) {
											const from = {}
											from[dataIndex] = { gte: date[0].toISOString() }

											const to = {}
											to[dataIndex] = { lte: date[1].toISOString() }
											filter["and"] = [from, to]
										}

										setSelectedKeys([filter])
									}}
								/>
							</>
						)}
						<Flex style={{ width: "100%" }} justify={"space-between"}>
							<Button size="small" onClick={() => handleReset(clearFilters, confirm)}>
								{t("btns.cancel")}
							</Button>
							<Button
								type="primary"
								size="small"
								onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
							>
								{t("btns.filter")}
							</Button>
						</Flex>
					</Space>
				</Space>
			</div>
		),
	}
}
