import {
	Avatar,
	Breadcrumb,
	Button,
	Col,
	ConfigProvider,
	Layout,
	Menu,
	Popover,
	Row,
	Segmented,
	Skeleton,
	Space,
} from "antd"
import { LaptopOutlined, NotificationOutlined, ThunderboltOutlined, UserOutlined } from "@ant-design/icons"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import { OidcUserStatus, useOidc, useOidcIdToken, useOidcUser } from "@axa-fr/react-oidc"
import React, { Component, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../app/hooks"

import { ReactComponent as CardIcon } from "../assets/cardIcon.svg"
import { ReactComponent as ChargerIcon } from "../assets/chargerIcon.svg"
import { Container } from "reactstrap"
import { FetchStatusEnum } from "../common/enums/fetchStatusEnum"
import type { MenuProps } from "antd"
import PageBreadcrump from "./breadcrumb/PageBreadcrump"
import { ReactComponent as PersonIcon } from "../assets/personIcon.svg"
import { RoleEnum } from "../common/enums/roleEnum"
import { UserAuthorization } from "../features/auth/types"
import csCz from "antd/es/locale/cs_CZ"
import enUS from "antd/es/locale/en_US"
import { getUserAuthroizationAsync } from "../features/auth/authSlice"
import { httpClient } from "../common/helpers/httpClientHelper"
import i18n from "../app/i18n"
import { isAccessGranted } from "../common/helpers/isAccessGranted"
import logo from "../assets/logo.png"
import nav from "../common/navigation/nav.json"
import styles from "./Layout.module.scss"
import { useTranslation } from "react-i18next"

const { Header, Content, Sider } = Layout

const AppLayout = () => {
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const { t } = useTranslation()
	const { user, status } = useAppSelector((x) => x.auth)
	const dispatch = useAppDispatch()
	const [locale, setLocal] = useState<any>()
	const [menuItems, setMenuItems] = useState<MenuProps["items"]>([])

	useEffect(() => {
		localStorage.setItem("i18nextLng", "cs")
		i18n.changeLanguage(localStorage.getItem("i18nextLng") ?? "cs")
		setLocaleFromCode(localStorage.getItem("i18nextLng") ?? "cs")
	}, [])

	const setLocaleFromCode = (value: string) => {
		if (value === "cs") setLocal(csCz)
		else setLocal(enUS)
	}

	const { login, logout, isAuthenticated } = useOidc()
	const { oidcUserLoadingState } = useOidcUser()

	const { idTokenPayload } = useOidcIdToken()

	const itemsSider: MenuProps["items"] = [
		{
			label: t("titles.chargePoints"),
			key: nav.chargePoints.base,
			icon: <ChargerIcon />,
			children: [
				{
					key: "transactions",
					label: t("titles.transactions"),
					onClick: () => navigate(nav.transactions.base),
				},
				{
					key: "reservations",
					label: t("titles.reservations"),
					onClick: () => navigate(nav.reservations.base),
				},
			],
		},
		{
			label: t("titles.chargeTags"),
			key: nav.chargeTags.base,
			icon: <CardIcon />,
			children: [
				{
					key: "expired",
					label: t("titles.expired"),
				},
				{
					key: "blocked",
					label: t("titles.blocked"),
				},
			],
			onClick: () => navigate(nav.chargeTags.base),
		},
	]

	useEffect(() => {
		setMenuItems(getMenuItems(user))
	}, [user])

	useEffect(() => {
		if (oidcUserLoadingState === OidcUserStatus.Loaded && !user) {
			getUser()
		}
	}, [oidcUserLoadingState])

	const getUser = async () => {
		dispatch(getUserAuthroizationAsync(false)).unwrap()
	}

	const getMenuItems = (user: UserAuthorization | undefined): MenuProps["items"] => {
		const menuItems: MenuProps["items"] = [
			{
				label: t("titles.map"),
				key: nav.map.base,
				onClick: () => navigate(nav.map.base),
			},
		]

		if (!user) return menuItems

		menuItems.push(
			{
				label: t("titles.dashboard"),
				key: nav.dashboard.dashboard,
				onClick: () => navigate(nav.dashboard.dashboard),
			},
			{
				label: t("titles.transactions"),
				key: nav.transactions.base,
				onClick: () => navigate(nav.transactions.base),
			},
			{
				label: t("titles.reservations"),
				key: nav.reservations.base,
				onClick: () => navigate(nav.reservations.base),
			}
		)

		if (isAccessGranted(user, [RoleEnum.Admin])) {
			menuItems.push({
				label: t("titles.adminstration"),
				key: nav.admin.base,
				popupOffset: [-16, -4],
				children: [
					{
						label: t("titles.dashboard"),
						key: nav.admin.base,
						onClick: () => navigate(nav.admin.base),
					},
					{
						label: t("titles.map"),
						key: nav.admin.map.base,
						onClick: () => navigate(nav.admin.map.base),
					},
					{
						label: t("titles.chargePoints"),
						key: nav.admin.chargePoints.base,
						onClick: () => navigate(nav.admin.chargePoints.base),
					},
					{
						label: t("titles.chargeTags"),
						key: nav.admin.chargeTags.base,
						onClick: () => navigate(nav.admin.chargeTags.base),
					},
					{
						label: t("titles.transactions"),
						key: nav.admin.transactions.base,
						onClick: () => navigate(nav.admin.transactions.base),
					},
					{
						label: t("titles.reservations"),
						key: nav.admin.reservations.base,
						onClick: () => navigate(nav.admin.reservations.base),
					},
					{
						label: t("titles.companies"),
						key: nav.admin.companies.base,
						onClick: () => navigate(nav.admin.companies.base),
					},
					{
						label: t("titles.users"),
						key: nav.admin.users.base,
						onClick: () => navigate(nav.admin.users.base),
					},
				],
			})
		}

		return menuItems
	}

	return (
		<ConfigProvider locale={locale}>
			<Layout className={styles["layout"]} style={{ height: "100vh", width: "100vw" }}>
				<Header style={{ display: "flex", alignItems: "center", padding: "0px 24px", maxWidth: "100%", width: "100%" }}>
					<img style={{ height: "28px", width: "auto", margin: "0px 8px" }} alt="logo" src={logo}></img>
					<Menu
						style={{ margin: "0px 16px", width: "calc(100% - 260px)" }}
						className={styles["top-menu"]}
						theme="dark"
						selectedKeys={[pathname]}
						mode="horizontal"
						items={menuItems}
					/>
					<>
						{isAuthenticated && (
							<Popover
								placement="bottom"
								content={
									<Row gutter={[8, 8]}>
										<Col span={24}>
											<span style={{ fontWeight: "bold" }}>{idTokenPayload?.email}</span>
										</Col>
										<Col span={24}>
											<span style={{ cursor: "pointer" }} onClick={() => logout(window.location.origin)}>
												{t("btns.logout")}
											</span>
										</Col>
									</Row>
								}
							>
								<Avatar style={{ cursor: "pointer" }} size="large" icon={<UserOutlined />} />
							</Popover>
						)}
						{!isAuthenticated && (
							<Button type="primary" onClick={() => login()}>
								{t("btns.login")}
							</Button>
						)}
					</>
				</Header>
				<Layout>
					{/*<Sider width={240}>
						<Menu
							mode="inline"
							selectedKeys={[pathname]}
							style={{ height: "100%", borderRight: 0 }}
							items={itemsSider}
						/>
						</Sider>*/}
					<Layout>
						<PageBreadcrump />
						<Content
							style={{
								padding: 24,
								margin: 0,
								minHeight: 280,
							}}
						>
							{status === FetchStatusEnum.loading ? <Skeleton active /> : <Outlet />}
						</Content>
					</Layout>
				</Layout>
			</Layout>
		</ConfigProvider>
	)
}

export default AppLayout
