import { gql } from "@apollo/client"

export const QUERY_ALL_CHARGEPOINTS = gql`
	query GetChargePoints(
		$skip: Int
		$take: Int
		$order: [ChargePointGraphQlDtoSortInput!]
		$where: ChargePointGraphQlDtoFilterInput
	) {
		query: chargePoints(skip: $skip, take: $take, order: $order, where: $where) {
			items {
				id
				chargePointId
				name
				vendor
				lastCommunicationTime
				reservationCount
				transactionCount
				connectorCount
				isPublic
				chargePointStateId
				externalLink
				company
				availabilityState
			}
			totalCount
		}
	}
`

export const QUERY_CHARGEPOINT = gql`
	query GetChargePoint($id: Long!) {
		query: chargePoint(id: $id) {
			id
			chargePointId
			name
			comment
			firmware
			serialNumber
			vendor
			lastCommunicationTime
			latitude
			longitude
			reservationCount
			transactionCount
			connectorCount
			isPublic
			chargePointStateId
			externalLink
			features
			company
			createTimestamp
			servicePortalLink
			availabilityState
			connectors {
				id
				connectorId
				lastStatus
				lastStatusTime
			}
		}
	}
`
export const QUERY_CHARGEPOINTS_LOCATIONS = gql`
	query GetChargePointsLocations($where: ChargePointGraphQlDtoFilterInput) {
		query: chargePoints(where: $where) {
			items {
				id
				name
				latitude
				longitude
				availabilityState
			}
		}
	}
`
export const QUERY_CHARGEPOINT_CHARGING_PROFILES = gql`
	query GetChargePointChargingProfile(
		$id: Long!
		$skip: Int
		$take: Int
		$order: [ChargePointChargingProfileGraphQlDtoSortInput!]
		$where: ChargePointChargingProfileGraphQlDtoFilterInput
	) {
		query: chargePointsChargingProfiles(id: $id, skip: $skip, take: $take, order: $order, where: $where) {
			items {
				id
				connectorId
				stackLevel
				chargingProfilePurposeId
				chargingProfileKindId
				chargingProfileRecurrencyKindId
				transactionId
				chargingProfileStatusId
				chargingRateUnitId
				limit
			}
			totalCount
		}
	}
`

export const QUERY_CHARGEPOINT_CHARGETAGS = gql`
	query GetChargePointChargeTags(
		$id: Long!
		$skip: Int
		$take: Int
		$order: [ChargePointChargeTagGraphQlDtoSortInput!]
		$where: ChargePointChargeTagGraphQlDtoFilterInput
	) {
		query: chargePointChargeTags(chargePointId: $id, skip: $skip, take: $take, order: $order, where: $where) {
			items {
				id
				name
				expiryDate
				user
				chargeTagTypeId
				chargeTagStatusId
				isEnabled
				chargeTagCreateTimestamp
				firstAuthorizationTimestamp
			}
			totalCount
		}
	}
`

export const QUERY_CHARGEPOINTS_DROPDOWN = gql`
	query GetChargePointsDropdown($order: [ChargePointGraphQlDtoSortInput!], $where: ChargePointGraphQlDtoFilterInput) {
		query: chargePoints(order: $order, where: $where) {
			totalCount
			items {
				name
				id
			}
		}
	}
`

export const QUERY_CHARGEPOINT_STATISTICS_TAGS = gql`
	query GetChargePointStatisticsTags($id: Long!) {
		chargeTagCounts(chargePointId: $id) {
			totalCount
			todaysCount
			stateCounts {
				id
				count
			}
			typeCounts {
				id
				count
			}
		}
	}
`
