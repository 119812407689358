
export interface ChargePointStatusModel {
    id: string;
    name: string;
    protocol: string;
    onlineConnectors: { [key: number]: OnlineConnectorStatusModel; };
}


export interface OnlineConnectorStatusModel {
    status: ConnectorStatusEnum;
    chargeRateKW?: number;
    meterKWH?: number;
    soC?: number;
}

export enum ConnectorStatusEnum {
    Undefined = 0,
    Available = 1,
    Occupied = 2,
    Unavailable = 3,
    Faulted = 4
}

export interface OcppMessageModel {
    messageTypeId: OcppMessageTypeEnum;
    uniqueIdentifier: string;
    action: string;
    jsonPayload: string;
}

export enum OcppMessageTypeEnum {
    Call = 2,
    CallResult = 3,
    CallError = 4
}

export interface OcppMessageResponseModel extends OcppMessageModel {
    errorCode: string;
    errorDescription: string;
}


export interface OcppMessageModel {
    messageTypeId: OcppMessageTypeEnum;
    uniqueIdentifier: string;
    action: string;
    jsonPayload: string;
}

export interface ProcessAnswerModel {
    chargePointStatus: ChargePointStatusModel,
    ocppMessage: OcppMessageModel,
    ocppMessageResponse: OcppMessageResponseModel
}

export interface StartTransactionRequest {
    connectorId: number;
    idTag: string;
    meterStart: number;
    reservationId: number;
    timestamp: string;
}


export interface StopTransactionRequest {
    idTag: string;
    meterStop: number;
    timestamp: string;
    transactionId: number;
    reason: StopTransactionRequestReason;
    transactionData: TransactionData[];
}

export enum StopTransactionRequestReason {
    EmergencyStop = 0,
    EVDisconnected = 1,
    HardReset = 2,
    Local = 3,
    Other = 4,
    PowerLoss = 5,
    Reboot = 6,
    Remote = 7,
    SoftReset = 8,
    UnlockCommand = 9,
    DeAuthorized = 10
}

export interface TransactionData {
    timestamp: string;
    sampledValue: SampledValue[];
}

export interface SampledValue {
    value: string;
    context?: SampledValueContext;
    format?: SampledValueFormat;
    measurand?: SampledValueMeasurand;
    phase?: SampledValuePhase;
    location?: SampledValueLocation;
    unit?: SampledValueUnit;
}

export enum SampledValueContext {
    Interruption_Begin = 0,
    Interruption_End = 1,
    Sample_Clock = 2,
    Sample_Periodic = 3,
    Transaction_Begin = 4,
    Transaction_End = 5,
    Trigger = 6,
    Other = 7
}

export enum SampledValueFormat {
    Raw = 0,
    SignedData = 1
}

export enum SampledValueMeasurand {
    Energy_Active_Export_Register = 0,
    Energy_Active_Import_Register = 1,
    Energy_Reactive_Export_Register = 2,
    Energy_Reactive_Import_Register = 3,
    Energy_Active_Export_Interval = 4,
    Energy_Active_Import_Interval = 5,
    Energy_Reactive_Export_Interval = 6,
    Energy_Reactive_Import_Interval = 7,
    Power_Active_Export = 8,
    Power_Active_Import = 9,
    Power_Offered = 10,
    Power_Reactive_Export = 11,
    Power_Reactive_Import = 12,
    Power_Factor = 13,
    Current_Import = 14,
    Current_Export = 15,
    Current_Offered = 16,
    Voltage = 17,
    Frequency = 18,
    Temperature = 19,
    SoC = 20,
    RPM = 21
}

export enum SampledValuePhase {
    L1 = 0,
    L2 = 1,
    L3 = 2,
    N = 3,
    L1N = 4,
    L2N = 5,
    L3N = 6,
    L1L2 = 7,
    L2L3 = 8,
    L3L1 = 9
}

export enum SampledValueLocation {
    Cable = 0,
    EV = 1,
    Inlet = 2,
    Outlet = 3,
    Body = 4
}

export enum SampledValueUnit {
    Wh = 0,
    KWh = 1,
    Varh = 2,
    Kvarh = 3,
    W = 4,
    KW = 5,
    VA = 6,
    KVA = 7,
    Var = 8,
    Kvar = 9,
    A = 10,
    V = 11,
    K = 12,
    Celcius = 13,
    Fahrenheit = 14,
    Percent = 15
}

export interface AuthorizeRequest {
    idTag: string;
}

export interface BootNotificationRequest {
    chargePointVendor: string;
    chargePointModel: string;
    chargePointSerialNumber: string;
    chargeBoxSerialNumber: string;
    firmwareVersion: string;
    iccid: string;
    imsi: string;
    meterType: string;
    meterSerialNumber: string;
}

export interface DataTransferRequest {
    vendorId: string;
    messageId: string;
    data: string;
}


export interface MeterValuesRequest {
    connectorId: number;
    transactionId: number;
    meterValue: MeterValue[];
}

export interface MeterValue {
    timestamp: string;
    sampledValue: SampledValue[];
}

export interface StatusNotificationRequest {
    connectorId: number;
    errorCode: StatusNotificationRequestErrorCode;
    info: string;
    status: StatusNotificationRequestStatus;
    timestamp?: string;
    vendorId: string;
    vendorErrorCode: string;
}

export enum StatusNotificationRequestErrorCode {
    ConnectorLockFailure = 0,
    EVCommunicationError = 1,
    GroundFailure = 2,
    HighTemperature = 3,
    InternalError = 4,
    LocalListConflict = 5,
    NoError = 6,
    OtherError = 7,
    OverCurrentFailure = 8,
    PowerMeterFailure = 9,
    PowerSwitchFailure = 10,
    ReaderFailure = 11,
    ResetFailure = 12,
    UnderVoltage = 13,
    OverVoltage = 14,
    WeakSignal = 15
}

export enum StatusNotificationRequestStatus {
    Available = 0,
    Preparing = 1,
    Charging = 2,
    SuspendedEVSE = 3,
    SuspendedEV = 4,
    Finishing = 5,
    Reserved = 6,
    Unavailable = 7,
    Faulted = 8
}