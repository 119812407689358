import { Input, Table, TableProps } from "antd"

import { BaseModel } from "../../common/models/customModels"
import { Reference } from "rc-table/lib/interface"
import { defaultTablePageSize } from "../../common/constants/constants"
import { useRef } from "react"
import { useResizeDetector } from "react-resize-detector"

const { Search } = Input

interface Props extends TableProps<BaseModel<number>> {
	totalCount: number | undefined
}

const MainTable = ({ totalCount, ...restProps }: Props) => {
	const tableRef = useRef<Reference>(null)

	const tableWrapperResizeDetector = useResizeDetector<HTMLDivElement>({
		refreshMode: "debounce",
		onResize: () => recalculateTableHeight(true, restProps.scroll?.y),
		refreshRate: 1,
	})

	const recalculateTableHeight = (withSettings: boolean, customHeight?: number | string): number | string => {
		const tableWrapper = tableWrapperResizeDetector.ref.current?.querySelector("div.ant-table-wrapper")
		const tableHeader =
			tableWrapperResizeDetector.ref.current?.querySelector("thead.ant-table-thead")?.clientHeight ?? 0

		const containerPadding = 0 //noPadding ? 0 : 48

		if (customHeight) {
			const emptyPlaceholder = tableWrapperResizeDetector.ref.current?.querySelector("div.ant-table-placeholder")
			emptyPlaceholder?.setAttribute(
				"style",
				`min-height: ${+customHeight + tableHeader - containerPadding}px; max-height: ${
					+customHeight + tableHeader - containerPadding
				}px`
			)

			const spinLoading = tableWrapperResizeDetector.ref.current?.querySelector("div.ant-table-container")
			spinLoading?.setAttribute(
				"style",
				`min-height: ${+customHeight + tableHeader - containerPadding}px; max-height: ${
					+customHeight + tableHeader - containerPadding
				}px`
			)

			return customHeight
		}

		const tableFooter = tableWrapperResizeDetector.ref.current?.querySelector("div.ant-table-footer")?.clientHeight ?? 0
		const tablePaging = 40

		const container = tableWrapperResizeDetector.ref.current?.clientHeight ?? 0
		const filterSection = 0 //filterHeaderRef.current?.clientHeight ?? 0

		const filterSectionMargin = 0 //!hideActions && restProps.filters ? 24 : 0

		const height =
			container - tableHeader - tableFooter - filterSection - containerPadding - filterSectionMargin - tablePaging

		if (tableWrapper && withSettings) {
			tableWrapper.setAttribute("style", `max-height:${height + tableHeader}px;height: 100%`)
		}

		return height
	}

	return (
		<div style={{ height: "100%", width: "100%" }} ref={tableWrapperResizeDetector.ref}>
			<Table
				{...restProps}
				virtual={restProps.virtual ?? true}
				ref={tableRef}
				pagination={{
					position: ["bottomCenter"],
					total: totalCount ?? 0,
					pageSize: defaultTablePageSize,
					showSizeChanger: false,
				}}
				style={{
					height: "100%",
					width: "100%",
				}}
				scroll={{
					x: restProps.scroll?.x ? restProps.scroll.x : (restProps.columns?.length ?? 0) * 200,
					y: recalculateTableHeight(true, restProps.scroll?.y),
				}}
				rowKey={(x) => x.id}
			/>
		</div>
	)
}

export default MainTable
