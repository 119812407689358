import useBreadcrumbs, { BreadcrumbsRoute } from "use-react-router-breadcrumbs"

import { Breadcrumb } from "antd"
import { Link } from "react-router-dom"
import React from "react"
import nav from "../../common/navigation/nav.json"
import { useTranslation } from "react-i18next"

const PageBreadcrump = () => {
	const { t } = useTranslation()

	const routes = [
		{ path: nav.transactions.base, breadcrumb: t("titles.transactions") },
		{ path: nav.reservations.base, breadcrumb: t("titles.reservations") },
		{ path: nav.map.base, breadcrumb: t("titles.map") },
		{ path: nav.users.base, breadcrumb: t("titles.users") },
		{ path: nav.chargePoints.base, breadcrumb: t("titles.chargePoints") },
		{ path: nav.chargeTags.base, breadcrumb: t("titles.chargeTags") },
		{ path: nav.admin.transactions.base, breadcrumb: t("titles.transactions") },
		{ path: nav.admin.reservations.base, breadcrumb: t("titles.reservations") },
		{ path: nav.admin.map.base, breadcrumb: t("titles.map") },
		{ path: nav.admin.base, breadcrumb: t("titles.adminstration") },
		{ path: nav.admin.users.base, breadcrumb: t("titles.users") },
		{ path: nav.admin.chargePoints.base, breadcrumb: t("titles.chargePoints") },
		{ path: nav.admin.chargeTags.base, breadcrumb: t("titles.chargeTags") },
		{ path: nav.admin.companies.base, breadcrumb: t("titles.companies") },
		{ path: nav.dashboard.dashboard, breadcrumb: t("titles.dashboard") },
		{ path: nav.dashboard.base, breadcrumb: t("titles.dashboard") },
		{ path: nav.map.base, breadcrumb: t("titles.map") },
		{ path: nav.transactions.detail, breadcrumb: t("titles.detail") },
		{ path: nav.admin.transactions.detail, breadcrumb: t("titles.detail") },
	] as BreadcrumbsRoute<string>[] | undefined

	const breadcrumbs = useBreadcrumbs(routes, { excludePaths: ["/"], disableDefaults: true })

	return (
		<Breadcrumb style={{ padding: "12px 24px" }}>
			{breadcrumbs.map(({ match, breadcrumb }: any) => {
				return (
					<Breadcrumb.Item key={match.pathname}>
						<Link to={match.pathname}>{breadcrumb}</Link>
					</Breadcrumb.Item>
				)
			})}
		</Breadcrumb>
	)
}

export default PageBreadcrump
