export enum DateFilterTypeEnum {
	Custom = 1,
	Today = 2,
	Week = 3,
	Month = 4,
	ThreeMonths = 5,
	SixMonths = 6,
	Year = 7,
	All = 8,
}
