import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import reducers from './reducers';
import { browserHistory } from '../common/helpers/historyHelper';

export const store = configureStore({
  reducer: reducers(undefined),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),//logMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
