import authReducer from "../features/auth/authSlice"
import chargePointsReducer from "../features/chargePoints/chargePointSlice"
import chargeTagsReducer from "../features/chargeTags/chargeTagSlice"
import { combineReducers } from "@reduxjs/toolkit"
import companiesReducer from "../features/companies/companySlice"
import usersReducer from "../features/users/usersSlice"
import versionReceiverReducer from "../features/versionReceiver/versionReceiver"

const reducers = (myHistory: undefined) =>
	combineReducers({
		chargePoints: chargePointsReducer,
		chargeTags: chargeTagsReducer,
		auth: authReducer,
		users: usersReducer,
		companies: companiesReducer,
		versionReceiver: versionReceiverReducer,
	})

export default reducers
